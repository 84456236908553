import { createIcon } from '@chakra-ui/icons'

export const CoinHistoryIcon = createIcon({
  displayName: 'CoinHistoryIcon',
  viewBox: '0 0 32 40',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 30.3345C0 33.4099 1.57383 35 4.63407 35H15.2837C14.6891 34.5282 14.1645 33.969 13.7448 33.34H4.7215C2.71049 33.34 1.66127 32.2916 1.66127 30.2996V4.70045C1.66127 2.77833 2.71049 1.66001 4.7215 1.66001H22.261C24.2545 1.66001 25.3212 2.72591 25.3212 4.70045V21.7549C25.9508 22.1742 26.5104 22.6985 27 23.2926V4.6655C27 1.59011 25.4087 0 22.3484 0H4.63407C1.60881 0 0 1.59011 0 4.6655V30.3345ZM6.17293 7.61857C6.17293 8.003 6.47021 8.30005 6.8899 8.30005H20.1101C20.5123 8.30005 20.8096 8.003 20.8096 7.61857C20.8096 7.23415 20.5123 6.91962 20.1101 6.91962H6.8899C6.47021 6.91962 6.17293 7.23415 6.17293 7.61857ZM6.17293 13.4199C6.17293 13.8043 6.47021 14.1188 6.8899 14.1188H20.1101C20.5123 14.1188 20.8096 13.8043 20.8096 13.4199C20.8096 13.0354 20.5123 12.7384 20.1101 12.7384H6.8899C6.47021 12.7384 6.17293 13.0354 6.17293 13.4199ZM25 40C28.866 40 32 36.866 32 33C32 29.134 28.866 26 25 26C21.134 26 18 29.134 18 33C18 36.866 21.134 40 25 40ZM22.4163 35.0608C22.8195 36.304 23.7939 36.9312 25.1267 36.9312C26.4147 36.9312 27.3555 36.304 27.7811 35.24C27.8147 35.184 27.7923 35.128 27.7251 35.1056L26.5043 34.5568C26.4371 34.5232 26.3811 34.5456 26.3475 34.6128C26.1347 35.1056 25.7763 35.4752 25.1379 35.4752C24.5443 35.4752 24.1635 35.1616 23.9955 34.6352C23.9059 34.3552 23.8723 34.0864 23.8723 33C23.8723 31.9136 23.9059 31.6448 23.9955 31.3648C24.1635 30.8384 24.5443 30.5248 25.1379 30.5248C25.7763 30.5248 26.1347 30.8944 26.3475 31.3872C26.3811 31.4544 26.4371 31.4768 26.5043 31.4432L27.7251 30.8944C27.7923 30.872 27.8147 30.816 27.7811 30.76C27.3555 29.696 26.4147 29.0688 25.1267 29.0688C23.7939 29.0688 22.8195 29.696 22.4163 30.9392C22.2483 31.432 22.2035 31.824 22.2035 33C22.2035 34.176 22.2483 34.568 22.4163 35.0608Z"
      fill="#1D1D21"
    />
  ),
})
