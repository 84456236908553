import axios from '@!/lib/axios'
import { buildUrlString } from '@!/Util'

export const getAnnouncemetsApi = async () => {
  try {
    return await axios.get('/api/v1/announcements.json')
  } catch (error) {
    throw new Error(error.message)
  }
}

export const getAnnouncemetDetailApi = async (announcementId) => {
  try {
    const url = buildUrlString(`/api/v1/announcements/${announcementId}.json`)
    return await axios.get(url)
  } catch (error) {
    throw new Error(error.message)
  }
}
