import PropTypes from 'prop-types'
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Stack, Button } from '@chakra-ui/react'

export const MultiButtonsPopupModal = ({ title, buttons, cancelCallback, width, isOpen = true }) => (
  <Modal isCentered isOpen={isOpen} onClose={cancelCallback}>
    <ModalOverlay />
    <ModalContent maxW={width}>
      <ModalBody>{title}</ModalBody>
      <ModalFooter>
        <Stack direction="row" spacing={0}>
          {buttons.map((button, index) => (
            <Button onClick={button.callback} key={`multi-buttons-popup-modal-${index}`} ml={4}>
              {button.title}
            </Button>
          ))}
        </Stack>
      </ModalFooter>
    </ModalContent>
  </Modal>
)

MultiButtonsPopupModal.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      callback: PropTypes.func.isRequired,
    }),
  ).isRequired,
  cancelCallback: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
