import { useState } from 'react'
import { useMutation } from 'react-query'

import { resignApi } from '@!/Api/User'

export const useResign = () => {
  const [status, setStatus] = useState(null)
  const [apiError, setApiError] = useState(null)

  const resignMutation = useMutation({
    mutationFn: () => resignApi(),
    onSuccess: () => {
      setStatus('success')
    },
    onError: () => {
      // 常にokを返すのでエラーはサーバーエラーのみ
      setApiError('sever_error')
      setStatus('fail')
    },
  })

  return [resignMutation, status, apiError]
}
