import PropTypes from 'prop-types'
import { Box, Input, FormControl, FormLabel, HStack } from '@chakra-ui/react'

const CreditCardForm = ({ newCardInfo, setNewCardInfo, newCardError, handleSaveCreditCard }) => {
  const handleInputChange = (e) => {
    let { name, value } = e.target
    if (name === 'expirationMonth' || name === 'expirationYear') {
      value = value.substring(0, 2)
    }

    if (name === 'cardNumber') {
      value = value.substring(0, 16)
    }

    if (name === 'securityCode') {
      value = value.substring(0, 4)
    }

    setNewCardInfo((prevCardInfo) => ({
      ...prevCardInfo,
      [name]: value,
    }))
  }

  const saveCardHolderNameUpperCase = () => {
    setNewCardInfo((prevCardInfo) => ({
      ...prevCardInfo,
      cardholderName: newCardInfo.cardholderName.toUpperCase(),
    }))
  }

  // カード名義人からフォーカスが外れた時にアルファベットを大文字に変換する
  const handleBlurCardholderName = () => {
    saveCardHolderNameUpperCase()
  }

  // enter押下時に保存処理が走るため、アルファベットを大文字に変換する
  const handleKeyPressCardholderName = (e) => {
    if (e.key === 'Enter') {
      saveCardHolderNameUpperCase()
    }
  }

  return (
    <form>
      <Box mt="24px">
        {newCardError && (
          <Box color="#e94841" mb="12px">
            {newCardError.error}
          </Box>
        )}
        <FormControl mt="16px">
          <FormLabel htmlFor="cardNumber" fontSize="13px">
            クレジットカード番号
          </FormLabel>
          <Input
            type="number"
            id="cardNumber"
            name="cardNumber"
            value={newCardInfo.cardNumber}
            placeholder="ハイフンなし、半角数字で入力してください"
            onChange={handleInputChange}
            h="40px"
            fontSize="11px"
            bg={newCardError?.field === 'cardNumber' ? '#ffe6e6' : '#f3f3f8'}
            py="0"
            px="12px"
            w="100%"
            boxSizing="border-box"
          />
        </FormControl>
        <FormControl mt="16px">
          <FormLabel htmlFor="expirationMonth" fontSize="13px">
            利用期限
          </FormLabel>
          <HStack>
            <Input
              type="number"
              id="expirationMonth"
              name="expirationMonth"
              value={newCardInfo.expirationMonth}
              placeholder="MM"
              onChange={handleInputChange}
              h="40px"
              fontSize="11px"
              bg={newCardError?.field === 'expirationMonth' ? '#ffe6e6' : '#f3f3f8'}
              py="0"
              px="12px"
              w="72px"
              mr="10px"
              boxSizing="border-box"
            />
            <Box lineHeight="40px">/</Box>
            <Input
              type="number"
              id="expirationYear"
              name="expirationYear"
              value={newCardInfo.expirationYear}
              placeholder="YY"
              onChange={handleInputChange}
              h="40px"
              fontSize="11px"
              bg={newCardError?.field === 'expirationYear' ? '#ffe6e6' : '#f3f3f8'}
              py="0"
              px="12px"
              w="72px"
              ml="10px"
              boxSizing="border-box"
            />
          </HStack>
        </FormControl>
        <FormControl mt="16px">
          <FormLabel htmlFor="cardholderName" fontSize="13px">
            カード名義人
          </FormLabel>
          <Input
            type="text"
            id="cardholderName"
            name="cardholderName"
            placeholder="半角アルファベットで入力してください"
            value={newCardInfo.cardholderName}
            onChange={handleInputChange}
            onBlur={handleBlurCardholderName}
            onKeyPress={handleKeyPressCardholderName}
            h="40px"
            fontSize="11px"
            bg={newCardError?.field === 'cardholderName' ? '#ffe6e6' : '#f3f3f8'}
            py="0"
            px="12px"
            w="100%"
            boxSizing="border-box"
          />
        </FormControl>
        <FormControl mt="16px">
          <FormLabel htmlFor="securityCode" fontSize="13px">
            セキュリティコード
          </FormLabel>
          <Input
            type="number"
            id="securityCode"
            name="securityCode"
            value={newCardInfo.securityCode}
            placeholder="半角数字で入力してください"
            onChange={handleInputChange}
            h="40px"
            fontSize="11px"
            bg={newCardError?.field === 'securityCode' ? '#ffe6e6' : '#f3f3f8'}
            py="0"
            px="12px"
            w="100%"
            boxSizing="border-box"
          />
        </FormControl>
      </Box>
      <Box d="flex" textAlign="center" pt="20px">
        <Box as="button" bg="#4c636e" borderRadius="3px" w="155px" h="36px" onClick={handleSaveCreditCard}>
          <Box as="div" color="#ffffff" fontWeight="bold" lineHeight="2">
            登録する
          </Box>
        </Box>
      </Box>
    </form>
  )
}

CreditCardForm.propTypes = {
  newCardInfo: PropTypes.object.isRequired,
  setNewCardInfo: PropTypes.func.isRequired,
  newCardError: PropTypes.object,
  handleSaveCreditCard: PropTypes.func.isRequired,
}

export default CreditCardForm
