import { PropTypes } from 'prop-types'

import { Box, LinkBox, LinkOverlay, VStack } from '@chakra-ui/react'

import { CoverImage } from '@!/Store/Common/components/CoverImage'

export const BookTitle = ({ title, titleId, imageUrl, sectionTitle, w }) => {
  return (
    <LinkBox w={w}>
      <VStack spacing={0}>
        <Box mb="0.5em">
          <CoverImage src={imageUrl} alt={title} objectFit="contain" width={w} />
        </Box>
        <LinkOverlay href={`/store/books?book_title_id=${titleId}`} data-gtm-click={sectionTitle} noOfLines={1} fontSize="sm" fontWeight="bold" w={w}>
          {title}
        </LinkOverlay>
      </VStack>
    </LinkBox>
  )
}

BookTitle.propType = {
  title: PropTypes.string.isRequired,
  titleId: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  sectionTitle: PropTypes.string.isRequired,
  w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
