import { useState } from 'react'
import { useMutation } from 'react-query'

import { coinHistoriesApi } from '@!/Api/User'

export const useCoinHistories = () => {
  const [apiError, setApiError] = useState(null)

  const coinHistoriesMutation = useMutation({
    mutationFn: () => coinHistoriesApi(),
    onError: (error) => {
      if (error && error.data) {
        setApiError(error.data)
      }
      setApiError('server error')
    },
  })

  return [coinHistoriesMutation, apiError]
}
