export async function getGmoToken(tokenApiUrl, shopId, params) {
  window.gmoCallBack = function (res) {
    window.gmoResponse = res
  }

  if (typeof window.Multipayment === 'undefined') {
    await loadGmoScript(tokenApiUrl)
  }

  window.Multipayment.init(shopId)
  window.Multipayment.getToken(params, window.gmoCallBack)

  while (window.gmoResponse == null) {
    await new Promise((resolve) => setTimeout(resolve, 500))
  }

  const response = window.gmoResponse
  window.gmoResponse = null
  return response
}

async function loadGmoScript(tokenApiUrl) {
  const script = document.createElement('script')
  script.src = tokenApiUrl
  document.getElementsByTagName('head')[0].appendChild(script)

  while (typeof window.Multipayment === 'undefined') {
    await new Promise((resolve) => setTimeout(resolve, 500))
  }
}
