import { Text } from '@chakra-ui/react'

export const CenterLineText = ({ children, ...props }) => {
  const dividerStyles = {
    content: '""',
    height: '1px',
    flexGrow: 1,
    bg: 'divider.light',
  }

  const styles = {
    w: '100%',
    display: 'flex',
    alignItems: 'center',
    _before: {
      ...dividerStyles,
      marginRight: '0.5em',
    },
    _after: {
      ...dividerStyles,
      marginLeft: '0.5em',
    },
  }

  return (
    <Text {...styles} {...props}>
      {children}
    </Text>
  )
}
