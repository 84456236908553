import { Box, Flex, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { NormalButton } from '@!/components/Button/NormalButton'
import { CoverImage } from '@!/Store/Common/components'

const CartItem = ({ books, handleRemoveCartItems }) => {
  return (
    <>
      {books.map((book, index) => (
        <Flex key={index} alignItems="center" borderTop={index !== 0 ? '1px solid #e0e0e0' : 'none'} padding="16px 0" cursor="pointer" justify="space-between">
          <Box display="flex">
            <CoverImage src={book.image_url} alt={book.full_name} width="55px" objectFit="cover" />
            <Box ml={2} fontWeight="bold">
              <Text fontSize="13px" paddingBottom="4px">
                {book.full_name}
              </Text>
              <Flex align="baseline">
                <Text fontSize="16px" color="#e94841">
                  {book.price}
                </Text>
                <Text fontSize="11px">&nbsp;円{book.price !== 0 && '（税込）'}</Text>
              </Flex>
            </Box>
          </Box>
          <NormalButton width="46px" height="29px" fontSize="11px" borderRadius="2px" onClick={() => handleRemoveCartItems([book.distribution_id])}>
            削除
          </NormalButton>
        </Flex>
      ))}
    </>
  )
}

CartItem.propTypes = {
  books: PropTypes.array.isRequired,
  handleRemoveCartItems: PropTypes.func.isRequired,
}

export default CartItem
