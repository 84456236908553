import { useState, useEffect } from 'react'
import { calculatePoint } from '@!/Store/Common/util'

export const useCalculatePointBack = (items, pointBackMeasure, usedPoint = 0, isCouponApplied = false) => {
  const [pointBack, setPointBack] = useState(0)

  useEffect(() => {
    if (!isCouponApplied && items && pointBackMeasure && Object.entries(pointBackMeasure).length !== 0) {
      const exclusionTitleIds = pointBackMeasure.exclusion_title_ids
      const exclusionPublisherIds = pointBackMeasure.exclusion_publisher_ids

      const priceItems = items.map((item) => {
        const excludeFromPointBack = exclusionTitleIds.includes(Number(item.title_id)) || exclusionPublisherIds.includes(item.publisher_id)

        return {
          price: item.price,
          exclude_from_point_back: excludeFromPointBack,
        }
      })
      const calculatedPointBack = calculatePoint(priceItems, pointBackMeasure.point_back_rate, usedPoint)
      setPointBack(calculatedPointBack)
    }
  }, [items, pointBackMeasure, usedPoint, isCouponApplied])

  return pointBack
}
