import { Routes, Route } from 'react-router-dom'

import { AppProvider } from '@!/providers'
import PaymentNewCard from '../containers/PaymentNewCard'
import PaymentMain from '../containers/PaymentMain'
import PaymentConfirm from '../containers/PaymentConfirm'
import PaymentSelect from '../containers/PaymentSelect'
import PaymentRedirect from '../containers/PaymentRedirect'
import { usePaymentSettings } from '@!/Hooks'

const PaymentApp = (props) => {
  const jotaiStore = usePaymentSettings({
    isSaveLastUrl: false,
    paymentList: props.payment_list,
    tokenApiUrl: props.token_api_url,
    shopId: props.shop_id,
  })

  return (
    <AppProvider jotaiStore={jotaiStore}>
      <Routes>
        <Route path="/" element={<PaymentMain />} />
        <Route path="/new_card" element={<PaymentNewCard />} />
        <Route path="/confirm" element={<PaymentConfirm />} />
        <Route path="/select" element={<PaymentSelect />} />
        <Route path="/redirect" element={<PaymentRedirect />} />
      </Routes>
    </AppProvider>
  )
}

export default PaymentApp
