import { useQuery } from 'react-query'
import { newBooksKeys } from '@!/Api/queryKeys'
import { getNewBooksApi } from '@!/Api/NewBooks'
import { useAtom } from 'jotai'
import { safeSearchAtom } from '@!/atoms/safeSearchAtom'

export const useNewBooks = (genreName, page, perPage) => {
  const [safeSearch] = useAtom(safeSearchAtom)

  return useQuery(
    newBooksKeys.filtered(safeSearch, genreName, page, perPage),
    async () => {
      return await getNewBooksApi(safeSearch, genreName, page, perPage)
    },
    {
      keepPreviousData: true,
    },
  )
}
