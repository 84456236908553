import Big from 'big.js'

export const calculatePoint = (items, pointBackRate, usedPoint) => {
  const groupedItems = groupBy(items, (item) => item['exclude_from_point_back'])

  const targetItems = groupedItems[false] || []
  let priceForPointBack = sum(targetItems.map((item) => item['price']))

  const excludedItems = groupedItems[true] || []
  const excludedPrice = sum(excludedItems.map((item) => item['price']))

  // NOTE: ポイント使用
  // ポイント還元対象金額から使用ポイントを減額する
  // ただし還元対象外作品の決済金額から優先してポイント使用対象とする
  priceForPointBack -= Math.max(0, usedPoint - excludedPrice)

  const pointBack = priceForPointBack == 0 ? 0 : calculatePointBack(toTaxExcludedPrice(priceForPointBack), pointBackRate)

  return pointBack
}

const groupBy = (array, getKey) =>
  array.reduce((obj, cur, idx, src) => {
    const key = getKey(cur, idx, src)
    ;(obj[key] || (obj[key] = [])).push(cur)
    return obj
  }, {})

const sum = (array) =>
  array.reduce((sum, cur) => {
    return sum + cur
  }, 0)

// div は割り算、times は掛け算
export const toTaxExcludedPrice = (price) => new Big(price).div(1.1).round(0, 0).toNumber()

const calculatePointBack = (taxExcludedPrice, pointBackRate) => new Big(taxExcludedPrice).times(pointBackRate).div(100).round(0, 0).toNumber()
