import { Routes, Route } from 'react-router-dom'
import { AppProvider } from '@!/providers'
import Bookshelf from '@!/User/Bookshelf/containers/Bookshelf'
import BookshelfList from '@!/User/Bookshelf/containers/BookshelfList'
import BookshelfBooks from '@!/User/Bookshelf/containers/BookshelfBooks'
import { getBookselfDisplayDefault } from '@!/Util/LocalStorageData'
import { useAppSettings } from '@!/Hooks/useAppSettings'

const BookshelfApp = () => {
  const defaultDisplay = getBookselfDisplayDefault()
  const jotaiStore = useAppSettings({ isSaveLastUrl: false })

  return (
    <AppProvider jotaiStore={jotaiStore} style={{ height: '100%' }}>
      <Routes>
        <Route path="/" element={defaultDisplay === '/title_list' ? <BookshelfList /> : <Bookshelf />} />
        <Route path="/book_all" element={<Bookshelf />} />
        <Route path="/title_list" element={<BookshelfList />} />
        <Route path="/title_books" element={<BookshelfBooks />} />
        <Route path="/*" element={<Bookshelf />} />
      </Routes>
    </AppProvider>
  )
}

export default BookshelfApp
