import { useQuery } from 'react-query'

import { volumesApi } from '@!/Api/StoreBook'
import { volumesKeys } from '@!/Api/queryKeys'

export function useVolumes(bookTitleId) {
  return useQuery(volumesKeys.volumes(bookTitleId), async () => {
    return await volumesApi(bookTitleId)
  })
}
