import React from 'react'
import PropTypes from 'prop-types'
import { buildAssetUrlString } from '@!/Util'
import { Box, Text, HStack, Image } from '@chakra-ui/react'
import overlayImage from '@images/coupon_overlay.webp'

const CouponCard = ({ coupon }) => {
  return (
    <>
      <Text fontSize="13px" fontWeight="semibold" py={4}>
        {coupon.title}
      </Text>
      <HStack spacing={0} alignItems="start">
        <Box position="relative" h="auto" w="100%" maxW="41%">
          <Image src={coupon.image_url} alt={coupon.title} w="100%" />
          {!coupon.used && (coupon.expired || !coupon.available) && (
            <Image src={buildAssetUrlString(overlayImage)} alt="終了しました" position="absolute" top="0" left="0" w="100%" h="100%" />
          )}
        </Box>
        <Box fontSize="11px" pl={3}>
          <Box pb={2}>
            <Text fontWeight="semibold" display="inline-block" verticalAlign="top">
              クーポン内容
            </Text>
            <Text display="inline-block" color="label.secondary">
              ：{coupon.name}
            </Text>
          </Box>
          <Box pb={2}>
            <Text fontWeight="semibold" display="inline-block" verticalAlign="top">
              利用条件
            </Text>
            <Text display="inline-block" color="label.secondary">
              ：{coupon.requirements}
              <br />
              {'\u3000'}※併用不可
            </Text>
          </Box>
          {coupon.limit_amount && (
            <Box pb={2}>
              <Text fontWeight="semibold" display="inline-block" verticalAlign="top">
                割引上限
              </Text>
              <Text display="inline-block" color="label.secondary">
                ：{coupon.limit_amount}円
              </Text>
            </Box>
          )}
          <Box pb={2}>
            <Text fontWeight="semibold" display="inline-block" verticalAlign="top">
              利用可能回数
            </Text>
            <Text display="inline-block" color="label.secondary">
              ：1回
            </Text>
          </Box>
          <Box pb={2}>
            <Text fontWeight="semibold" display="inline-block" verticalAlign="top">
              対象作品
            </Text>
            <Text display="inline-block" color="label.secondary">
              ：全作品
            </Text>
          </Box>
          {coupon.expire_date && (
            <Box pb={2}>
              <Text fontWeight="semibold" display="inline-block" verticalAlign="top">
                有効期限
              </Text>
              <Text display="inline-block" color="label.secondary">
                ：{coupon.expire_date}
              </Text>
            </Box>
          )}
        </Box>
      </HStack>
    </>
  )
}

CouponCard.propTypes = {
  coupon: PropTypes.object.isRequired,
}

export default CouponCard
