import PropTypes from 'prop-types'

import { Heading, Stack } from '@chakra-ui/react'

export const HeaderInfo = ({ title }) => {
  return (
    <Stack spacing={0} pt="0.25rem" pb="1rem">
      <Heading as="h1" fontSize="2xl">
        {title}
      </Heading>
    </Stack>
  )
}

HeaderInfo.propTypes = {
  title: PropTypes.string.isRequired,
}
