import { Center, Image } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util'

import PointBackTagPng from '@images/point_back_tag.png'

export const PointBackTag = () => {
  return (
    <Center py={0.5} bg="bg.pointBackTag">
      <Image h="22px" filter="drop-shadow(0px 1px 4px rgba(0,0,0,0.1))" src={buildAssetUrlString(PointBackTagPng)} />
    </Center>
  )
}
