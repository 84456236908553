import { NormalButton } from '@!/components/Button'
import { BookshelfIcon } from '@!/components/Icon'

const BookshelfLinkButton = ({ width }) => {
  const handleClick = () => {
    window.location.href = '/user/bookshelf/'
  }

  return (
    <NormalButton width={width} height="40px" rightIcon={<BookshelfIcon width="22px" height="22px" />} onClick={handleClick}>
      本棚を見る
    </NormalButton>
  )
}

export default BookshelfLinkButton
