import { PropTypes } from 'prop-types'

import { Table, Thead, Tbody, Tr, Th, Td, TableContainer, Skeleton } from '@chakra-ui/react'

export const PointTable = ({ pointList }) => {
  const body = (pointList) => {
    if (pointList) {
      return pointList.map((row, index) => {
        return (
          <Tr key={index}>
            <Td textAlign={'center'}>{row.expire_date}</Td>
            <Td textAlign={'center'}>{row.point_count}pt</Td>
          </Tr>
        )
      })
    }

    return Array(4)
      .fill()
      .map((_i, index) => {
        return (
          <Tr key={index}>
            <Td colSpan={2}>
              <Skeleton height="1rem" />
            </Td>
          </Tr>
        )
      })
  }

  return (
    <TableContainer>
      <Table>
        <Thead>
          <Tr>
            <Th textAlign={'center'}>有効期限</Th>
            <Th textAlign={'center'}>pt数</Th>
          </Tr>
        </Thead>
        <Tbody>{body(pointList)}</Tbody>
      </Table>
    </TableContainer>
  )
}

PointTable.propTypes = {
  pointList: PropTypes.arrayOf(
    PropTypes.shape({
      expire_date: PropTypes.string.isRequired,
      point_count: PropTypes.number.isRequired,
    }),
  ),
}
