import { AppProvider } from '@!/providers'
import BulkPurchaseTitles from '@!/Store/BulkPurchaseTitles/containers/BulkPurchaseTitles'

const BulkPurchaseTitlesApp = (props) => {
  return (
    <AppProvider>
      <BulkPurchaseTitles bookTitles={props.book_titles} />
    </AppProvider>
  )
}

export default BulkPurchaseTitlesApp
