export function getPermitEndTime(permitEnd) {
  return Date.parse(`${permitEnd.substring(0, 4)}/${permitEnd.substring(4, 6)}/${permitEnd.substring(6, 8)} 00:00:00 +0900`)
}

export function visibleBookLimitDate(book) {
  // 閲覧可能時間はdownload_end_timeのみをみる
  return Date.parse(book.download_end_time)
}

export function isVisibleBook(book, currentTime) {
  let visible = true
  const limitDate = visibleBookLimitDate(book)

  // 閲覧期限付コンテンツは期限が過ぎたら除外
  if (book.dl_limit === 1 && limitDate && currentTime > limitDate) {
    visible = false
  }

  return visible
}

export function getEndDate(book) {
  // レンタルもdownloadEndの時間をみる
  if ((book.rental || book.dl_limit >= 0) && book.download_end_time) {
    const downloadEnd = new Date(book.download_end_time)
    return `${downloadEnd.getFullYear()}年${downloadEnd.getMonth() + 1}月${downloadEnd.getDate()}日${`0${downloadEnd.getHours()}`.slice(
      -2,
    )}:${`0${downloadEnd.getMinutes()}`.slice(-2)}`
  }

  return null
}
