import React from 'react'
import { CoverImage } from '@!/Store/Common/components'
import { Box, Text, Link, Flex } from '@chakra-ui/react'
import { ellipsis } from '@!/Store/Common/util'

export default function ListItem({ item, handleClickBookTitle, device }) {
  const { fullName, authorName, imageUrl } = item
  const flexValues = device === 'pc' ? { image: '2', text: '8' } : { image: '3', text: '7' }

  return (
    <Box>
      <Link
        href="/user/bookshelf"
        textDecoration="none"
        onClick={(e) => {
          e.preventDefault()
          handleClickBookTitle(item)
        }}
      >
        <Flex>
          <Box flex={flexValues.image} mr={4}>
            <CoverImage src={imageUrl} alt={fullName} width="100%" />
          </Box>
          <Box flex={flexValues.text}>
            <Text mb={2} fontSize="sm" fontWeight="bold" color="label.primary">
              {ellipsis(fullName, 16, 17)}
            </Text>
            {authorName && (
              <Text my={2} fontSize="xs" color="label.secondary">
                {authorName}
              </Text>
            )}
          </Box>
        </Flex>
      </Link>
    </Box>
  )
}
