import { useState, useEffect } from 'react'
import { Box, Heading, Text, SimpleGrid, Center } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { Config } from '@!/config/config'
import { BulkPurchaseCard } from '@!/Store/BulkPurchaseTitles/components/BulkPurchaseCard'
import { Paginate } from '@!/components/Paginate'

const BulkPurchaseTitles = ({ bookTitles }) => {
  const [currentBookTitles, setCurrentBookTitles] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [bookTitleOffset, setVolumeOffset] = useState(0)
  const bulkPurchaseTitlesPerPag = Config.bulkPurchaseTitlesPerPag

  useEffect(() => {
    setTotalCount(bookTitles.length)
    setCurrentBookTitles(bookTitles.slice(0, bulkPurchaseTitlesPerPag))
  }, [bookTitles])

  useEffect(() => {
    const endOffset = bookTitleOffset + bulkPurchaseTitlesPerPag
    setCurrentBookTitles(bookTitles.slice(bookTitleOffset, endOffset))
  }, [bookTitleOffset])

  const handlePageClick = (page) => {
    const newOffset = ((page - 1) * bulkPurchaseTitlesPerPag) % bookTitles.length
    setVolumeOffset(newOffset)
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  return (
    <Box px={4} py={8} fontFamily="Hiragino Sans">
      <Heading as="h1" fontSize="2xl" mb={2}>
        おすすめ まとめ買い作品
      </Heading>
      <Text fontSize="11px" color="label.accent">
        ※全巻購入した場合の価格と還元ポイント数が記載されています。
      </Text>
      <Box my={6}>
        <SimpleGrid columns={{ base: 2, md: 4 }} spacing="13px">
          {currentBookTitles.map((title, index) => (
            <Box key={index}>
              <BulkPurchaseCard title={title} />
            </Box>
          ))}
        </SimpleGrid>
      </Box>
      <Box p={4} w="100%" boxSizing="border-box">
        <Center>
          <Paginate currentPage={currentPage} perPage={bulkPurchaseTitlesPerPag} totalCount={totalCount} onPageChange={handlePageClick} />
        </Center>
      </Box>
    </Box>
  )
}

BulkPurchaseTitles.propTypes = {
  bookTitles: PropTypes.array.isRequired,
}

export default BulkPurchaseTitles
