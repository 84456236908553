import { StyleSheet } from 'aphrodite'

export const styles = StyleSheet.create({
  main_container: {
    margin: '0 auto',
  },
  header: {
    width: '100%',
    background: '#fff',
  },
  container: {},
  app_sp_h1: {
    margin: 0,
    height: '30px',
    lineHeight: '30px',
    verticalAlign: 'middle',
    padding: '0 15px',
    fontSize: '12px',
    color: '#4a4a4a',
    fontWeight: 'normal',
  },
})
