import { PropTypes } from 'prop-types'

import { IconButton } from '@chakra-ui/react'
import { createIcon } from '@chakra-ui/icons'

export const ClearSearchButton = ({ onClick }) => {
  const ClearIcon = createIcon({
    displayName: 'ClearIcon',
    viewBox: '0 0 28 24',
    d: 'M14 22a9.626 9.626 0 0 1-3.863-.784 10.238 10.238 0 0 1-3.186-2.167 10.237 10.237 0 0 1-2.167-3.186A9.627 9.627 0 0 1 4 12c0-1.366.261-2.65.784-3.853a10.29 10.29 0 0 1 2.157-3.196 10.238 10.238 0 0 1 3.186-2.167A9.627 9.627 0 0 1 13.99 2c1.366 0 2.654.261 3.863.784a10.205 10.205 0 0 1 3.196 2.167 10.206 10.206 0 0 1 2.167 3.196A9.557 9.557 0 0 1 24 12a9.626 9.626 0 0 1-.784 3.863 10.237 10.237 0 0 1-2.167 3.186 10.205 10.205 0 0 1-3.196 2.167A9.557 9.557 0 0 1 14 22Zm-3.294-5.882a.831.831 0 0 0 .598-.236l2.706-2.725 2.716 2.725c.15.157.343.236.578.236a.787.787 0 0 0 .578-.236.808.808 0 0 0 .236-.578.747.747 0 0 0-.236-.579l-2.725-2.715 2.735-2.726a.808.808 0 0 0 .236-.578.775.775 0 0 0-.236-.569.775.775 0 0 0-.569-.235.763.763 0 0 0-.558.235l-2.755 2.736-2.736-2.726a.814.814 0 0 0-.568-.225.775.775 0 0 0-.569.235.754.754 0 0 0-.235.568c0 .216.078.406.235.57l2.726 2.715-2.726 2.725a.775.775 0 0 0-.235.569.808.808 0 0 0 .804.814Z',
  })

  return <IconButton aria-label="検索内容をクリア" size="xs" variant="unstyled" icon={<ClearIcon color="gray.400" boxSize={6} />} onClick={onClick} />
}

ClearSearchButton.propType = {
  onClick: PropTypes.func,
}
