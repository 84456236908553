import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useAtom } from 'jotai'

import { AddCartButton, ViewCartButton } from './'
import { cartItemsAtom } from '@!/atoms/cartItemsAtom'

export const CartButton = ({ detail }) => {
  const [cartItems] = useAtom(cartItemsAtom)
  const [enableAdd, setEnableAdd] = useState(false)

  useEffect(() => {
    if (detail.purchased) {
      setEnableAdd(false)
      return
    }

    // カート追加済かどうか
    setEnableAdd(!cartItems.some((cartItem) => cartItem.distribution_id === detail.distribution_id))
  }, [detail.purchased, cartItems, detail.distribution_id])

  if (detail.purchased) {
    return null
  }

  if (enableAdd) {
    return <AddCartButton detail={detail} />
  } else {
    return <ViewCartButton />
  }
}

CartButton.propTypes = {
  detail: PropTypes.object.isRequired,
}
