import { atom } from 'jotai'
import { Cookies } from 'react-cookie'

const safeSearchInitialValue = () => {
  const cookies = new Cookies()
  const safeSearchCookie = cookies.get('safe_search')
  return safeSearchCookie ?? true
}

export const safeSearchAtom = atom(safeSearchInitialValue(), (get, set, newValue) => {
  set(safeSearchAtom, newValue)
})
safeSearchAtom.debugLabel = 'safeSearch'
