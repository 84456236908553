import { atom } from 'jotai'
import { splitAtom } from 'jotai/utils'

// カートアイテム一覧を保持する
export const cartItemsAtom = atom([])
cartItemsAtom.debugLabel = 'cartItems'

// 追加、更新、削除用に1件だけ保持する
export const cartItemAtom = atom(null)
cartItemAtom.debugLabel = 'cartItem'

// cartItemsAtomのリストオブジェクト(react16環境では使用不可)
export const cartItemAtomsAtom = splitAtom(cartItemsAtom)

// カートアイテム件数を取得する
export const numberOfCartItemsAtom = atom((get) => get(cartItemsAtom).length)
numberOfCartItemsAtom.debugLabel = 'numberOfCartItems'

/*
  cartItemsAtomへ新規追加する
  ex.
    createCartItem(newCartItem)
*/
export const createCartItemAtom = atom(null, (get, set, newCartItem) => {
  if (newCartItem && newCartItem.distribution_id) {
    set(cartItemsAtom, (prev) => [...prev, newCartItem])
    set(cartItemAtom, {})
  }
})

/*
  cartItemsAtomから削除する
  事前にsetCartItemで削除したいcartItemをセットしてから実行する
  ex.
    const [cartItem, setCartItem] = useAtom(cartItemAtom)
    const [, deleteCartItem] = useAtom(deleteCartItemAtom)
    setCartItem(props.detail)
    deleteCartItem()
*/
export const deleteCartItemAtom = atom(null, (get, set) => {
  const selectedCartItem = get(cartItemAtom)
  if (selectedCartItem) {
    set(cartItemsAtom, (prev) => prev.filter((prevCartItem) => !(prevCartItem.distribution_id === selectedCartItem.distribution_id)))
    set(cartItemAtom, {})
  }
})
