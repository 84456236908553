import { AppProvider } from '@!/providers'
import Tables from '@!/Store/Kana/Tables/containers/Tables'
import StoreApp from '@!/Store/Common/containers/StoreApp'

const TablesApp = (props) => {
  return (
    <AppProvider>
      <StoreApp {...props}>
        <Tables />
      </StoreApp>
    </AppProvider>
  )
}

export default TablesApp
