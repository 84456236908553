import { AppProvider } from '@!/providers'
import BookPurchaseComplete from '../containers/BookPurchaseComplete'

const BookPurchaseCompleteApp = (props) => {
  return (
    <AppProvider>
      <BookPurchaseComplete books={props.books} coupons={props.coupons} {...props} />
    </AppProvider>
  )
}

export default BookPurchaseCompleteApp
