import { useMemo } from 'react'
import { PropTypes } from 'prop-types'
import { Link, HStack, Box, Text, Stack, Grid, GridItem } from '@chakra-ui/react'

import { buildUrlString } from '@!/Util'
import { parsePermitTime } from '@!/Store/Common/util/time'
import { CoverImage, PointBackCountLabel, PriceLabel } from '@!/Store/Common/components'
import { PrimaryActionButton, SampleButton } from '@!/Store/Common/components/BookList/Button'
import { CartIconButton } from '@!/Store/Common/components/Cart'

export const BookListItem = ({ book, volume, currentTime }) => {
  const currentDateTime = useMemo(() => Date.parse(currentTime), [currentTime])
  const permitStart = useMemo(() => parsePermitTime(volume.permit_start), [volume.permit_start])
  const permitEnd = useMemo(() => parsePermitTime(volume.permit_end, true), [volume.permit_end])
  if (currentDateTime < permitStart || currentDateTime > permitEnd) {
    return null
  }

  return (
    <Box w="100%">
      <Grid templateColumns="auto 1fr">
        <GridItem colSpan={1}>
          <Box mr={4}>
            <Link href={buildUrlString(`/store/books/${volume.distribution_id}`)}>
              <CoverImage src={volume.image_url} alt={volume.full_name} objectFit="contain" width="70px" />
            </Link>
          </Box>
        </GridItem>
        <GridItem colSpan={1}>
          <Link href={buildUrlString(`/store/books/${volume.distribution_id}`)} textDecoration="none" color="label.primary" fontWeight="none">
            <Text fontWeight="bold" size="sm">
              {volume.full_name}
            </Text>
            {!volume.purchased && (
              <HStack spacing={0} justifyContent="space-between" mt={2} mb={3}>
                <Stack spacing={0}>
                  <PriceLabel price={volume.price} properPrice={volume.proper_price} />
                  <PointBackCountLabel pointBackCount={volume.point_back_count} />
                </Stack>
                <CartIconButton detail={volume} />
              </HStack>
            )}
          </Link>
        </GridItem>
        <GridItem colSpan={1}></GridItem>
        <GridItem colSpan={1}>
          <HStack spacing={0}>
            <Box w="50%" mr="9px">
              <SampleButton
                distributionId={volume.distribution_id}
                sampleBookId={volume.sample_book_id}
                rating={book.rating}
                price={volume.price}
                isPurchased={volume.purchased}
              />
            </Box>
            <Box w="50%">
              <PrimaryActionButton volume={volume} />
            </Box>
          </HStack>
        </GridItem>
      </Grid>
    </Box>
  )
}

BookListItem.propType = {
  book: PropTypes.object.isRequired,
  volume: PropTypes.object.isRequired,
  currentTime: PropTypes.string.isRequired,
}
