import { Box, Container, Heading, Stack, Text } from '@chakra-ui/react'

export const RegistrationCompletePage = () => {
  return (
    <Container p={5} pb="65px" bg="bg.primary" mt={2} boxSizing="border-box">
      <Stack spacing={0}>
        <Heading fontSize="md" textAlign="center" as="h2">
          メール会員登録
        </Heading>
        <Box fontSize="sm" my={8}>
          <Text>ご入力頂いたメールアドレスに認証用のメールを送らせていただきました。</Text>
          <Text>ご確認いただき、認証を完了してください。</Text>
        </Box>
        <Box fontSize="sm">
          <Text color="label.accent">※ メールが届かない場合、メールアドレスの入力間違いの可能性がございますので、再度ご実施ください。</Text>
          <Text color="label.accent">※ メールが迷惑メールフォルダに分類されることがございますので、ご確認ください。</Text>
        </Box>
      </Stack>
    </Container>
  )
}
