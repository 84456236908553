import { PropTypes } from 'prop-types'
import { Link, Image } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util'
import bannerImage from '@images/limited_time_banner.webp'
import bannerNoImagePng from '@images/home_banner-noimage.png'

export const LimitedTimeBanner = ({ bannerInfo }) => {
  const { alt, uri } = bannerInfo

  return (
    <Link href={uri} data-gtm-click={`homeバナー_${alt}`}>
      <Image src={buildAssetUrlString(bannerImage)} fallbackSrc={buildAssetUrlString(bannerNoImagePng)} alt={alt} width="100%" aspectRatio={1125 / 510} />
    </Link>
  )
}

LimitedTimeBanner.propType = {
  bannerInfo: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }).isRequired,
}
