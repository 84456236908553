import PropTypes from 'prop-types'
import { Box, Heading, SimpleGrid, Text } from '@chakra-ui/react'

const tables = [
  ['あ', 'い', 'う', 'え', 'お'],
  ['か', 'き', 'く', 'け', 'こ'],
  ['さ', 'し', 'す', 'せ', 'そ'],
  ['た', 'ち', 'つ', 'て', 'と'],
  ['な', 'に', 'ぬ', 'ね', 'の'],
  ['は', 'ひ', 'ふ', 'へ', 'ほ'],
  ['ま', 'み', 'む', 'め', 'も'],
  ['や', 'ゆ', 'よ'],
  ['ら', 'り', 'る', 'れ', 'ろ'],
  ['わ', 'を', 'ん'],
]

export const KanaTable = ({ subject, onClickKana }) => (
  <Box py={3}>
    {subject && (
      <Heading as="h2" size="xs" pb={1} mb={1} borderBottom="solid 3px #DCDCE1">
        {subject}
      </Heading>
    )}
    {tables.map((row, rowIndex) => (
      <SimpleGrid key={`kana-table-${rowIndex}`} columns={5} py={2}>
        {row.map((data, dataIndex) => (
          <Box key={`kana-table-${rowIndex}-${dataIndex}`} mx={2} p={1} border="solid 1px#5D5D61" borderRadius="4px" textAlign="center">
            <Text onClick={() => onClickKana(data)} color="label.primary" fontWeight="normal" cursor="pointer">
              {data}
            </Text>
          </Box>
        ))}
      </SimpleGrid>
    ))}
  </Box>
)

KanaTable.propTypes = {
  subject: PropTypes.string,
  onClickKana: PropTypes.func.isRequired,
}
