import { useQuery } from 'react-query'

import { getCartItemsApi } from '../Api/Cart'
import { cartItemsKeys } from '../Api/queryKeys'

import { getCartItemsNotLoggedIn } from '../Util/LocalStorageData'

export function useCartItems(userHash) {
  const cartItemsKey = userHash ? cartItemsKeys.cartItemsLoggedIn : cartItemsKeys.cartItemsNotLoggedIn
  return useQuery(
    cartItemsKey(userHash),
    async () => {
      if (userHash) {
        return await getCartItemsApi()
      } else {
        return getCartItemsNotLoggedIn()
      }
    },
    { refetchOnWindowFocus: false },
  )
}
