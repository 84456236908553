export function openApp(os, path = '') {
  if (os === 'ios') {
    window.setTimeout(() => {
      window.location.href = 'https://itunes.apple.com/jp/app/mangabang!-ren-qi-man-huaga/id939480693'
    }, 1200)

    window.location.href = `mangabang://${path}`
  } else if (os === 'android') {
    window.location.href = 'intent://manga-bang.com#Intent;scheme=mangabang2;package=com.mangabang;S.market_referrer=referrer_string;end'
  } else if (os === 'android_full') {
    const fallbackUrl = encodeURI(`https://${window.location.host}/download_reader`)
    window.location.href = `intent://freetime.manga-bang.com#Intent;scheme=mbwebreader;S.browser_fallback_url=${fallbackUrl};end`
  }
}
