import axios from '@!/lib/axios'
import { getCsrfToken } from '@!/Util/PostRequest'
import { buildUrlString } from '@!/Util'

export const getPurchaseHistoriesApi = async () => {
  return await axios.get(
    '/api/v1/user/store/book_purchase_histories',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const getPurchasedBookTitleApi = async (bookTitleId) => {
  const url = buildUrlString('/api/v1/bookshelf/purchased_book_title', { book_title_id: bookTitleId })
  return await axios.get(
    url,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const purchaseApi = async (purchaseParams) => {
  const paymentType = purchaseParams.payment_type
  let url

  if (paymentType === 'coin' || paymentType === 'credit' || paymentType === 'none') {
    url = '/api/v1/user/store/purchase_book.json'
  } else {
    return { success: true }
  }

  return await axios.post(url, purchaseParams, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
  })
}
