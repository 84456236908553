import { AppProvider } from '@!/providers'
import Coupons from '../containers/Coupons'

const CouponsApp = (props) => {
  return (
    <AppProvider>
      <Coupons coupons={props.coupons} />
    </AppProvider>
  )
}

export default CouponsApp
