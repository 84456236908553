import { createIcon } from '@chakra-ui/icons'

export const LoginIcon = createIcon({
  displayName: 'LoginIcon',
  viewBox: '0 0 38 42',
  path: (
    <>
      <path
        d="M6.168 32.52C6.48 32.528 6.696 32.536 6.88 32.536H11.176C11.352 32.536 11.64 32.536 11.88 32.52C11.864 32.72 11.864 32.944 11.864 33.144V37.088C11.864 37.344 11.872 37.968 11.88 38.056H11.192C11.192 38 11.2 37.808 11.2 37.592H6.832C6.84 37.8 6.84 37.992 6.848 38.056H6.16C6.168 37.952 6.184 37.36 6.184 37.08V33.144C6.184 32.96 6.184 32.712 6.168 32.52ZM6.832 33.168V36.968H11.208V33.168H6.832ZM18.12 31.6C18.32 31.888 18.608 32.368 18.776 32.696L18.344 32.888C18.184 32.568 17.912 32.08 17.696 31.784L18.12 31.6ZM19 31.28C19.208 31.576 19.52 32.072 19.672 32.368L19.24 32.56C19.064 32.216 18.8 31.76 18.576 31.464L19 31.28ZM15.968 31.984C15.84 32.192 15.696 32.464 15.616 32.608C15.56 32.72 15.496 32.832 15.432 32.944H17.6C17.816 32.944 17.992 32.92 18.12 32.872L18.648 33.192C18.584 33.304 18.504 33.488 18.464 33.616C18.248 34.424 17.76 35.504 17.048 36.376C16.32 37.264 15.36 37.992 13.864 38.552L13.28 38.024C14.752 37.592 15.752 36.872 16.48 36.016C17.096 35.288 17.592 34.248 17.752 33.52H15.056C14.568 34.2 13.904 34.912 13.024 35.488L12.472 35.08C13.848 34.256 14.632 33.088 14.984 32.376C15.064 32.232 15.184 31.944 15.232 31.736L15.968 31.984ZM18.688 35.112C20.072 34.736 21.296 34.176 22.184 33.616C23.016 33.096 23.816 32.4 24.368 31.736L24.904 32.232C24.296 32.864 23.56 33.472 22.744 34.016V37.392C22.744 37.696 22.76 38.088 22.792 38.248H22.008C22.032 38.096 22.056 37.696 22.056 37.392V34.432C21.216 34.912 20.12 35.392 19.008 35.736L18.688 35.112ZM25.816 32.136C26.384 32.512 27.408 33.344 27.856 33.792L27.352 34.296C26.952 33.88 25.952 33.024 25.36 32.624L25.816 32.136ZM25.128 37.496C26.528 37.288 27.568 36.8 28.328 36.328C29.56 35.552 30.536 34.368 31 33.384L31.384 34.064C30.84 35.064 29.904 36.152 28.696 36.912C27.896 37.416 26.888 37.904 25.552 38.152L25.128 37.496Z"
        fill="#1D1D21"
      />
      <rect x="0.5" y="0.5" width="37" height="41" rx="2.5" stroke="#DCDCE1" fill="none" />
      <path
        d="M13.0566 26.0781C11.0547 26.0781 10 25.0137 10 22.9824V9.08594C10 7.06445 11.0547 6 13.0566 6H22.9199C24.9219 6 25.9766 7.06445 25.9766 9.08594V13.1973H24.0234V9.23242C24.0234 8.40234 23.5938 7.95312 22.7246 7.95312H13.252C12.3828 7.95312 11.9531 8.40234 11.9531 9.23242V22.8359C11.9531 23.666 12.3828 24.125 13.252 24.125H22.7246C23.5938 24.125 24.0234 23.666 24.0234 22.8359V18.8809H25.9766V22.9824C25.9766 25.0137 24.9219 26.0781 22.9199 26.0781H13.0566ZM19.1309 16.9375C18.6523 16.9375 18.2422 16.5273 18.2422 16.0293C18.2422 15.5312 18.6523 15.1211 19.1309 15.1211H28.3691L29.7363 15.1992L29.043 14.6426L27.7148 13.3926C27.5391 13.2363 27.4512 13.0117 27.4512 12.7871C27.4512 12.3477 27.7734 11.9863 28.2227 11.9863C28.457 11.9863 28.6328 12.0742 28.7988 12.2402L31.7676 15.3359C32.002 15.5703 32.0801 15.7852 32.0801 16.0293C32.0801 16.2637 32.002 16.4883 31.7676 16.7227L28.7988 19.8184C28.6328 19.9746 28.457 20.0723 28.2227 20.0723C27.7734 20.0723 27.4512 19.7012 27.4512 19.2617C27.4512 19.0371 27.5391 18.8223 27.7148 18.666L29.043 17.4062L29.7363 16.8594L28.3691 16.9375H19.1309Z"
        fill="#1D1D21"
      />
    </>
  ),
})
