import { useEffect, useMemo, useState } from 'react'
import { useAtom } from 'jotai'
import { useNavigate, useSearchParams, Link as RouterLink } from 'react-router-dom'

import BookshelfItems from '@!/User/Bookshelf/components/BookshelfItems'
import { getEndDate } from '@!/User/Bookshelf/util/Check'
import { CircularProgressModal, ConfirmPopupModal } from '@!/components/Modal'
import { storeBookColophonUrl } from '@!/Util/Location'
import { usePurchaseItems, useReadBook, usePurchasedBookTitle } from '@!/Hooks'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { getDevice } from '@!/Util/Device'
import { Container, VStack, Heading, Text, Divider, Box, Link } from '@chakra-ui/react'

const BookshelfBooks = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [purchaseItems] = usePurchaseItems()
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)
  const [bookTitleId, setBookTitleId] = useState(null)
  const { data: selectedBookTitle, isLoading: isLoading } = usePurchasedBookTitle(bookTitleId)
  const [selectedBook, setSelectedBook] = useState(null)

  const [readPurchasedBookMutation, redirectUrl, readBookApiError] = useReadBook('purchased')
  const [apiError, setApiError] = useState(false)
  const [apiErrorText, setApiErrorText] = useState('')
  const [confirmModalText, setConfirmModalText] = useState('')

  useEffect(() => {
    if (!searchParams.get('book_title_id')) {
      navigate('/title_list')
    }
    setBookTitleId(searchParams.get('book_title_id'))
  }, [searchParams])

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: '本棚(作品別)', ref: '/user/bookshelf#/title_list' }, { name: selectedBookTitle?.name }])
  }, [selectedBookTitle])

  useEffect(() => {
    if (!redirectUrl) return

    window.location.href = redirectUrl
  }, [redirectUrl])

  useEffect(() => {
    if (!readBookApiError) return

    setApiError(readBookApiError)
    if (readBookApiError.indexOf('BOOK_EXPIRED') !== -1) {
      setApiErrorText('閲覧期限を過ぎているため表示できません。')
    } else {
      setApiErrorText('エラーが発生しました。')
    }
  }, [readBookApiError])

  const resetApiError = () => {
    setApiError(null)
    setApiErrorText('')
  }

  const handleConfirmModalPopup = () => {
    if (!selectedBook) return

    showBook(selectedBook)
  }

  const handleClickBook = (purchaseHistory, book) => {
    if (purchaseHistory) {
      const endDate = getEndDate(purchaseHistory)
      if (endDate) {
        // 期限持ちのものは「〜まで閲覧できます。」というアラートを出す。
        setConfirmModalText(`「${book.full_name}」は${endDate}まで閲覧できます。`)
        setSelectedBook(book)
      } else {
        showBook(purchaseHistory)
      }
    } else {
      window.location.href = `/store/books?book_title_id=${book.book_title_id}`
    }
  }

  const showBook = (book) => {
    const colophonUrl = storeBookColophonUrl(book.mddc_id, false)
    readPurchasedBookMutation.mutate({ bookId: book.mddc_id, colophonUrl: colophonUrl })
    setConfirmModalText('')
    setSelectedBook(null)
  }

  const device = getDevice()
  const books = useMemo(() => {
    if (selectedBookTitle && purchaseItems) {
      // NOTE: ブラックリスト作品の場合は追加巻の購入を不可にするため、購入済みの巻のみに絞り込み
      if (selectedBookTitle.ng_title) {
        return selectedBookTitle.books.filter((book) => !!purchaseItems[book.distribution_id])
      }
      // NOTE: 未購入 かつ 未販売の作品は除外する(販売終了した購入済の通常コンテンツは表示する)
      return selectedBookTitle.books.filter((book) => purchaseItems[book.distribution_id] || book.published)
    } else {
      return null
    }
  }, [selectedBookTitle, purchaseItems])
  const titleName = selectedBookTitle?.name || ''
  const showNumber = device === 'sp' ? 4 : 5

  return (
    <Container py={6} boxSizing="border-box">
      <CircularProgressModal isOpen={isLoading || readPurchasedBookMutation.isLoading} />
      <VStack spacing={0} align="stretch">
        <Heading as="h1" fontSize="2xl">
          本棚
        </Heading>
        <Divider my={3} />
        <Heading as="h1" fontSize="md">
          {titleName}
        </Heading>

        <ConfirmPopupModal handlePopup={() => resetApiError()} text={apiErrorText} isOpen={!!apiError} />
        <ConfirmPopupModal handlePopup={handleConfirmModalPopup} text={confirmModalText} isOpen={!!confirmModalText} />

        {!books?.length ? (
          <Text py={10} textAlign="center" fontSize="sm">
            閲覧できる作品がありません。
          </Text>
        ) : (
          <Box my={3}>
            <BookshelfItems books={books} purchaseItems={purchaseItems} handleClickBook={handleClickBook} showNumber={showNumber} />
          </Box>
        )}

        <Link as={RouterLink} to="/title_list" textAlign="center" fontSize="sm">
          本棚に戻る
        </Link>
      </VStack>
    </Container>
  )
}

export default BookshelfBooks
