import { createStore } from 'jotai'
import { isSaveLastUrlAtom } from '@!/atoms/appSettingsAtom'
import { paymentListAtom, tokenApiUrlAtom, shopIdAtom } from '@!/atoms/paymentAtom'

export const usePaymentSettings = ({ isSaveLastUrl, paymentList, tokenApiUrl, shopId }) => {
  const store = createStore()
  store.set(isSaveLastUrlAtom, isSaveLastUrl)
  store.set(paymentListAtom, paymentList)
  store.set(tokenApiUrlAtom, tokenApiUrl)
  store.set(shopIdAtom, shopId)

  return store
}
