import PropTypes from 'prop-types'
import { Box, Text, Flex } from '@chakra-ui/react'

const CampaignPointSummary = ({ pointBack, pointBackRate }) => {
  return (
    <Box fontSize="md" fontWeight="bold">
      <Flex justifyContent="space-between" alignItems="center">
        <Text>還元ポイント</Text>
        <Flex>
          <Text>{pointBack.toLocaleString()}&nbsp;pt</Text>
          {pointBack > 0 && <Text color="label.accent">&nbsp;(税抜価格&nbsp;&times;&nbsp;{pointBackRate}%)</Text>}
        </Flex>
      </Flex>
    </Box>
  )
}

CampaignPointSummary.propTypes = {
  pointBack: PropTypes.number.isRequired,
  pointBackRate: PropTypes.number.isRequired,
}

export default CampaignPointSummary
