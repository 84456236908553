import { IconButton } from '@chakra-ui/react'

import { AppleIcon } from '@!/components/Icon'

export const AppleButton = ({ onClick }) => {
  const styles = {
    color: 'white',
    fontSize: '22px',
    bg: '#030303',
    _hover: '#030303',
    _active: '#030303',
    width: '57px',
    height: '57px',
  }

  return <IconButton isRound={true} aria-label="Sign in with Apple" icon={<AppleIcon />} {...styles} onClick={onClick} />
}
