import { StyleSheet } from 'aphrodite'

export const styles = StyleSheet.create({
  inquiry_main_container: {
    padding: '40px 24px 0',
    maxWidth: '800px',
  },
  inquiry_help: {
    paddingBottom: '18px',
    margin: 0,
    textAlign: 'left',
    fontSize: '14px',
    color: '#656667',
  },
  inquiry_help_link: {
    color: '#F00',
  },
  inquiry_label: {
    display: 'block',
    marginBottom: '6px',
    fontSize: '13px',
  },
  inquiry_input: {
    display: 'block',
    marginBottom: '18px',
    border: '1px solid #c3c4c5',
    width: '100%',
    padding: '6px',
  },
  inquiry_textarea: {
    display: 'block',
    marginBottom: '12px',
    border: '1px solid #c3c4c5',
    width: '100%',
    height: '120px',
    padding: '6px',
  },
  inquiry_required: {
    color: '#d50606',
  },
  inquiry_button_box: {
    margin: '0 auto',
    textAlign: 'center',
  },
  inquiry_button: {
    padding: '12px 36px',
    marginBottom: '24px',
    background: '#d50606',
    color: '#FFF',
    borderRadius: '4px',
  },
  inquiry_attention: {
    border: '1px solid #e94841',
    padding: '10px',
    borderRadius: '4px',
    fontSize: '14px',
    margin: '0 0 30px 0',
  },
})
