import { AppProvider } from '@!/providers'

import { PointHistories } from '@!/User/PointHistories/containers/PointHistories'
import { useAppSettings } from '@!/Hooks/useAppSettings'

const PointHistoriesApp = () => {
  const jotaiStore = useAppSettings({ isSaveLastUrl: false })

  return (
    <AppProvider jotaiStore={jotaiStore}>
      <PointHistories />
    </AppProvider>
  )
}

export default PointHistoriesApp
