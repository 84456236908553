import { createIcon } from '@chakra-ui/icons'

export const CouponIcon = createIcon({
  displayName: 'CouponIcon',
  viewBox: '0 0 37 29',
  path: (
    <>
      <path
        d="M18.1 21C17.85 21 17.65 21.08 17.48 21.25C17.32 21.42 17.23 21.62 17.23 21.87C17.23 22.12 17.31 22.32 17.48 22.49C17.65 22.65 17.85 22.74 18.1 22.74C18.35 22.74 18.55 22.66 18.72 22.49C18.88 22.32 18.97 22.12 18.97 21.87C18.97 21.62 18.89 21.42 18.72 21.25C18.55 21.09 18.35 21 18.1 21ZM18.65 22.42C18.58 22.49 18.49 22.55 18.4 22.59C18.49 22.55 18.57 22.5 18.65 22.42C18.72 22.35 18.78 22.26 18.81 22.17C18.77 22.26 18.72 22.34 18.65 22.42Z"
        fill="#1D1D21"
      />
      ,
      <path
        d="M18.1 5.45996C17.85 5.45996 17.65 5.53996 17.48 5.70996C17.32 5.87996 17.23 6.07996 17.23 6.32996C17.23 6.57996 17.31 6.77996 17.48 6.94996C17.65 7.10996 17.85 7.19996 18.1 7.19996C18.35 7.19996 18.55 7.11996 18.72 6.94996C18.88 6.77996 18.97 6.57996 18.97 6.32996C18.97 6.07996 18.89 5.87996 18.72 5.70996C18.55 5.54996 18.35 5.45996 18.1 5.45996ZM18.65 6.87996C18.58 6.94996 18.49 7.00996 18.4 7.04996C18.49 7.00996 18.57 6.95996 18.65 6.87996C18.73 6.79996 18.78 6.71996 18.81 6.62996C18.77 6.71996 18.72 6.79996 18.65 6.87996Z"
        fill="#1D1D21"
      />
      ,
      <path
        d="M35.45 8.42C35.69 8.28 35.88 8.08 36.01 7.82C36.14 7.56 36.2 7.29 36.2 7.01V2.87C36.2 2.1 35.91 1.43 35.34 0.86C34.77 0.29 34.09 0 33.33 0H2.87C2.1 0 1.43 0.29 0.86 0.86C0.29 1.43 0 2.11 0 2.87V7.01C0 7.29 0.06 7.56 0.19 7.82C0.32 8.09 0.52 8.29 0.79 8.42C1.83 8.98 2.65 9.78 3.25 10.79C3.85 11.8 4.15 12.92 4.15 14.13C4.15 15.34 3.85 16.42 3.25 17.43C2.65 18.44 1.82 19.23 0.79 19.79C0.52 19.92 0.32 20.13 0.2 20.39C0.07 20.65 0.01 20.92 0.01 21.2V25.34C0.01 26.11 0.3 26.78 0.87 27.35C1.44 27.92 2.12 28.21 2.88 28.21H33.34C34.11 28.21 34.78 27.92 35.35 27.35C35.92 26.78 36.21 26.1 36.21 25.34V21.2C36.21 20.92 36.15 20.65 36.02 20.39C35.89 20.13 35.71 19.92 35.47 19.79C34.4 19.23 33.56 18.43 32.97 17.43C32.37 16.42 32.07 15.32 32.07 14.14C32.07 12.94 32.37 11.81 32.97 10.8C33.57 9.79 34.41 8.99 35.47 8.43L35.45 8.42ZM31.47 18.28C32.23 19.53 33.23 20.57 34.46 21.36V25.33C34.46 25.66 34.36 25.93 34.14 26.14C33.93 26.35 33.66 26.46 33.33 26.46H2.87C2.54 26.46 2.27 26.36 2.06 26.14C1.85 25.93 1.74 25.66 1.74 25.33V21.36C3 20.57 4.01 19.53 4.76 18.28C5.51 17.01 5.89 15.6 5.89 14.1C5.89 12.6 5.51 11.19 4.76 9.92C4.02 8.67 3.01 7.63 1.74 6.84V2.87C1.74 2.54 1.84 2.27 2.06 2.06C2.27 1.85 2.54 1.74 2.87 1.74H33.33C33.66 1.74 33.93 1.84 34.14 2.06C34.35 2.27 34.46 2.54 34.46 2.87V6.84C33.23 7.63 32.23 8.67 31.47 9.92C30.7 11.19 30.31 12.6 30.31 14.1C30.31 15.6 30.7 17.01 31.47 18.28ZM35.92 7.77C35.86 7.89 35.79 8 35.7 8.1C35.79 8.01 35.86 7.9 35.92 7.77C35.98 7.65 36.02 7.52 36.05 7.39C36.02 7.52 35.98 7.64 35.92 7.77ZM36.05 2.33C35.95 1.81 35.69 1.34 35.27 0.93C35.68 1.34 35.94 1.81 36.05 2.33ZM0.1 2.87C0.1 2.68 0.12 2.51 0.15 2.33C0.12 2.5 0.1 2.68 0.1 2.87ZM35.27 27.27C35.68 26.86 35.94 26.39 36.05 25.87C35.95 26.39 35.69 26.86 35.27 27.27ZM35.92 20.43C35.98 20.55 36.02 20.68 36.05 20.81C36.02 20.68 35.98 20.56 35.92 20.43C35.86 20.31 35.79 20.2 35.7 20.1C35.79 20.19 35.86 20.3 35.92 20.43Z"
        fill="#1D1D21"
      />
      ,
      <path
        d="M18.1 13.23C17.85 13.23 17.65 13.31 17.48 13.48C17.32 13.65 17.23 13.85 17.23 14.1C17.23 14.35 17.31 14.55 17.48 14.72C17.65 14.88 17.85 14.97 18.1 14.97C18.35 14.97 18.55 14.89 18.72 14.72C18.88 14.55 18.97 14.35 18.97 14.1C18.97 13.85 18.89 13.65 18.72 13.48C18.55 13.32 18.35 13.23 18.1 13.23ZM18.65 14.65C18.58 14.72 18.49 14.78 18.4 14.82C18.49 14.78 18.57 14.73 18.65 14.65C18.72 14.58 18.78 14.49 18.81 14.4C18.77 14.49 18.72 14.57 18.65 14.65Z"
        fill="#1D1D21"
      />
    </>
  ),
})
