import { useMemo } from 'react'
import { useAtom } from 'jotai'
import { safeSearchAtom } from '@!/atoms/safeSearchAtom'
import { useTabs } from '@!/Hooks'
import { getDevice } from '@!/Util'
import { Tabs, TabList, Tab, Text } from '@chakra-ui/react'

export const TopTabs = ({ tabName }) => {
  const [safeSearch] = useAtom(safeSearchAtom)
  const device = useMemo(() => getDevice(), [])
  const { currentTab, tabs, handleTabChange } = useTabs(tabName)

  const renderLabel = (label) => {
    if (label.includes('・')) {
      const parts = label.split('・')
      const insertBreak = device === 'sp' && !safeSearch
      return (
        <>
          {parts[0]}
          {insertBreak && <br />}
          <Text as="span" display={insertBreak ? 'none' : 'inline'}>
            ・
          </Text>
          {parts[1]}
        </>
      )
    }
    return label
  }

  return (
    <Tabs index={currentTab.index} onChange={handleTabChange} colorScheme="red" width="100%" boxShadow="0px 6px 6px -5px rgba(0, 0, 0, 0.25)">
      <TabList width="100%">
        {tabs.map((tab) => (
          <Tab
            key={tab.name}
            flex="1"
            p={2}
            whiteSpace="nowrap"
            wordBreak="break-all"
            _selected={{ color: '#FD4238', borderBottom: '4px solid #FD4238' }}
            _notLast={{ borderRight: '0.5px solid #DCDCE1' }}
          >
            <Text fontSize="sm" fontWeight="semibold">
              {renderLabel(tab.label)}
            </Text>
          </Tab>
        ))}
      </TabList>
    </Tabs>
  )
}
