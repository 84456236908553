import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/react'

export const PrimaryButton = ({ children, isDisabled, ...props }) => {
  return (
    <Button isDisabled={isDisabled} colorScheme="primary" p="0px" {...props}>
      {children}
    </Button>
  )
}

PrimaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  p: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  variant: PropTypes.string,
}

PrimaryButton.defaultProps = {
  p: '0px',
  width: '100%',
  height: '44px',
  fontSize: '14px',
  variant: 'solid',
}
