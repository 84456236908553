import { useMemo } from 'react'
import { Center, Box } from '@chakra-ui/react'
import { getOS, openApp } from '@!/Util'
import { OpenAppStore } from '@!/User/Login/components/OpenAppStore'
import { RoundedBorderBox } from '@!/components/Box/RoundedBorderBox'

export const WarningAppRegister = () => {
  const os = useMemo(() => getOS(), [])
  const handleOpenApp = (e) => {
    e.preventDefault()
    openApp(os)
  }

  return (
    <Center>
      <RoundedBorderBox py={3}>
        <Box fontSize="sm" px={3}>
          マンガBANG（アプリ）をご利用中の方は、
          <br />
          事前にアプリで会員登録をしてから、
          <br />
          マンガBANGブックスでログインしてください。
        </Box>
        {['ios', 'android'].includes(os) ? (
          <Box pt={2}>
            <OpenAppStore handleOpenApp={handleOpenApp} />
          </Box>
        ) : null}
      </RoundedBorderBox>
    </Center>
  )
}
