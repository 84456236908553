import { PropTypes } from 'prop-types'
import { VStack, StackDivider } from '@chakra-ui/react'

import { BookListItem } from '@!/Store/Common/components/BookList'

export const BookList = ({ book, volumes, currentTime }) => {
  return (
    <VStack divider={<StackDivider style={{ margin: '1rem 0' }} />} spacing={0}>
      {volumes.map((volume, index) => {
        return <BookListItem key={`${volume.distribution_id}-${index}`} book={book} volume={volume} currentTime={currentTime} />
      })}
    </VStack>
  )
}

BookList.propType = {
  book: PropTypes.object.isRequired,
  volumes: PropTypes.array.isRequired,
  currentTime: PropTypes.string.isRequired,
}
