import { atom } from 'jotai'

// purchaseItemから期限切れではない作品を格納する
export const booksAtom = atom([])
booksAtom.debugLabel = 'booksAtom'

// 選択中の巻
export const selectedBookAtom = atom({})
selectedBookAtom.debugLabel = 'selectedBookAtom'

// 選択中の作品
export const selectedBookTitleAtom = atom({})
selectedBookTitleAtom.debugLabel = 'selectedBookTitleAtom'

// ソート順
export const sortTypeAtom = atom('buy')
sortTypeAtom.debugLabel = 'sortTypeAtom'

// purchaseItemから期限切れの作品を格納する
export const removedBooksAtom = atom([])
removedBooksAtom.debugLabel = 'removedBooksAtom'

// 巻別表示用のストアタイトルを格納する
export const bookTitlesAtom = atom([])
bookTitlesAtom.debugLabel = 'bookTitlesAtom'
