import { useState } from 'react'
import { useAtom } from 'jotai'
import { useMutation } from 'react-query'
import { execLogoutApi } from '@!/Api/User'
import { resetUserAtom } from '@!/atoms/userInfoAtom'
import { cartItemsAtom } from '@!/atoms/cartItemsAtom'
import { purchaseItemsAtom } from '@!/atoms/purchaseItemsAtom'
import { savePurchaseData, resetCartItemsLoggedIn } from '@!/Util/LocalStorageData'
import { useAppCookies } from '@!/Hooks'

export const useLogout = () => {
  const [, resetUser] = useAtom(resetUserAtom)
  const [, setCartItems] = useAtom(cartItemsAtom)
  const [, setPurchaseItems] = useAtom(purchaseItemsAtom)
  const [apiError, setApiError] = useState(null)
  const { resetUserCookies } = useAppCookies()

  const execLogoutMutation = useMutation({
    mutationFn: () => execLogoutApi(),
    onSuccess: () => {
      setApiError(null)
      // atomのリセット
      resetUser()
      setCartItems([])
      setPurchaseItems(null)
      // cookieのリセット
      resetUserCookies()
      // local storageのリセット
      savePurchaseData(null)
      resetCartItemsLoggedIn()
      window.location.href = '/'
    },
    onError: () => {
      // エラーになる場合はサーバーエラーのみ
      setApiError('ログアウトに失敗しました。')
    },
  })

  return [execLogoutMutation, apiError]
}
