import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Stack } from '@chakra-ui/react'

import { useMutation } from 'react-query'

import { EmailRegistration } from '../components'
import { registrationEmailApi } from '@!/Api/User'
import { CircularProgressModal } from '@!/components/Modal'

export const RegistrationForm = () => {
  const navigate = useNavigate()
  const [isDisabled, setDisabled] = useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState('')

  const mutation = useMutation({
    mutationFn: registrationEmailApi,
    onMutate: () => {
      setDisabled(true)
      if (formErrorMessage) {
        setFormErrorMessage('')
      }
    },
    onSuccess: () => {
      showSuccessMessage()
    },
    onError: (error) => {
      handleErrorMessage(error)
      setDisabled(false)
    },
  })

  const handleErrorMessage = (error) => {
    setFormErrorMessage(error.message)
  }

  const handleRegistration = ({ email, password }) => {
    mutation.mutate({
      email: email,
      password: password,
    })
  }

  const showSuccessMessage = () => {
    // NOTE: メールアクティベーションを挟むのでメール送信完了画面に遷移
    navigate('/registration_complete')
  }

  return (
    <Stack>
      <EmailRegistration formErrorMessage={formErrorMessage} isDisabled={isDisabled} handleRegistration={handleRegistration} />
      <CircularProgressModal isOpen={mutation.isLoading} />
    </Stack>
  )
}
