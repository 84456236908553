import { PropTypes } from 'prop-types'

import { Stack, VStack } from '@chakra-ui/react'

import { SectionTitle } from '@!/Store/Common/components/'
import { BookTitle } from '@!/Store/Common/components/ListItem'
import { RankingCrown, ScrollableContent } from '@!/Store/Home/components'

/* 横スクロール型のランキングリスト */
export const ScrollableRankingList = ({ rankingBookTitles, tabName }) => {
  const sectionTitle = '人気ランキング'

  const RankingTitle = ({ title }) => {
    return (
      <VStack spacing={0} mr={3}>
        <RankingCrown ranking={title.ranking} />
        <BookTitle title={title.name} titleId={title.book_title_id} imageUrl={title.image_url} sectionTitle={`${tabName}_${sectionTitle}`} w="100px" />
      </VStack>
    )
  }
  const items = rankingBookTitles.map((title, index) => {
    return <RankingTitle key={`ranking-${index}-${title.book_title_id}`} title={title} />
  })

  return (
    <Stack spacing={0} mx={4} my={3}>
      <SectionTitle title={sectionTitle} />
      <ScrollableContent>
        <Stack direction="row" spacing={0} mr={3}>
          {items}
        </Stack>
      </ScrollableContent>
    </Stack>
  )
}

ScrollableRankingList.propTypes = {
  rankingBookTitles: PropTypes.array.isRequired,
  tabName: PropTypes.string.isRequired,
}
