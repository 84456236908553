import { AppProvider } from '@!/providers'
import CoinHistories from '../containers/CoinHistories'
import { useAppSettings } from '@!/Hooks/useAppSettings'

const CoinHistoriesApp = () => {
  const jotaiStore = useAppSettings({ isSaveLastUrl: false })

  return (
    <AppProvider jotaiStore={jotaiStore}>
      <CoinHistories />
    </AppProvider>
  )
}

export default CoinHistoriesApp
