import { PropTypes } from 'prop-types'

import { Link as RouterLink } from 'react-router-dom'

import { Text, VStack } from '@chakra-ui/react'

import { CenterLineText } from './Text'
import { PrimaryButton } from '@!/components/Button'
import { WarningAppRegister } from '@!/User/Login/components'

export const LeadToRegistration = ({ device }) => {
  return (
    <VStack spacing={0} my={3}>
      <CenterLineText fontSize="sm" fontWeight="bold" my={4}>
        新規会員登録
      </CenterLineText>
      <WarningAppRegister />
      {device == 'pc' && (
        <Text color="label.accent" fontSize="sm" fontWeight="bold" my={4}>
          お手持ちのスマートフォンでアプリを開いてください
        </Text>
      )}
      <CenterLineText fontSize="sm" fontWeight="bold" my={2}>
        初めての方はこちら
      </CenterLineText>
      <PrimaryButton width="full" as={RouterLink} to="registrations" my={2}>
        新規会員登録（無料）
      </PrimaryButton>
    </VStack>
  )
}

LeadToRegistration.propTypes = {
  device: PropTypes.string.isRequired,
  handleOpenApp: PropTypes.func,
}
