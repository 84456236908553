import { useQuery } from 'react-query'

import { getAnnouncemetsApi } from '@!/Api/Announcement'
import { announcementsKeys } from '@!/Api/queryKeys'

export function useAnnouncements() {
  return useQuery(announcementsKeys.announcements, async () => {
    return await getAnnouncemetsApi()
  })
}
