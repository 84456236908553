import { PropTypes } from 'prop-types'

import { ReadBookButton, BuyNowButton, ReadFreeBookButton } from '@!/Store/Common/components/BookList/Button'

export const PrimaryActionButton = ({ volume }) => {
  return (
    <>
      {volume.purchased ? <ReadBookButton volume={volume} /> : volume.price === 0 ? <ReadFreeBookButton volume={volume} /> : <BuyNowButton volume={volume} />}
    </>
  )
}

PrimaryActionButton.propType = {
  volume: PropTypes.object.isRequired,
}
