import { PropTypes } from 'prop-types'
import { useAtom } from 'jotai'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { Banner } from './Banner'
import notLoggedInHomeBannerImage from '@images/not_logged_in_home_banner.webp'
import loggedInHomeBannerImage from '@images/logged_in_home_banner.webp'
import homeBannerNoImagePng from '@images/home_banner-noimage.png'

export const HomeBanner = ({ tabName, ...props }) => {
  const [userHash] = useAtom(userHashAtom)

  const banner = userHash ? loggedInHomeBannerImage : notLoggedInHomeBannerImage
  const gtmClick = userHash ? `${tabName}バナー_event_after` : `${tabName}バナー_event_before`

  return (
    <Banner
      src={banner}
      fallbackSrc={homeBannerNoImagePng}
      href="/event"
      alt="初回限定90%OFF、全作品対象40%ポイント還元キャンペーン"
      gtmClick={gtmClick}
      {...props}
    />
  )
}

HomeBanner.propTypes = {
  tabName: PropTypes.string.isRequired,
}
