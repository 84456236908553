import StoreSearchList from '@!/Store/SearchList/containers/StoreSearchList'
import StoreApp from '@!/Store/Common/containers/StoreApp'
import { AppProvider } from '@!/providers'

const StoreSearchListApp = (props) => {
  return (
    <AppProvider>
      <StoreApp {...props}>
        <StoreSearchList type={props.type} q={props.q} result={props.result} searchTags={props.search_tags} tabName={props.tab_name} />
      </StoreApp>
    </AppProvider>
  )
}

export default StoreSearchListApp
