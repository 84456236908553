import PropTypes from 'prop-types'
import { AddCartIcon } from '@!/components/Icon'
import { NormalIconButton } from '@!/components/Button'
import { ConfirmPopupModal } from '@!/components/Modal'
import { useAddCart } from '@!/Hooks/useAddCart'

export const AddCartIconButton = ({ detail, ...props }) => {
  const { handleAddCartItem, showConfirmPopup, confirmMessage, closeConfirmPopup } = useAddCart(detail)

  return (
    <>
      <NormalIconButton icon={<AddCartIcon width="1.5em" height="1.5em" />} onClick={handleAddCartItem} aria-label="カートに追加" {...props} />
      <ConfirmPopupModal text={confirmMessage} isOpen={showConfirmPopup} handlePopup={closeConfirmPopup} />
    </>
  )
}

AddCartIconButton.propTypes = {
  detail: PropTypes.object.isRequired,
}
