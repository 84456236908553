import { createIcon } from '@chakra-ui/icons'

export const RightArrowIcon = createIcon({
  displayName: 'RightArrowIcon',
  viewBox: '0 0 18 24',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2499 11.0075L13.25 11.0075L13.25 11C13.25 10.6935 13.1267 10.4337 12.8975 10.2159C12.8972 10.2157 12.897 10.2155 12.8967 10.2152L7.53228 5.04646C7.33561 4.84537 7.07663 4.75 6.79843 4.75C6.21797 4.75 5.75 5.19596 5.75 5.78644C5.75 6.0753 5.86998 6.33471 6.06547 6.52726L6.06766 6.52939L10.7189 11L6.06766 15.4706L6.06756 15.4705L6.06155 15.4767C5.87043 15.6735 5.75 15.9307 5.75 16.2203C5.75 16.8054 6.21941 17.25 6.79843 17.25C7.07663 17.25 7.33561 17.1546 7.53228 16.9535L12.8975 11.7841C13.1307 11.5625 13.241 11.3036 13.2499 11.0075Z"
    />
  ),
})
