export const userCoinKeys = {
  coinCounts: (userHash) => ['user', 'coin_counts', userHash],
}

export const userPointKeys = {
  pointCounts: (userHash) => ['user', 'point_counts', userHash],
  availablePoints: (userHash) => ['user', 'available_points', userHash],
}

export const pointBackMeasureKeys = {
  pointBackMeasure: ['pointBackMeasure'],
}

export const cartItemsKeys = {
  cartItemsLoggedIn: (userHash) => ['user', 'cartItems', 'loggedIn', userHash],
  cartItemsNotLoggedIn: () => ['cartItems', 'notLoggedIn'],
}

export const cardInfoKeys = {
  cardInfo: ['cardInfo'],
}

export const bookDetailsKeys = {
  bookDetails: (bookId) => ['bookDetails', bookId],
}

export const purchaseHistoriesKeys = {
  purchaseHistories: (userHash) => ['user', 'purchaseHistories', userHash],
  purchasedBookTitle: (userHash, bookTitleId) => ['user', 'purchaseHistories', userHash, bookTitleId],
}

export const announcementsKeys = {
  announcements: ['announcements'],
  announcementDetail: (announcementId) => ['announcementDetail', announcementId],
}

export const userAccountKeys = {
  accountInfo: (userHash) => ['user', 'account_info', userHash],
}

export const userCheckPurchasedBookKeys = {
  check: (userHash, distributionIds) => ['user', 'checkPurchasedBooks', userHash, distributionIds],
}

export const volumesKeys = {
  volumes: (bookTitleId) => ['volumes', bookTitleId],
}

export const userCouponKeys = {
  coupons: (userHash) => ['user', 'coupons', userHash],
}

export const newBooksKeys = {
  all: ['newBooks'],
  filtered: (safeSearch, genreName, page, perPage) => [...newBooksKeys.all, safeSearch, genreName, page, perPage],
}
