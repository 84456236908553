import { Box, Stack, Image, Link } from '@chakra-ui/react'
import { PointBackBanner } from '@!/components/Banner'
import ReadBookLinkCart from '../components/ReadBookLinkCart'
import BookshelfLinkButton from '../components/BookshelfLinkButton'

const BookPurchaseComplete = ({ books, coupons }) => {
  return (
    <Stack spacing={0} my="2px" textAlign="center" px={4} pb={5}>
      <Box fontWeight="bold" my={6}>
        ご購入ありがとうございました。
      </Box>
      {coupons && coupons.length && (
        <>
          {coupons.map((coupon, i) => (
            <Box key={i} margin="0 auto" pb={4} maxW="430px">
              <Image src={coupon.image_url} alt={coupon.title} w="100%" />
            </Box>
          ))}
          <Link href="/user/coupons" fontSize="xs" mb={7}>
            クーポンを確認する
          </Link>
        </>
      )}
      <Box mb="10px">
        <ReadBookLinkCart books={books} />
      </Box>
      <Box mb="30px">
        <BookshelfLinkButton width={books.length > 1 ? '95%' : '240px'} />
      </Box>
      <PointBackBanner />
    </Stack>
  )
}

export default BookPurchaseComplete
