import { useQuery } from 'react-query'

import { getAnnouncemetDetailApi } from '@!/Api/Announcement'
import { announcementsKeys } from '@!/Api/queryKeys'

export function useAnnouncementDetail(announcementId) {
  return useQuery(announcementsKeys.announcementDetail(announcementId), async () => {
    if (announcementId) {
      return await getAnnouncemetDetailApi(announcementId)
    }
  })
}
