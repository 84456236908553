import { AppProvider } from '@!/providers'
import BookPurchase from '@!/Store/Purchase/containers/BookPurchase'

const BookPurchaseApp = (props) => {
  return (
    <AppProvider>
      <BookPurchase bookId={props.bookId} tokenApiUrl={props.tokenApiUrl} shopId={props.shopId} />
    </AppProvider>
  )
}

export default BookPurchaseApp
