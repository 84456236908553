import { useEffect, useRef, useState } from 'react'
import { Box, Text, VStack, Checkbox, OrderedList, ListItem, Heading, useToast } from '@chakra-ui/react'
import { PrimaryButton } from '@!/components/Button'
import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { CircularProgressModal, YesNoPopupModal } from '@!/components/Modal'
import { useResign, useLogout } from '@!/Hooks'

const AccountResign = () => {
  const toast = useToast()
  const [resignMutation, resignStatus] = useResign()
  const [validate, setValidate] = useState(null)
  const [confirmResign, setConfirmResign] = useState(false)
  const [execLogoutMutation] = useLogout()
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: '退会' }])
  }, [])

  const agreeRef = useRef(null)

  useEffect(() => {
    if (resignStatus === 'success') {
      execLogoutMutation.mutate()
      toast({
        title: '成功',
        description: '退会処理が完了しました。',
        status: 'success',
        duration: 9000,
        isClosable: true,
        onCloseComplete: () => {
          window.location.href = '/'
        },
      })
    }
  }, [resignStatus])

  const resign = (e) => {
    e.preventDefault()

    if (!agreeRef.current.checked) {
      setValidate('agree')
    } else {
      setValidate(null)
      setConfirmResign(true)
    }
  }

  const handleResign = (isResign) => {
    if (isResign) {
      resignMutation.mutate()
    }
    setConfirmResign(false)
  }

  return (
    <Box p="32px 16px" alignItems="center" color="label.primary" fontSize="sm">
      <CircularProgressModal isOpen={resignMutation.isLoading} />
      <YesNoPopupModal
        title={'退会します。よろしいですか？'}
        handleCancel={() => handleResign(false)}
        handleSubmit={() => handleResign(true)}
        cancelTitle={'キャンセル'}
        submitTitle={'はい'}
        isOpen={confirmResign}
      />
      <form onSubmit={(e) => resign(e)}>
        <VStack spacing={0}>
          <Heading as="h3" size="lg" my={4}>
            退会
          </Heading>
          <Box my={4}>
            <Box fontWeight="bold">下記の注意点をご確認の上、同意するにチェックをして退会するボタンを押してください。</Box>
            <OrderedList>
              <VStack spacing={0} alignItems="left">
                <ListItem my={2}>今まで購入したコインとマンガデータすべてが消去されます。</ListItem>
                <ListItem my={2}>一度退会するとデータを復元することができません。</ListItem>
                <ListItem my={2}>マンガBANG（アプリ）と連携しているアカウントの場合、マンガBANG（アプリ）も利用できなくなります。</ListItem>
              </VStack>
            </OrderedList>
          </Box>
          <Checkbox size="sm" ref={agreeRef} id="agree" my={4}>
            上記を確認して同意します。
          </Checkbox>
          {resignStatus === 'fail' && <Text color="#f00">退会に失敗しました。</Text>}
          {validate === 'agree' ? <Text color="#f00">退会には同意が必要です。</Text> : null}
          <PrimaryButton variant="outline" type="submit" width="165px" height="37px" my={4}>
            退会する
          </PrimaryButton>
        </VStack>
      </form>
    </Box>
  )
}

export default AccountResign
