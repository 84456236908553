import { StyleSheet } from 'aphrodite'

export default StyleSheet.create({
  announcements_main_title: {
    margin: 0,
    fontSize: '20px',
    padding: '20px 10px',
    color: '#000',
    fontWeight: 'bold',
  },
  announcements_list: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
  },
  announcements_list_link: {
    textDecoration: 'none',
  },
  announcements_list_box: {
    padding: '6px 12px',
  },
  announcements_list_item: {
    borderBottom: 'solid 1px #eee',
  },
  announcements_list_title: {
    fontSize: '18px',
    color: '#000',
  },
  announcements_list_date: {
    fontSize: '10px',
    color: '#333',
  },
  announcements_list_detail_title: {
    margin: 0,
    fontSize: '20px',
    padding: '20px 10px 10px 10px',
    color: '#000',
    fontWeight: 'bold',
  },
  announcements_list_detail: {
    margin: 0,
    padding: '0 10px 20px 10px',
    color: '#000',
    fontSize: '16px',
  },
  announcements_list_detail_date: {
    textAlign: 'right',
    paddingBottom: '10px',
    fontSize: '14px',
  },
})
