import PropTypes from 'prop-types'
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Stack, Button } from '@chakra-ui/react'

export const LessCoinsPopupModal = ({ handleGoCoinPurchase, handleClose, isOpen = true }) => (
  <Modal isCentered size={'xl'} isOpen={isOpen} onClose={handleClose}>
    <ModalOverlay />
    <ModalContent>
      <ModalBody>コインが足りません。コインを追加で購入してください。</ModalBody>
      <ModalFooter>
        <Stack direction="row" spacing={0}>
          <Button onClick={handleClose}>閉じる</Button>
          <Button onClick={handleGoCoinPurchase} ml={4}>
            コインを購入する
          </Button>
        </Stack>
      </ModalFooter>
    </ModalContent>
  </Modal>
)

LessCoinsPopupModal.propTypes = {
  isOpen: PropTypes.bool,
  handleGoCoinPurchase: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
}
