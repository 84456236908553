import { useState } from 'react'
import { useMutation } from 'react-query'

import { registerAccountEmailApi } from '@!/Api/User'

export const useRegisterAccountEmail = () => {
  const [status, setStatus] = useState(null)
  const [apiError, setApiError] = useState(null)

  const registerAccountEmailMutation = useMutation({
    mutationFn: ({ email, password }) => registerAccountEmailApi(email, password),
    onSuccess: () => {
      setStatus('success')
    },
    onError: (error) => {
      if (error.data && error.data.error) {
        setApiError(error.data.error)
      } else {
        setApiError('unknown_error')
      }
      setStatus('fail')
    },
  })

  return [registerAccountEmailMutation, status, apiError]
}
