import { createStore } from 'jotai'
import { isSaveLastUrlAtom, csrfTokenAtom, csrfParamsAtom, alertMessageAtom } from '@!/atoms/appSettingsAtom'

export const useAppSettings = ({ isSaveLastUrl, csrfToken = null, csrfParams = null, alertMessage = null }) => {
  const store = createStore()
  store.set(isSaveLastUrlAtom, isSaveLastUrl)
  store.set(csrfTokenAtom, csrfToken)
  store.set(csrfParamsAtom, csrfParams)
  store.set(alertMessageAtom, alertMessage)

  return store
}
