import PropTypes from 'prop-types'
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Link, Text, Image, Center } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util'
import SearchFormPng from '@images/search_form.png'

export const SearchGuidePopupModal = ({ handleClose, isOpen = true }) => (
  <Modal isCentered size="md" isOpen={isOpen} onClose={handleClose}>
    <ModalOverlay />
    <ModalContent fontSize="sm">
      <ModalBody mt={6}>
        <Text>他ジャンルの検索は各ジャンルTOP、全ジャンル検索はHOMEにある検索ボックスからご利用いただけます。</Text>
        <Center>
          <Image src={buildAssetUrlString(SearchFormPng)} alt="検索について" />
        </Center>
      </ModalBody>
      <ModalFooter justifyContent="center" fontSize="sm">
        <Link onClick={handleClose}>閉じる</Link>
      </ModalFooter>
    </ModalContent>
  </Modal>
)

SearchGuidePopupModal.propTypes = {
  isOpen: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
}
