import { PrimaryButton } from '@!/components/Button'
import { buildUrlString } from '@!/Util'
import { useAtom } from 'jotai'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { useNavigationHandler } from '@!/Hooks/'

export const BuyNowButton = ({ volume, buttonText = '今すぐ購入する', height = '37px', ...options }) => {
  const [userHash] = useAtom(userHashAtom)
  const { redirectToLoginWithToast } = useNavigationHandler()

  const handleClick = () => {
    if (userHash === undefined) {
      redirectToLoginWithToast()
    } else {
      window.location.href = buildUrlString('/store/purchase', { book_id: volume.distribution_id })
    }
  }

  return (
    <>
      <PrimaryButton width="100%" as="a" onClick={handleClick} {...options} height={height}>
        {buttonText}
      </PrimaryButton>
    </>
  )
}
