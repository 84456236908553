import { useQuery } from 'react-query'
import { userAccountKeys } from '@!/Api/queryKeys'
import { getAccountInfoApi } from '@!/Api/User'

export const useAccountInfo = (userHash) => {
  return useQuery(
    userAccountKeys.accountInfo(userHash),
    async () => {
      return await getAccountInfoApi()
    },
    {
      enabled: !!userHash,
    },
  )
}
