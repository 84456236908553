import { PropTypes } from 'prop-types'

import { Stack, Wrap, WrapItem } from '@chakra-ui/react'

import { SectionTitle } from '@!/Store/Common/components/SectionTitle'
import { TagSearchItem } from './TagSearchItem'

/* タグ検索用のリンク一覧 */
export const TagSearchList = ({ title, tags, buildSearchUrl }) => {
  return (
    <Stack spacing={0} mx={4} my={3}>
      <SectionTitle title={title} />
      <Wrap spacing={0}>
        {tags.map((tag, index) => {
          return (
            <WrapItem key={`tag-${index}`} my={1.5} mr={3}>
              <TagSearchItem tag={tag} url={buildSearchUrl(tag)}></TagSearchItem>
            </WrapItem>
          )
        })}
      </Wrap>
    </Stack>
  )
}

TagSearchList.propTypes = {
  title: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  buildSearchUrl: PropTypes.func.isRequired,
}
