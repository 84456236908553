import PropTypes from 'prop-types'
import { Box, Text, Link, HStack, VStack } from '@chakra-ui/react'
import { CoverImage } from '@!/Store/Common/components'
import { AddAllVolumeButton } from '@!/Store/Common/components/Cart'

export const BulkPurchaseCard = ({ title }) => {
  return (
    <VStack
      spacing={0}
      flex="1"
      justifyContent="space-between"
      backgroundColor="bg.primary"
      boxShadow="0px 3px 12px rgba(0, 0, 0, 0.06)"
      borderRadius="lg"
      px={2}
      py={3}
      h="100%"
    >
      <Link
        display="flex"
        flexDirection="column"
        href={`/store/books?book_title_id=${title.title_id}`}
        color="label.primary"
        textDecoration="none"
        fontWeight="600"
        w="100%"
        h="100%"
      >
        <Text fontSize="13px" lineHeight="20px" textAlign="left" pb={6} flex="1">
          {title.title_name}
        </Text>
        <HStack spacing={0} alignItems="stretch">
          <Box minW="54px" mr={2}>
            <CoverImage src={title.image_url} alt={title.title_name} />
          </Box>
          <VStack spacing={0} flex="1" justifyContent="space-between" alignItems="flex-start" fontSize="11px" fontWeight="300" lineHeight="11px">
            <Box>
              <Text textAlign="left">
                全
                <Text as="span" fontWeight="semibold">
                  {title.total_volume}
                </Text>
                巻
              </Text>
              <Box pt={1} lineHeight="13px">
                <Text>
                  <Text as="span" fontWeight="semibold">
                    {title.total_price.toLocaleString()}
                  </Text>
                  <Text as="span" pl={1}>
                    円
                  </Text>
                </Text>
                <Text>(税込)</Text>
              </Box>
            </Box>
            <Box w="100%" p={1} textAlign="center" bgColor="bg.secondary" fontWeight="semibold" lineHeight="14px" borderRadius="3px">
              <Text as="span" color="label.accent">
                {title.total_point_count.toLocaleString()}pt
              </Text>
              <Text as="span">還元</Text>
            </Box>
          </VStack>
        </HStack>
      </Link>
      <Box mt={2} w="100%">
        <AddAllVolumeButton bookTitleId={title.title_id} buttonText="全巻カート" />
      </Box>
    </VStack>
  )
}

BulkPurchaseCard.propTypes = {
  title: PropTypes.object.isRequired,
}
