import { PropTypes } from 'prop-types'
import { Box } from '@chakra-ui/react'

import { SearchBox } from '@!/Store/Common/components/Search'
import { buildUrlString } from '@!/Util'

export const SearchForm = ({ keyword, genreName, placeholder = 'マンガを検索' }) => {
  const handleSearch = (q) => {
    const params = { type: 'keyword', q: q }

    if (genreName) {
      params.genre_name = genreName
    }

    const url = buildUrlString('/store/search', params)

    if (url) {
      window.location.href = url
    }
  }

  return (
    <Box mx={4} my={3}>
      <SearchBox defaultValue={keyword} handleSearch={handleSearch} placeholder={placeholder} />
    </Box>
  )
}

SearchForm.propType = {
  keyword: PropTypes.string,
  genreName: PropTypes.string,
  placeholder: PropTypes.string,
}
