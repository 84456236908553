import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { VStack, Box, Link } from '@chakra-ui/react'

const BookPurchaseError = ({ errorMessage }) => {
  const navigate = useNavigate()
  const pageBack = (event) => {
    event.preventDefault()
    navigate(-1)
  }

  return (
    <VStack>
      <Box fontWeight="bold" my="30px">
        {errorMessage}
      </Box>
      <Box mb="20px">
        <Link href="#" onClick={pageBack}>
          前のページへ戻る
        </Link>
      </Box>
    </VStack>
  )
}

BookPurchaseError.propTypes = {
  errorMessage: PropTypes.string.isRequired,
}

export default BookPurchaseError
