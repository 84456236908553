import React from 'react'
import { Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react'

export default function SortBlock({ sortType, changeSortType }) {
  return (
    <Menu autoSelect={false}>
      <MenuButton color="label.primary" fontWeight="semibold" mr={3} textDecoration="underline">
        並び替え
      </MenuButton>
      <MenuList>
        <MenuItem onClick={(e) => changeSortType(e, 'buy')} bg={sortType === 'buy' ? 'bg.tertiary' : null} fontWeight={sortType === 'buy' ? 'semibold' : null}>
          購入順
        </MenuItem>
        <MenuItem
          onClick={(e) => changeSortType(e, 'title')}
          bg={sortType === 'title' ? 'bg.tertiary' : null}
          fontWeight={sortType === 'title' ? 'semibold' : null}
        >
          作品順
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
