import React from 'react'
import LazyLoad from 'react-lazyload'
import { CoverImage } from '@!/Store/Common/components'
import { Box, UnorderedList, ListItem, Text } from '@chakra-ui/react'
import { ellipsis } from '@!/Store/Common/util'

export default function BookshelfItems({ books, purchaseItems, handleClickBook, showNumber }) {
  const itemWidth = `calc(100% / ${showNumber})`

  return (
    <LazyLoad offset={100} once>
      <UnorderedList m={0} listStyleType="none" display="flex" flexWrap="wrap">
        {books.map((book) => {
          const isNotPurchased = purchaseItems && !purchaseItems[book.mddc_id]
          return (
            <ListItem
              key={book.mddc_id}
              flex={`0 0 ${itemWidth}`}
              verticalAlign="top"
              cursor="pointer"
              onClick={() => handleClickBook(purchaseItems ? purchaseItems[book.mddc_id] : book, book)}
              onKeyDown={() => {}}
            >
              <Box p={2}>
                <CoverImage width="100%" variant={isNotPurchased ? 'grayscale' : 'default'} src={book.image_url} alt={book.full_name} />
                <Box fontSize="xs" color="label.primary" textAlign="center">
                  {isNotPurchased && (
                    <Text as="span" color="label.accent">
                      (未購入)
                    </Text>
                  )}
                  <Text as="span">{ellipsis(book.full_name, isNotPurchased ? 5 : 7, 2)}</Text>
                </Box>
              </Box>
            </ListItem>
          )
        })}
      </UnorderedList>
    </LazyLoad>
  )
}
