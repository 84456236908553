import { PropTypes } from 'prop-types'

import { Box, Text } from '@chakra-ui/react'

export const PointLabel = (props) => {
  const { pointCount } = props

  return (
    <Box color={'#E94841'}>
      <Text as="span" fontSize={'2em'} fontWeight={700}>
        {pointCount.toLocaleString()}
      </Text>
      <Text as="span" fontSize={'1em'} fontWeight={600} marginInlineStart={'0.5em'}>
        pt
      </Text>
    </Box>
  )
}

PointLabel.propType = {
  pointCount: PropTypes.number.isRequired,
}
