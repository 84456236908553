import axios from '@!/lib/axios'
import { getCsrfToken } from '@!/Util/PostRequest'

import { buildUrlString } from '@!/Util/buildUrlString'

export const coinCountsApi = async () => {
  try {
    return await axios.get(
      '/api/v1/user/coin/counts',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const pointCountApi = async () => {
  try {
    return await axios.get(
      '/api/v1/user/point_count',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const availablePointApi = async () => {
  try {
    const url = buildUrlString('/api/v1/user/available_points.json')
    return await axios.get(url)
  } catch (error) {
    throw new Error(error.message)
  }
}

export const loginEmailApi = async (loginParams) => {
  try {
    const url = buildUrlString('/api/v1/user/login/email.json')

    return await axios.post(url, loginParams, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    })
  } catch (error) {
    const message = error.data?.message || 'エラーが発生しました'
    throw new Error(message)
  }
}

export const registrationEmailApi = async (registrationParams) => {
  try {
    const url = buildUrlString('/api/v1/user/registration_email.json')

    return await axios.post(url, registrationParams, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    })
  } catch (error) {
    const message = error.data?.message || 'エラーが発生しました'
    throw new Error(message)
  }
}

export const getAccountInfoApi = async () => {
  return await axios.get(
    '/api/v1/user/account',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const execLogoutApi = async () => {
  return await axios.post(
    '/api/v1/user/logout.json',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    },
  )
}

export const registerAccountEmailApi = async (email, password) => {
  return await axios.post(
    '/api/v1/user/register_account_email',
    { email: email, password: password },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    },
  )
}

export const updatePasswordApi = async (currentPassword, newPassword) => {
  return await axios.post(
    '/api/v1/user/update_password',
    { password: currentPassword, new_password: newPassword },
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    },
  )
}

export const resignApi = async () => {
  return await axios.post(
    '/api/v1/user/resign',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    },
  )
}

export const resetPasswordApi = async (resetPasswordParams) => {
  return await axios.post('/api/v1/user/reset_password.json', resetPasswordParams, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
  })
}

export const coinHistoriesApi = async () => {
  return await axios.get(
    '/api/v1/user/coin/histories.json',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const checkPurchasedBooksApi = async (distributionIds) => {
  const url = buildUrlString('/api/v1/users/stores/check_purchased_books', { distribution_ids: distributionIds })
  return await axios.get(
    url,
    {},
    {
      headers: {
        'Content-Type': 'application/json',
      },
    },
  )
}

export const couponsApi = async () => {
  try {
    return await axios.get(
      '/api/v1/users/coupons',
      {},
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
  } catch (error) {
    console.log(error)
    throw error
  }
}
