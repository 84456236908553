import { useMemo, useEffect } from 'react'
import { useAtom } from 'jotai'
import { Link as RouterLink } from 'react-router-dom'

import { Center, Container, Heading, Link, Stack } from '@chakra-ui/react'

import { RegistrationForm, SocialRegistrationForm } from './'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { useJumpLastUrl } from '@!/Hooks'
import { userHashAtom } from '@!/atoms/userInfoAtom'

export const RegistrationPage = () => {
  const jumpLastUrl = useJumpLastUrl()
  const [userHash] = useAtom(userHashAtom)
  const isLoggedIn = useMemo(() => !!userHash, [userHash])
  // NOTE: ログイン済みの場合は元画面 or ルートにリダイレクト
  useEffect(() => {
    if (isLoggedIn) {
      jumpLastUrl()
    }
  }, [isLoggedIn])

  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: '新規会員登録' }])
  }, [])

  return (
    <Container p={5} pb="65px" bg="bg.primary" mt={2} boxSizing="border-box">
      <Stack spacing={0}>
        <Heading fontSize="md" textAlign="center" as="h2" mb={5}>
          新規会員登録
        </Heading>
        <RegistrationForm />
        <SocialRegistrationForm />
        <Center>
          <Link as={RouterLink} to="/" my={2}>
            会員登録済みの方はこちら
          </Link>
        </Center>
      </Stack>
    </Container>
  )
}
