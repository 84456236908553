import { useEffect, useRef } from 'react'

import { useToast } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { csrfTokenAtom, csrfParamsAtom, alertMessageAtom } from '@!/atoms/appSettingsAtom'
import { SocialLogin } from '@!/User/Login/components'

export const SocialLoginForm = () => {
  const toast = useToast()
  const [csrfToken] = useAtom(csrfTokenAtom)
  const [csrfParams] = useAtom(csrfParamsAtom)
  const [alertMessage, setAlertMessage] = useAtom(alertMessageAtom)
  const formRef = useRef()

  const handleLogin = (provider) => {
    console.log(provider)
    if (!['apple', 'facebook', 'twitter'].includes(provider)) {
      return
    }

    if (formRef.current) {
      formRef.current.action = `/auth/${provider}`
      formRef.current.submit()
    }
  }

  useEffect(() => {
    if (alertMessage) {
      toast({
        title: 'Notification',
        description: alertMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {
          setAlertMessage(null)
        },
      })
    }
  }, [alertMessage])

  return (
    <>
      <form method="post" ref={formRef}>
        {csrfParams && csrfToken && <input type="hidden" name={csrfParams} value={csrfToken} />}
      </form>
      <SocialLogin handleLogin={handleLogin} />
    </>
  )
}
