import { useState } from 'react'
import PropTypes from 'prop-types'
import { useAtom } from 'jotai'

import { AddCartIcon } from '@!/components/Icon'
import { CircularProgressModal, ConfirmPopupModal } from '@!/components/Modal'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { cartItemsAtom } from '@!/atoms/cartItemsAtom'
import { saveCartItemsLoggedIn, saveCartItemsNotLoggedIn } from '@!/Util/LocalStorageData'
import { Config } from '@!/config/config'
import { useCreateCartItems } from '@!/Hooks'
import { SecondaryButton } from '@!/components/Button'
import { volumesApi } from '@!/Api/StoreBook'
import { checkPurchasedBooksApi } from '@!/Api/User'
import { sliceByNumber } from '@!/lib/Array'

export const AddAllVolumeButton = ({ bookTitleId, buttonText }) => {
  const [cartItems, setCartItems] = useAtom(cartItemsAtom)
  const [userHash] = useAtom(userHashAtom)
  const [showConfirmPopup, setShowConfirmPopup] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState('')
  const [redirectToCart, setRedirectToCart] = useState(false)
  const [createCartItemsMutation] = useCreateCartItems(userHash)
  const [isLoading, setIsLoading] = useState(false)

  const maximumNumberOfCarts = userHash ? Config.maximumNumberOfCartsLoggedIn : Config.maximumNumberOfCartsNotLoggedIn

  const getFilterdVolumes = (volumes, purchasedVolumeIds) => {
    // 購入済み作品を除外して全巻一覧を返す
    const volumesWithPurchaseStatus = volumes.map((volume) => ({
      ...volume,
      purchased: purchasedVolumeIds.some((purchasedBookId) => purchasedBookId == volume.distribution_id),
    }))
    return volumesWithPurchaseStatus.filter((volume) => !volume.purchased)
  }

  const buildNewCartItems = (filteredVolumes) => {
    // 既にカートに存在する作品と重複するものを除外
    const volumesToAdd = filteredVolumes.filter((volume) => !cartItems.some((cartItem) => cartItem.distribution_id === volume.distribution_id))

    return [...cartItems, ...volumesToAdd]
  }

  const execCartApi = (cartItems) => {
    const createCartItemsParams = cartItems.map((item) => {
      return {
        distribution_id: item.distribution_id,
        version: item.version,
      }
    })
    createCartItemsMutation.mutate(
      { books: createCartItemsParams },
      {
        onSuccess: () => {
          saveCartItems(cartItems)
        },
        onError: () => {
          setRedirectToCart(false)
          openConfirmPopup('カートへの追加に失敗しました。')
        },
      },
    )
  }

  const saveCartItemsToLocalStorage = (cartItems) => {
    if (userHash) {
      saveCartItemsLoggedIn(cartItems)
    } else {
      saveCartItemsNotLoggedIn(cartItems)
    }
  }

  const saveCartItems = (newCartItems) => {
    saveCartItemsToLocalStorage(newCartItems)
    setCartItems(newCartItems)
    setRedirectToCart(true)
    openConfirmPopup('カートに追加しました')
  }

  const openConfirmPopup = (message) => {
    setConfirmMessage(message)
    setShowConfirmPopup(true)
  }

  const fetchPurchasedVolumeIds = async (distributionIds) => {
    const splitedDistributionIds = sliceByNumber(distributionIds, 50)
    const purchasedIds = await Promise.all(splitedDistributionIds.map(async (ids) => await checkPurchasedBooksApi(ids)))
    return purchasedIds.flat()
  }

  const addAllVolumeToCart = async (event) => {
    event.preventDefault()
    setIsLoading(true)

    try {
      const volumes = await volumesApi(bookTitleId)

      if (!volumes.length) {
        openConfirmPopup('カートへの追加に失敗しました。')
        return
      }

      let filteredVolumes = volumes
      let purchasedVolumeIds = []

      if (userHash) {
        const distributionIds = volumes.map((volume) => volume.distribution_id)
        purchasedVolumeIds = await fetchPurchasedVolumeIds(distributionIds)
        filteredVolumes = getFilterdVolumes(volumes, purchasedVolumeIds)
      }

      const newCartItems = buildNewCartItems(filteredVolumes, purchasedVolumeIds)

      // 追加後のカート数と最大登録可能件数の比較
      if (newCartItems.length > maximumNumberOfCarts) {
        openConfirmPopup(`カートに追加できるのは最大${maximumNumberOfCarts}件です。`)
        return
      }

      if (userHash) {
        // ログイン時はAPI実行
        execCartApi(newCartItems)
        return
      }

      // 未ログイン時はLocal Storageとatomを更新
      saveCartItems(newCartItems)
    } catch (error) {
      console.log(error)
      openConfirmPopup('カートへの追加に失敗しました。')
      return
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <>
      <SecondaryButton width="100%" onClick={addAllVolumeToCart} rightIcon={<AddCartIcon width="24px" height="24px" ml={-1} />}>
        {buttonText}
      </SecondaryButton>
      <CircularProgressModal isOpen={createCartItemsMutation.isLoading || isLoading} />
      <ConfirmPopupModal
        text={confirmMessage}
        isOpen={showConfirmPopup}
        handlePopup={() => {
          setShowConfirmPopup(false)
          if (redirectToCart) {
            window.location.href = '/cart'
          }
        }}
      />
    </>
  )
}

AddAllVolumeButton.propTypes = {
  bookTitleId: PropTypes.number.isRequired,
  buttonText: PropTypes.string.isRequired,
}
