import { ColophonProvider } from '@!/providers'
import { Colophon } from '../containers/Colophon'

const ColophonApp = (props) => {
  return (
    <ColophonProvider>
      <Colophon {...props} />
    </ColophonProvider>
  )
}

export default ColophonApp
