import { useEffect, useState, useMemo } from 'react'
import { useMutation } from 'react-query'
import { PropTypes } from 'prop-types'
import { useAtom } from 'jotai'

import { userHashAtom } from '@!/atoms/userInfoAtom'
import { buildUrlString } from '@!/Util'
import { PrimaryButton } from '@!/components/Button'
import { CircularProgressModal, ConfirmPopupModal } from '@!/components/Modal'
import { getEndDate } from '@!/User/Bookshelf/util/Check'
import { storeBookColophonUrl } from '@!/Util/Location'
import { useReadBook } from '@!/Hooks'
import { bookDetailsApi } from '@!/Api/StoreBook'

export const ReadFreeBookButton = ({ volume }) => {
  const [userHash] = useAtom(userHashAtom)

  const [confirmModalOpen, setConfirmModalOpen] = useState(false)
  const bookDetailsMutation = useMutation({
    mutationFn: () => bookDetailsApi(volume.distribution_id),
    onError: (error) => {
      if (error && error.data) {
        setApiError(error.data)
      } else {
        setApiError('server error')
      }
    },
  })

  const endDate = useMemo(() => getEndDate(volume), [volume])
  const confirmPopupText = useMemo(() => `「${volume.title_name}」は${endDate}まで閲覧できます。`, [volume, endDate])

  const [readFreeBookMutation, redirectUrl, apiError, setApiError] = useReadBook('free')

  useEffect(() => {
    if (!redirectUrl) return

    window.location.href = redirectUrl
  }, [redirectUrl])

  const execReadBook = async () => {
    const colophonUrl = storeBookColophonUrl(volume.distribution_id, false)
    readFreeBookMutation.mutate({ bookId: volume.distribution_id, version: volume.version, colophonUrl: colophonUrl })
  }

  const handleReadFreeBook = (event) => {
    event.preventDefault()
    bookDetailsMutation.mutate()
  }

  useEffect(() => {
    if (!bookDetailsMutation.isSuccess) return

    if (!bookDetailsMutation.data || bookDetailsMutation.data.length === 0 || bookDetailsMutation.data[0].price !== 0) {
      // 価格が0円ではない場合の処理
      setApiError(true)
      return
    }

    // ログイン済みの場合は0円決済して本棚に登録させる
    if (userHash) {
      window.location.href = buildUrlString('/store/purchase', { book_id: volume.distribution_id })
      return
    }

    if (endDate) {
      setConfirmModalOpen(true)
    } else {
      execReadBook()
    }
  }, [bookDetailsMutation.isSuccess])

  const handleApiError = () => {
    console.log(apiError)
    setApiError(null)
  }

  return (
    <>
      <PrimaryButton width="100%" height="37px" onClick={(event) => handleReadFreeBook(event)}>
        無料で読む
      </PrimaryButton>
      <ConfirmPopupModal handlePopup={() => execReadBook()} text={confirmPopupText} isOpen={confirmModalOpen} />
      <ConfirmPopupModal text="エラーが発生しました。" handlePopup={() => handleApiError()} isOpen={!!apiError} />
      <CircularProgressModal isOpen={readFreeBookMutation.isLoading} />
    </>
  )
}

ReadFreeBookButton.propType = {
  volume: PropTypes.object.isRequired,
}
