export const goFirstPage = () => {
  window.parent.postMessage('first_page', '*')
}

export const searchNext = () => {
  window.parent.postMessage('next_search', '*')
}

export const closeViewer = () => {
  window.parent.postMessage('close_viewer', '*')
}
