import StoreBookDetail from '@!/Store/BookDetail/containers/StoreBookDetail'
import StoreApp from '@!/Store/Common/containers/StoreApp'
import { AppProvider } from '@!/providers'

const StoreBookDetailApp = (props) => {
  return (
    <AppProvider>
      <StoreApp {...props}>
        <StoreBookDetail book={props.bookDetail} currentTime={props.currentTime} />
      </StoreApp>
    </AppProvider>
  )
}

export default StoreBookDetailApp
