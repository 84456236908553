import { ErrorBoundary } from 'react-error-boundary'
import { ChakraProvider } from '@chakra-ui/react'
import { Provider } from 'jotai'
import { QueryClient, QueryClientProvider } from 'react-query'
import { HashRouter } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'

import { Fallback } from '@!/components/Error/Fallback'
import { theme } from '@!/config/theme'
import { JotaiDevToolsProvider } from '@!/providers'

export const BaseProvider = (props) => {
  const queryClient = new QueryClient()
  const toastOptions = {
    defaultOptions: { position: 'top' },
  }

  return (
    <>
      <ErrorBoundary FallbackComponent={Fallback}>
        <ChakraProvider theme={theme} toastOptions={toastOptions}>
          <Provider store={props.jotaiStore}>
            <JotaiDevToolsProvider />
            <QueryClientProvider client={queryClient}>
              <CookiesProvider defaultSetOptions={{ path: '/', sameSite: 'strict' }}>
                <HashRouter>{props.children}</HashRouter>
              </CookiesProvider>
            </QueryClientProvider>
          </Provider>
        </ChakraProvider>
      </ErrorBoundary>
    </>
  )
}
