import { AppProvider } from '@!/providers'
import { Account, AccountRegisterEmail, AccountUpdatePassword, AccountResign } from '@!/User/Account/containers'
import { Routes, Route } from 'react-router-dom'
import { useAppSettings } from '@!/Hooks/useAppSettings'

const AccountApp = (props) => {
  const jotaiStore = useAppSettings({ isSaveLastUrl: false, csrfToken: props.csrf_token, csrfParams: props.csrf_params, alertMessage: props.alert_message })

  return (
    <AppProvider jotaiStore={jotaiStore}>
      <Routes>
        <Route path="/" element={<Account />} />
        <Route path="/register_email" element={<AccountRegisterEmail />} />
        <Route path="/update_password" element={<AccountUpdatePassword />} />
        <Route path="/resign" element={<AccountResign />} />
        <Route path="/*" element={<Account />} />
      </Routes>
    </AppProvider>
  )
}

export default AccountApp
