import { atom } from 'jotai'

export const isSaveLastUrlAtom = atom(true)
isSaveLastUrlAtom.debugLabel = 'isSaveLastUrl'

export const csrfTokenAtom = atom(null)
csrfTokenAtom.debugLabel = 'csrfToken'

export const csrfParamsAtom = atom(null)
csrfParamsAtom.debugLabel = 'csrfParams'

export const alertMessageAtom = atom(null)
alertMessageAtom.debugLabel = 'alertMessage'

export const menuOpenAtom = atom(false)
menuOpenAtom.debugLabel = 'menuOpen'
