import { PropTypes } from 'prop-types'

import { Box } from '@chakra-ui/react'

/* 説明文などで使用する角丸のボックス */
export const RoundedBorderBox = ({ borderColor = '#DCDCE1', borderWidth = '1px', borderRadius = 'base', children, ...props }) => {
  return (
    <Box borderColor={borderColor} borderWidth={borderWidth} borderRadius={borderRadius} {...props}>
      {children}
    </Box>
  )
}

RoundedBorderBox.propTypes = {
  borderColor: PropTypes.string,
  borderWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderRadius: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
