import { StyleSheet, css } from 'aphrodite'

export const Error404 = () => {
  const styles = StyleSheet.create({
    error_404_main: {
      width: '75%',
      textAlign: 'center',
      margin: '0 auto',
      paddingTop: '10%',
    },
    error_404_image: {
      fontSize: '42px',
      color: '#f00',
    },
    error_404_main_text: {
      margin: 0,
      fontSize: '16px',
    },
    error_404_sub_text: {
      margin: '6px 0',
      fontSize: '12px',
    },
    error_404_links: {
      textAlign: 'center',
      verticalAlign: 'middle',
      fontSize: '14px',
      marginTop: '24px',
    },
    error_404_links_back_free: {
      margin: '12px 0',
    },
    error_404_links_color: {
      color: '#000',
    },
    error_404_goto_home_button: {
      textAlign: 'center',
      fontSize: '15px',
      color: '#ffffff',
      borderRadius: '4px',
      backgroundColor: '#e94841',
      padding: '8px 0',
      marginTop: '8px',
      cursor: 'pointer',
      textDecoration: 'none',
      width: '300px',
      display: 'block',
      margin: 'auto',
    },
  })

  return (
    <div>
      <div className={css(styles.error_404_main)}>
        <div className={css(styles.error_404_image)}>
          <i className="fa fa-question" />
        </div>
        <p className={css(styles.error_404_main_text)}>ぺージが見つかりません。</p>
        <p className={css(styles.error_404_sub_text)}>お探しのページは存在しないURL、または削除された可能性があります。</p>
      </div>
      <div className={css(styles.error_404_links)}>
        <a href="/" className={css(styles.error_404_goto_home_button)}>
          トップへ戻る
        </a>
      </div>
    </div>
  )
}
