import { Box, Heading } from '@chakra-ui/react'
import { encodeURIPath } from '@!/Util/String'
import { KanaTable } from '@!/Store/Kana/Tables/components'

const Tables = () => {
  const onClickBookTitleKana = (kana) => {
    location.href = `/store/search/book_title_kana?q=${encodeURIPath(kana)}`
  }
  const onClickAuthorKana = (kana) => {
    location.href = `/store/search/author_kana?q=${encodeURIPath(kana)}`
  }
  return (
    <Box mt={0.5} px={3}>
      <Box py={6}>
        <Heading as="h1" size="md">
          索引検索
        </Heading>
      </Box>
      <KanaTable subject="作品名検索" onClickKana={onClickBookTitleKana} />
      <KanaTable subject="作家名検索" onClickKana={onClickAuthorKana} />
    </Box>
  )
}

export default Tables
