import { useState, useEffect } from 'react'
import { useTabs, useNewBooks } from '@!/Hooks'
import { Box, Heading, Flex, Divider, Center } from '@chakra-ui/react'
import { TagItem } from '@!/Store/Home/components'
import { Paginate } from '@!/components/Paginate'
import { CircularProgressModal } from '@!/components/Modal'
import NewBooksList from '@!/Store/NewBooks/components/NewBooksList'

const NewBooks = ({ tabName }) => {
  const { currentTab, tabs, handleTabChange } = useTabs(tabName, '/store/new_books', true)
  const isActive = (index) => index === currentTab.index
  const [currentPage, setCurrentPage] = useState(1)
  // 1ページあたり63作品。横3×縦21列（小画面）、横7×縦9列（大画面）のレイアウトで表示。
  const perPage = 63

  const { data: newBooksData, isLoading } = useNewBooks(currentTab.name === 'home' ? '' : currentTab.name, currentPage, perPage)

  const handlePageClick = (page) => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  useEffect(() => {
    if (tabName == null) return
    const isValidGenre = tabs.some((tab) => tab.name === tabName)
    if (!isValidGenre) {
      window.location.href = '/store/new_books'
    }
  }, [tabName, tabs])

  return (
    <Box px={4} py={6}>
      <Heading as="h1" fontSize="md" mb={4}>
        新着コミックス一覧
      </Heading>
      <Flex flexWrap="wrap" gap={1}>
        {tabs.map((tab, index) => (
          <Box key={tab.name}>
            <TagItem
              name={tab.name === 'home' ? '全ジャンル' : tab.label}
              as="a"
              whiteSpace="nowrap"
              bg={isActive(index) && 'label.primary'}
              color={isActive(index) && 'label.white'}
              fontSize="13px"
              px={3}
              cursor="pointer"
              onClick={() => {
                handleTabChange(index)
              }}
            />
          </Box>
        ))}
      </Flex>
      <Divider my={4} />
      <NewBooksList newBooks={newBooksData?.dated_books || []} tabName={tabName} />
      <Center>
        <Paginate currentPage={currentPage} totalCount={newBooksData?.total_count || 0} perPage={perPage} onPageChange={handlePageClick} />
      </Center>
      <CircularProgressModal isOpen={isLoading} />
    </Box>
  )
}

export default NewBooks
