import PropTypes from 'prop-types'
import { Modal, ModalOverlay, CircularProgress, Center } from '@chakra-ui/react'

export const CircularProgressModal = ({ isOpen = true }) => (
  <Modal isOpen={isOpen}>
    <ModalOverlay>
      <Center h={'100%'}>
        <CircularProgress isIndeterminate size={50} thickness={5} />
      </Center>
    </ModalOverlay>
  </Modal>
)

CircularProgressModal.propTypes = {
  isOpen: PropTypes.bool,
}
