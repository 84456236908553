import axios from '@!/lib/axios'
import { buildUrlString } from '@!/Util'

export const bookDetailsApi = async (bookId) => {
  const url = buildUrlString(`/api/v1/store_books.json`, { book_id: bookId })
  return await axios.get(url)
}

export const volumesApi = async (bookTitleId) => {
  const url = buildUrlString(`/api/v1/store_books/volumes.json`, { title_id: bookTitleId })
  return await axios.get(url)
}

export const purchasedBookDownloadApi = async ({ bookId, colophonUrl = '', colophonSize = '' }) => {
  const api_path = '/api/v1/user/store/book_download.json'
  const url = buildUrlString(api_path, { book_id: bookId, colophon: colophonUrl, colophon_size: colophonSize })
  return await axios.get(url)
}

export const freeBookDownloadApi = async ({ bookId, version, colophonUrl = '', colophonSize = '' }) => {
  const api_path = '/api/v1/user/store/free_book_download.json'
  const url = buildUrlString(api_path, { book_id: bookId, version: version, colophon: colophonUrl, colophon_size: colophonSize })
  return await axios.get(url)
}

export const sampleBookDownloadApi = async ({ bookId, colophonUrl = '', colophonSize = '' }) => {
  const api_path = '/api/v1/user/store/sample_book_download.json'
  const url = buildUrlString(api_path, { book_id: bookId, colophon: colophonUrl, colophon_size: colophonSize })
  return await axios.get(url)
}
