import { Routes, Route } from 'react-router-dom'

import { AppProvider } from '@!/providers'
import Announcements from '@!/Announcements/containers/Announcements'
import AnnouncementDetail from '@!/Announcements/containers/AnnouncementDetail'
import { useAppSettings } from '@!/Hooks/useAppSettings'

const AnnouncementsApp = () => {
  const jotaiStore = useAppSettings({ isSaveLastUrl: false })

  return (
    <AppProvider jotaiStore={jotaiStore}>
      <Routes>
        <Route path="/" element={<Announcements />} />
        <Route path="/detail" element={<AnnouncementDetail />} />
        <Route path="/*" element={<Announcements />} />
      </Routes>
    </AppProvider>
  )
}

export default AnnouncementsApp
