import PropTypes from 'prop-types'
import { Button, useStyleConfig } from '@chakra-ui/react'

export const NormalButton = ({ children, isDisabled, ...props }) => {
  const styles = useStyleConfig('Button')
  const disabledStyles = {
    ...styles._disabled,
    bg: 'bg.tertiary',
    _hover: {
      bg: 'bg.tertiary',
    },
  }

  return (
    <Button variant="outline" isDisabled={isDisabled} _disabled={disabledStyles} p={0} {...props}>
      {children}
    </Button>
  )
}

NormalButton.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  p: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
}

NormalButton.defaultProps = {
  color: '#222',
  p: '0px',
  width: '100%',
  height: '44px',
  fontSize: '14px',
}
