import React from 'react'

export const formatStringWithNewlines = (str) => {
  return (
    <>
      {str.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {index < str.split('\n').length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  )
}
