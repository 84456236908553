import PropTypes from 'prop-types'
import { VStack, Divider } from '@chakra-ui/react'
import { PaymentCoinListRow } from '@!/User/Payment/components'

export const PaymentCoinList = (props) => {
  const handleClickCoin = (index) => {
    props.handleClickCoin(index)
  }

  return (
    <VStack py="8px" borderY="1px" borderColor="divider.light" divider={<Divider spacing={0} />}>
      {props.paymentList.map((value, index) => (
        <PaymentCoinListRow
          key={`payment-coin-list-row-${index}`}
          coin={value[0]}
          price={value[1]}
          attention={value[2]}
          index={index}
          handleClickCoin={handleClickCoin}
        />
      ))}
    </VStack>
  )
}

PaymentCoinList.propTypes = {
  paymentList: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])).isRequired),
  handleClickCoin: PropTypes.func.isRequired,
}
