import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/react'
import queryString from 'query-string'

import { LineIcon } from '@!/components/Icon'

export const ShareLineButton = ({ title }) => {
  const pseudoStyle = { bg: 'social.line', color: 'white' }
  const locationHrefForLine = () => {
    const parsedLocationHref = queryString.parseUrl(window.location.href)
    // openExternalBrowserを付けるとLINE内のWeb Viewではなくブラウザで開いてくれる。
    // https://developers.line.biz/ja/docs/line-login/using-line-url-scheme/#opening-url-in-external-browser
    return queryString.stringifyUrl({ url: parsedLocationHref.url, query: { ...parsedLocationHref.query, openExternalBrowser: 1 } })
  }

  return (
    <Button
      size="xs"
      as="a"
      // 「テキストメッセージを送る(https://line.me/R/share?text=)」はPC版に対応していないため使用せず
      // https://developers.line.biz/ja/docs/line-login/using-line-url-scheme/#sending-text-messages
      // 「LINEで送る(https://social-plugins.line.me/lineit/share?url=)」ボタンのカスタムアイコン用URLを使用する。
      // https://developers.line.biz/ja/docs/line-social-plugins/install-guide/using-line-share-buttons/#using-custom-icons
      // 「LINEで送る」のtextパラメータは非公式でPC版のみ対応している。
      href={queryString.stringifyUrl({ url: 'https://social-plugins.line.me/lineit/share', query: { url: locationHrefForLine(), text: title } })}
      target="_blank"
      rel="noopener noreferrer"
      leftIcon={<LineIcon width="2.0em" height="2.0em" mr={-2} />}
      bg="social.line"
      color="white"
      _hover={pseudoStyle}
      _active={pseudoStyle}
    >
      LINEで送る
    </Button>
  )
}

ShareLineButton.propTypes = {
  title: PropTypes.string.isRequired,
}
