import { AppProvider } from '@!/providers'
import NewBooks from '@!/Store/NewBooks/containers/NewBooks'

const NewBooksApp = (props) => {
  return (
    <AppProvider>
      <NewBooks tabName={props.tab_name} />
    </AppProvider>
  )
}

export default NewBooksApp
