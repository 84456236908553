import { PropTypes } from 'prop-types'

import { IconButton } from '@chakra-ui/react'

import { MenuIcon } from '@!/components/Icon'

export const HamburgerMenu = ({ handleOnClick }) => {
  return (
    <IconButton p={1} aria-label="メニュー" color="label.primary" fontSize="36px" icon={<MenuIcon />} variant="link" onClick={handleOnClick} border="none" />
  )
}

HamburgerMenu.propType = {
  handleOnClick: PropTypes.func.isRequired,
}
