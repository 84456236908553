import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/react'

export const SecondaryButton = ({ children, isDisabled, ...props }) => {
  return (
    <Button colorScheme="secondary" isDisabled={isDisabled} {...props}>
      {children}
    </Button>
  )
}

SecondaryButton.propTypes = {
  children: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  p: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  variant: PropTypes.string,
}

SecondaryButton.defaultProps = {
  color: '#222',
  p: '0px',
  width: '100%',
  height: '44px',
  fontSize: '14px',
  variant: 'solid',
}
