import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { Box } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'

import { CircularProgressModal } from '@!/components/Modal'
import { CurrentCoinInfo } from '@!/User/common/components'
import { PaymentCoinList, PaymentMainFooter } from '@!/User/Payment/components'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { userCoinsAtom, userCoinsIsLoadingAtom } from '@!/atoms/userInfoAtom'
import { paymentListAtom, newCardInfoAtom, newCardTokenAtom, listIndexAtom, initPaymentAtom } from '@!/atoms/paymentAtom'

const PaymentMain = () => {
  const navigate = useNavigate()
  const [userCoins] = useAtom(userCoinsAtom)
  const [userCoinsIsLoading] = useAtom(userCoinsIsLoadingAtom)
  const [paymentList] = useAtom(paymentListAtom)
  const [, setNewCardInfo] = useAtom(newCardInfoAtom)
  const [, setNewCardToken] = useAtom(newCardTokenAtom)
  const [, initPayment] = useAtom(initPaymentAtom)
  const [listIndex, setListIndex] = useAtom(listIndexAtom)
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    initPayment()
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'コイン購入' }])
  }, [])

  useEffect(() => {
    if (listIndex < 0) return

    navigate('/select')
  }, [listIndex])

  const handleClickCoin = (index) => {
    if (index >= 0 && index < paymentList.length) {
      setNewCardToken(null)
      setNewCardInfo({})
      setListIndex(index)
    }
  }

  return (
    <Box pt="30px">
      {userCoinsIsLoading ? (
        <CircularProgressModal isOpen={userCoinsIsLoading} />
      ) : (
        <>
          <CurrentCoinInfo coinCounts={userCoins} />
          <PaymentCoinList paymentList={paymentList} handleClickCoin={(index) => handleClickCoin(index)} />
          <PaymentMainFooter />
        </>
      )}
    </Box>
  )
}

export default PaymentMain
