import { PropTypes } from 'prop-types'

import { Heading, HStack, Link } from '@chakra-ui/react'
import { RightArrowIcon } from '@!/components/Icon'

export const SectionTitle = ({ title, url, tabName }) => {
  return (
    <HStack spacing={0} mb="1.2em">
      <Heading fontSize="lg" as="h2" noOfLines={1} flex="1">
        {title}
      </Heading>
      {url && (
        <Link
          as="a"
          href={url}
          fontSize="sm"
          fontWeight="300"
          color="label.primary"
          textDecoration="none"
          {...(tabName && { 'data-gtm-click': `${tabName}_${title}_もっと見る` })}
        >
          もっと見る
          <RightArrowIcon ml={0.5} mb={0.5} />
        </Link>
      )}
    </HStack>
  )
}

SectionTitle.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
  tabName: PropTypes.string,
}
