import { IconButton } from '@chakra-ui/react'

import { BookshelfIcon } from '@!/components/Icon'

export const Bookshelf = () => {
  return (
    <IconButton
      ml={1}
      aria-label="本棚"
      color="label.primary"
      fontSize="36px"
      icon={<BookshelfIcon w="24px" h="28px" stroke={'label.primary'} />}
      variant="link"
      as="a"
      href="/user/bookshelf"
    />
  )
}
