import PropTypes from 'prop-types'
import { Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, Image } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util'

import HelpAccordionPlusSvg from '@images/help_accordion_plus.svg'
import HelpAccordionMinusSvg from '@images/help_accordion_minus.svg'

export const HelpAccordion = ({ category, qAndAList }) => (
  <Box w="100%" mb={8}>
    <Box ml={4}>
      <Box pb={3} fontWeight="600" fontSize="md" lineHeight={6}>
        {category}
      </Box>
    </Box>
    <Accordion allowMultiple>
      {qAndAList.map((qAndA, index) => (
        <AccordionItem key={index}>
          {({ isExpanded }) => (
            <>
              <AccordionButton px={8} py={4} bg="bg.primary" _expanded={{ bg: 'bg.primary' }}>
                <Box mr={4} flex="1" textAlign="left" fontSize="sm" fontWeight="300">
                  {qAndA.question}
                </Box>
                <Box>
                  <Image src={buildAssetUrlString(isExpanded ? HelpAccordionMinusSvg : HelpAccordionPlusSvg)} />
                </Box>
              </AccordionButton>
              <AccordionPanel px={8} py={4} bg="bg.secondary" fontSize="sm" fontWeight="300">
                {qAndA.answer}
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      ))}
    </Accordion>
  </Box>
)

HelpAccordion.propTypes = {
  category: PropTypes.string.isRequired,
  qAndAList: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string.isRequired,
      answer: PropTypes.string.isRequired,
    }),
  ).isRequired,
}
