import axios from 'axios'

axios.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    if (error.response) {
      const response = error.response
      if (response.status === 401) {
        // 401ページに遷移して強制ログアウト処理を行う
        if (window.location.pathname !== '/unauthorized') {
          window.location.href = '/unauthorized'
        }
      }
      throw error.response
    } else {
      throw new Error('問題が発生しました。')
    }
  },
)

export default axios
