import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Box, Image, Link } from '@chakra-ui/react'
import InlineBanner_1 from '@images/inline_banner_1.webp'
import InlineBanner_2 from '@images/inline_banner_2.webp'
import InlineBanner_3 from '@images/inline_banner_3.webp'
import { ScrollableFeatureList } from '@!/Store/Common/components'
import { buildAssetUrlString } from '@!/Util'

const FeatureListWithBanners = ({ features, tabName }) => {
  const inlineBanners = [InlineBanner_1, InlineBanner_2, InlineBanner_3]
  // 特集の一番上と特集5つごとにインラインバナーを表示
  // ジャンルタブ選択時は、特集3つ目と、以降は特集5つごとにインラインバナーを表示
  const bannerIndexes = useMemo(() => {
    return tabName === 'home' ? [0, 5, 10] : [3, 8, 13]
  }, [tabName])

  const getNextBanner = (index) => {
    if (index < inlineBanners.length) {
      const banner = inlineBanners[index]
      return banner
    }
    return null
  }

  const items = useMemo(() => {
    const featureItems = []
    let bannerIndex = 0

    features.forEach((feature, index) => {
      if (bannerIndexes.includes(index)) {
        const banner = getNextBanner(bannerIndex)
        featureItems.push(
          <Box my={6} mx={4} key={`banner-${bannerIndex}`}>
            <Link href={'/event'} data-gtm-click={`${tabName}バナー${bannerIndex + 1}`}>
              <Image src={buildAssetUrlString(banner)} alt="全作品対象40%ポイント還元" w="100%" />
            </Link>
          </Box>,
        )
        bannerIndex++
      }

      featureItems.push(
        <ScrollableFeatureList key={feature.pathname} title={feature.title} pathname={feature.pathname} books={feature.book_titles} tabName={tabName} />,
      )
    })

    return featureItems
  }, [features])

  return <Box>{items}</Box>
}

FeatureListWithBanners.propTypes = {
  features: PropTypes.array.isRequired,
  tabName: PropTypes.string.isRequired,
}

export default FeatureListWithBanners
