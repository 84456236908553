import { IconButton, Image } from '@chakra-ui/react'

import CouponSvg from '@images/coupon.svg'
import { buildAssetUrlString } from '@!/Util'

export const Coupon = () => {
  return (
    <IconButton aria-label="クーポン" color="label.primary" icon={<Image src={buildAssetUrlString(CouponSvg)} />} variant="link" as="a" href="/user/coupons" />
  )
}
