import { Box, VStack } from '@chakra-ui/react'

import { AppInstall } from '@!/components/AppInstall'
import { BreadcrumbList } from '@!/components/Breadcrumb'
import { AbjBox, Copyright, FooterLinks, FooterMessage, FooterMenus } from './'

import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'

export const NewFooter = () => {
  const [breadcrumb] = useAtom(breadcrumbAtom)

  return (
    <>
      <BreadcrumbList links={breadcrumb} />
      <FooterMenus />
      <AppInstall os="" device="" showBorder={false} hiddenBottomText={true} />
      <Box bg="#222" p="4">
        <VStack spacing={0}>
          <FooterLinks />
          <AbjBox />
          <FooterMessage />
          <Copyright />
        </VStack>
      </Box>
    </>
  )
}
