import { useQuery } from 'react-query'
import { couponsApi } from '@!/Api/User'
import { userCouponKeys } from '@!/Api/queryKeys'

export const useCoupons = (userHash) => {
  return useQuery(userCouponKeys.coupons(userHash), async () => {
    if (userHash) {
      try {
        return await couponsApi()
      } catch (error) {
        console.log(error)
        return []
      }
    }

    return []
  })
}
