import PropTypes from 'prop-types'
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Stack, Button } from '@chakra-ui/react'

export const YesNoPopupModal = ({ title, submitTitle = 'Yes', cancelTitle = 'No', handleSubmit, handleCancel, isOpen = true }) => (
  <Modal isCentered isOpen={isOpen} onClose={handleCancel}>
    <ModalOverlay />
    <ModalContent>
      <ModalBody>{title}</ModalBody>
      <ModalFooter>
        <Stack direction="row" spacing={0}>
          <Button onClick={handleCancel}>{cancelTitle}</Button>
          <Button onClick={handleSubmit} ml={4}>
            {submitTitle}
          </Button>
        </Stack>
      </ModalFooter>
    </ModalContent>
  </Modal>
)

YesNoPopupModal.propTypes = {
  title: PropTypes.string.isRequired,
  submitTitle: PropTypes.string,
  cancelTitle: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func,
  isOpen: PropTypes.bool,
}
