import PropTypes from 'prop-types'
import { Box, Text, Link, Center, Heading, Divider } from '@chakra-ui/react'

import { buildUrlString } from '@!/Util'
import { ShareButtons } from '@!/Store/Common/components/ShareButtons'

export const BookExpandedInfo = ({ book }) => {
  return (
    <Box w="100%">
      <Divider my="3" />
      <Center>
        <Heading as="h2" fontSize="md" fontWeight="semibold">
          詳細情報
        </Heading>
      </Center>
      {book.title_name && (
        <Box mt={2}>
          <Text fontSize="xs">タイトル：{book.title_name}</Text>
        </Box>
      )}
      {book.author_name && (
        <Box mt={2}>
          <Text fontSize="xs">
            著者名：
            {book.author_name
              .split(',')
              .map((author, index) => (
                <Link key={index} textDecoration="none" href={`${buildUrlString(`/store/search/author/${author}`)}`}>
                  {author}
                </Link>
              ))
              .reduce((acc, curr) => [acc, ',', curr])}
          </Text>
        </Box>
      )}
      {book.publisher_name && (
        <Box mt={2}>
          <Text fontSize="xs">
            出版社：
            <Link textDecoration="none" href={`${buildUrlString(`/store/search/publisher/${book.publisher_name}`)}`}>
              {book.publisher_name}
            </Link>
          </Text>
        </Box>
      )}
      {book.magazine_name && (
        <Box mt={2}>
          <Text fontSize="xs">
            連載雑誌：
            <Link textDecoration="none" href={`${buildUrlString(`/store/search/magazine/${book.magazine_name}`)}`}>
              {book.magazine_name}
            </Link>
          </Text>
        </Box>
      )}
      {book.genre_name && (
        <Box mt={2}>
          <Text fontSize="xs">
            ジャンル：
            <Link textDecoration="none" href={`${buildUrlString(`/store/search/category/${book.genre_name}`)}`}>
              {book.genre_name}
            </Link>
          </Text>
        </Box>
      )}
      <Center mt={3} mb={1}>
        <ShareButtons title={`${book.title_name}(${book.volume})`} />
      </Center>
      <Divider mt="3" />
    </Box>
  )
}

BookExpandedInfo.propTypes = {
  book: PropTypes.shape({
    author_name: PropTypes.string,
    magazine_name: PropTypes.string,
    publisher_name: PropTypes.string,
    genre_name: PropTypes.string,
  }),
}
