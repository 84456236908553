import { useAtom } from 'jotai'
import { useQuery } from 'react-query'

import { userHashAtom } from '@!/atoms/userInfoAtom'
import { getPurchasedBookTitleApi } from '@!/Api/StoreBookPurchase'
import { purchaseHistoriesKeys } from '@!/Api/queryKeys'

export const usePurchasedBookTitle = (bookTitleId) => {
  const [userHash] = useAtom(userHashAtom)
  return useQuery(purchaseHistoriesKeys.purchasedBookTitle(userHash, bookTitleId), async () => await getPurchasedBookTitleApi(bookTitleId), {
    enabled: !!userHash && !!bookTitleId,
  })
}
