import { PropTypes } from 'prop-types'

import { HStack, Image, Text } from '@chakra-ui/react'

import { buildAssetUrlString } from '@!/Util'

import StoreCoinSvg from '@images/store_coin.svg'

export const CoinLabel = (props) => {
  const { coinCount } = props

  return (
    <HStack padding={'0 8px'}>
      <Image htmlWidth={22} htmlHeight={22} src={buildAssetUrlString(StoreCoinSvg)} />
      <Text fontSize={'1.5em'} fontWeight={600}>
        {coinCount.toLocaleString()}
      </Text>
    </HStack>
  )
}

CoinLabel.propType = {
  coinCount: PropTypes.number.isRequired,
}
