import PropTypes from 'prop-types'
import { HStack, Box } from '@chakra-ui/react'

import { ShareFacebookButton, ShareXButton, ShareLineButton } from '@!/Store/Common/components/ShareButtons'

export const ShareButtons = ({ title }) => {
  return (
    <HStack spacing={0}>
      <Box mx={1}>
        <ShareFacebookButton />
      </Box>
      <Box mx={1}>
        <ShareXButton title={title} />
      </Box>
      <Box mx={1}>
        <ShareLineButton title={title} />
      </Box>
    </HStack>
  )
}

ShareButtons.propTypes = {
  title: PropTypes.string.isRequired,
}
