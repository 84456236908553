import StoreFeatureList from '../containers/StoreFeatureList'
import StoreApp from '../../Common/containers/StoreApp'
import { AppProvider } from '@!/providers'

const StoreFeatureListApp = (props) => {
  return (
    <AppProvider>
      <StoreApp {...props}>
        <StoreFeatureList items={props.items} currentTime={props.currentTime} />
      </StoreApp>
    </AppProvider>
  )
}

export default StoreFeatureListApp
