import { calculatePoint } from './calculatePoint'

describe('calculatePoint', () => {
  const pointBackRate = 50

  describe('1作品購入', () => {
    describe('ポイント還元対象の作品の場合', () => {
      const items = [{ price: 500, exclude_from_point_back: false }]

      test('ポイントは税抜価格にポイントバック比率を掛けた値', () => {
        const point = calculatePoint(items, pointBackRate, 0)
        expect(point).toEqual(227)
      })

      describe('ポイントを使用する場合', () => {
        const usedPoint = 100

        test('決済金額から使用ポイントを引いた金額でポイントを計算する', () => {
          const point = calculatePoint(items, pointBackRate, usedPoint)

          // (500-100)/1.1=363.6363...（小数点以下切り捨て）
          expect(point).toEqual(181)
        })
      })
    })

    describe('ポイント還元対象外の作品の場合', () => {
      const items = [{ price: 500, exclude_from_point_back: true }]

      test('ポイントは0', () => {
        const point = calculatePoint(items, pointBackRate, 0)
        expect(point).toEqual(0)
      })
    })
  })

  describe('複数作品購入', () => {
    describe('ポイント還元対象外の作品を含まない場合', () => {
      const items = [
        { price: 500, exclude_from_point_back: false },
        { price: 600, exclude_from_point_back: false },
        { price: 700, exclude_from_point_back: false },
        { price: 800, exclude_from_point_back: false },
        { price: 900, exclude_from_point_back: false },
      ]

      test('ポイントは総額の税抜価格にポイントバック比率を掛けた値', () => {
        const point = calculatePoint(items, pointBackRate, 0)

        // (500 + 600 + 700 + 800 + 900)/1.1 = 3181.8181...（小数点以下切り捨て）
        // 3181*0.5=1590.5（小数点以下切り捨て）
        expect(point).toEqual(1590)
      })

      describe('ポイントを使用する場合', () => {
        const usedPoint = 1000

        test('決済金額から使用ポイントを引いた金額でポイントを計算する', () => {
          const point = calculatePoint(items, pointBackRate, usedPoint)

          // (3500-1000)/1.1=2,272.7272...（小数点以下切り捨て）
          expect(point).toEqual(1136)
        })
      })
    })
    describe('ポイント還元対象外の作品を含む場合', () => {
      const items = [
        { price: 500, exclude_from_point_back: true },
        { price: 600, exclude_from_point_back: true },
        { price: 700, exclude_from_point_back: false },
        { price: 800, exclude_from_point_back: false },
        { price: 900, exclude_from_point_back: false },
      ]

      test('ポイントはポイント対象作品のみで計算した値', () => {
        const point = calculatePoint(items, pointBackRate, 0)

        // (700 + 800 + 900)/1.1 = 2181.8181...（小数点以下切り捨て）
        // 2181*0.5=1090.5（小数点以下切り捨て）
        expect(point).toEqual(1090)
      })

      describe('ポイントを使用する場合', () => {
        describe('使用ポイントがポイント還元対象外作品の決済額より少ない場合', () => {
          const usedPoint = 1000

          test('ポイントはポイント未使用時と同じ値', () => {
            const point = calculatePoint(items, pointBackRate, usedPoint)
            expect(point).toEqual(1090)
          })
        })
        describe('使用ポイントがポイント還元対象外作品の決済額より多い場合', () => {
          const usedPoint = 1200

          test('差分を使用ポイントとして引いた金額でポイントを計算する', () => {
            const point = calculatePoint(items, pointBackRate, usedPoint)
            expect(point).toEqual(1045)
          })
        })
      })
    })
  })
})
