import { StyleSheet } from 'aphrodite'

import StoreCoinSvg from '@images/store_coin.svg'

export const styles = StyleSheet.create({
  payment_main_container: {
    color: '#484848',
    margin: '20px auto',
    padding: '0',
    maxWidth: '800px',
  },
  payment_main_coin_current: {
    textAlign: 'center',
    fontSize: '20px',
    fontWeight: 'bold',
    padding: '12px 12px 6px 12px',
    verticalAlign: 'middle',
    ':before': {
      display: 'inline-block',
      background: `url(${window.asset_host}${StoreCoinSvg}) no-repeat center center`,
      backgroundSize: 'contain',
      width: '24px',
      height: '24px',
      content: '""',
      marginRight: '6px',
      marginBottom: '4px',
    },
  },
  payment_main_coin_unuseable: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 'normal',
    padding: '0 12px 0 12px',
    verticalAlign: 'middle',
    color: '#b3b3b3',
  },
  payment_main_attention: {
    margin: 0,
    fontSize: '12px',
    padding: '6px 12px 6px 12px',
    color: '#F00',
  },
  payment_main_separator: {
    height: '6px',
    width: '100%',
    background: '#c3c4c5',
  },
  payment_main_coins: {
    width: '100%',
    borderBottom: '1px solid #c3c4c5',
  },
  payment_main_coins_data: {
    fontSize: '15px',
  },
  payment_main_coins_coin: {
    width: '33%',
    textAlign: 'left',
    padding: '12px',
    color: '#484848',
    ':before': {
      display: 'inline-block',
      background: `url(${window.asset_host}${StoreCoinSvg}) no-repeat center center`,
      backgroundSize: 'contain',
      width: '18px',
      height: '18px',
      content: '""',
      marginRight: '4px',
      marginBottom: '4px',
    },
  },
  payment_main_coins_attention_box: {
    color: '#484848',
  },
  payment_main_coins_attention: {
    width: '32%',
    textAlign: 'left',
    fontSize: '12px',
    color: '#FF3D00',
  },
  payment_main_bonus_coin: {
    margin: 0,
    textAlign: 'center',
    background: '#32cd32',
    padding: '8px',
    color: '#fff',
  },
  payment_main_bonus_sp_medal: {
    color: '#FF3D00',
    border: '1px solid #f00',
    padding: '2px',
  },
  payment_main_coins_price: {
    width: '35%',
    textAlign: 'right',
    padding: '12px',
  },
  payment_main_coins_price_button: {
    color: '#FFFFFF',
    padding: '6px 12px',
    background: '#d50606',
    borderRadius: '4px',
  },
  payment_main_footer: {
    paddingBottom: '24px',
  },
  payment_main_footer_text: {
    padding: '6px 12px',
    margin: 0,
    fontSize: '12px',
    color: '#484848',
  },
  payment_main_footer_link: {
    textDecoration: 'none',
  },
  payment_main_footer_button_box: {
    margin: '6px 12px',
  },
  payment_main_footer_button: {
    padding: '6px 12px',
    marginRight: '12px',
    fontSize: '12px',
    background: '#484848',
    color: '#fff',
    borderRadius: '4px',
  },
  credit_card_main: {
    padding: '16px 0 20px',
    margin: '20px auto',
    maxWidth: '700px',
  },
  credit_card_info: {
    padding: '12px 24px',
  },
  credit_card_form_group: {
    width: '100%',
    marginBottom: '12px',
    color: '#484848',
  },
  credit_card_label: {
    width: '100%',
    display: 'block',
  },
  credit_card_label_attention: {
    margin: 0,
    color: '#f00',
    fontSize: '12px',
  },
  credit_card_coin: {
    textAlign: 'right',
    width: '50%',
    display: 'inline-block',
    margin: 0,
    ':before': {
      display: 'inline-block',
      background: `url(${window.asset_host}${StoreCoinSvg}) no-repeat center center`,
      backgroundSize: 'contain',
      width: '18px',
      height: '18px',
      content: '""',
      marginRight: '4px',
      marginBottom: '4px',
      verticalAlign: 'middle',
    },
  },
  credit_card_price: {
    textAlign: 'right',
    width: '50%',
    margin: 0,
    display: 'inline-block',
  },
  credit_card_security_code_label: {
    marginBottom: '12px',
  },
  credit_card_security_code_question: {
    color: '#ccc',
    width: '25px',
    height: '30px',
    lineHeight: '30px',
    fontSize: '18px',
    top: '2px',
    right: '2px',
    textAlign: 'center',
    ':before': {
      content: '"\\f059"',
    },
  },
  credit_card_amount: {
    lineHeight: '40px',
    height: 'auto',
    padding: '0',
    display: 'block',
    width: '100%',
    fontSize: '18px',
    fontWeight: 'bold',
    backgroundColor: '#fff',
  },
  credit_card_form: {
    lineHeight: '40px',
    height: '40px',
    padding: '0 16px',
    display: 'block',
    width: '100%',
    fontSize: '14px',
    border: '1px solid #ccc',
    borderRadius: '4px',
    boxSizing: 'border-box',
  },
  credit_card_security_code_form: {
    width: '50%',
    lineHeight: '40px',
    height: '40px',
    padding: '0 16px',
    fontSize: '14px',
    display: 'block',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  credit_card_expire_select: {
    height: '34px',
    width: '72px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  credit_card_submit: {
    width: '100%',
    marginTop: '24px',
    fontSize: '24px',
    padding: '6px',
    backgroundColor: '#2ec4a5',
    color: 'white',
    borderRadius: '4px',
  },
  credit_card_back: {
    marginTop: '24px',
    textAlign: 'left',
  },
  conform_card_info: {
    padding: 0,
  },
  confirm_credit_card: {
    width: '100%',
    marginTop: '4px',
    padding: '0 12px',
    boxSizing: 'border-box',
  },
  confirm_credit_card_label: {
    fontSize: '13px',
    width: '50%',
    display: 'inline-block',
  },
  confirm_credit_card_data: {
    margin: '0',
    fontSize: '12px',
    width: '50%',
    display: 'inline-block',
    textAlign: 'right',
  },
  confirm_form_group: {
    width: '100%',
    marginBottom: '12px',
    borderBottom: '1px solid #d6d6d6',
    color: '#484848',
    padding: '12px',
    boxSizing: 'border-box',
  },
  confirm_form_buttons: {
    width: '100%',
    padding: '24px',
    color: '#484848',
    boxSizing: 'border-box',
  },
  confirm_before_block: {
    margin: '12px 0 0 0',
    borderBottom: '1px solid #d6d6d6',
    padding: '12px 6px 4px 12px',
  },
  confirm_before_title: {
    display: 'inline-block',
    margin: 0,
    fontWeight: 'bold',
    color: '#484848',
    width: '55%',
  },
  confirm_card_other_link: {
    display: 'inline-block',
    color: '#484848',
    width: '45%',
    textAlign: 'right',
    fontSize: '12px',
    textDecoration: 'none',
  },
  confirm_card_other_text: {
    padding: '6px 8px',
    color: '#fff',
    background: '#ff5252',
    borderRadius: '4px',
  },
  confirm_back: {
    marginTop: '24px',
    textAlign: 'left',
    paddingLeft: '12px',
  },
  confirm_back_link: {
    color: '#484848',
  },
  confirm_card_label: {
    width: '50%',
    display: 'inline-block',
    fontWeight: 'bold',
  },
  select_group_header: {
    width: '100%',
    marginTop: '12px',
    color: '#484848',
    fontWeight: 'bold',
    background: '#EEE',
    padding: '12px 24px',
    boxSizing: 'border-box',
  },
  select_form_group: {
    width: '100%',
    marginBottom: '12px',
    color: '#484848',
    padding: '12px',
    borderBottom: '1px solid #d6d6d6',
    boxSizing: 'border-box',
  },
  select_group: {
    padding: '6px 12px',
    margin: '18px 12px 12px 12px',
    borderRadius: '12px',
    border: '1px solid #d6d6d6',
    boxShadow: '0 1px 6px rgba(36, 33, 33, 0.28)',
  },
  select_logo_image: {
    width: '94%',
  },
  paypay_logo_image: {
    width: '40%',
  },
  select_check: {
    width: '6%',
  },
  select_back: {
    margin: '24px 12px',
    textAlign: 'left',
  },
})
