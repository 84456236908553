import { StyleSheet, css } from 'aphrodite'

export default function bottomText({ inner }) {
  const styles = StyleSheet.create({
    free_complete_bottom_text_container: {
      fontSize: '12px',
      padding: '30px 56px',
      color: '#4a4a4a',
      background: '#f4f4f4',
    },
  })
  return <div className={css(styles.free_complete_bottom_text_container)}>{inner}</div>
}
