import { PropTypes } from 'prop-types'

import { Tag } from '@chakra-ui/react'

export const TagSearchItem = ({ tag, url }) => {
  return (
    <Tag variant="outline" color="label.primary" borderColor="gray.400" borderRadius="full" fontSize="sm" px={3} py={2} as="a" href={url}>
      {tag}
    </Tag>
  )
}

TagSearchItem.propTypes = {
  tag: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}
