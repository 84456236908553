import { Text } from '@chakra-ui/react'
import { ContentContainer } from '@!/components/Container'

export const FooterMessage = () => {
  return (
    <ContentContainer my={2}>
      <Text color="white" fontSize="xs">
        マンガBANGブックスは、人気漫画がお得に読める電子書籍サイトです。40{'％'}
        のポイント還元は利用上限がなく、いつでもお得にマンガを読む事ができます。品揃えも豊富で、無料や値引きも充実しているので、ぜひブックマークしてください。購入した作品は、姉妹サービスのスマホアプリ「マンガBANG!」でも読む事が出来ます。
      </Text>
    </ContentContainer>
  )
}
