import { PropTypes } from 'prop-types'

import { LinkBox, LinkOverlay } from '@chakra-ui/react'

export const MenuLink = (props) => {
  const { text, url, ...rest } = props

  return (
    <LinkBox p={rest.p} textAlign={rest.textAlign} width={'100%'}>
      <LinkOverlay fontSize={rest.fontSize} fontWeight={600} href={url}>
        {text}
      </LinkOverlay>
    </LinkBox>
  )
}

MenuLink.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}
