/* global Multipayment, gmoCallBack, gmoResponse */
import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { useNavigate } from 'react-router-dom'
import { css } from 'aphrodite'

import { styles } from '../styles/PaymentStyle'
import CreditCardInput from '../components/CreditCardInput'
import { tokenApiUrlAtom, shopIdAtom, newCardInfoAtom, newCardTokenAtom } from '@!/atoms/paymentAtom'
import { useValidListItemCheck } from '@!/User/Payment/Hooks/useValidListItemCheck'

export const PaymentNewCard = () => {
  const navigate = useNavigate()
  const [tokenApiUrl] = useAtom(tokenApiUrlAtom)
  const [shopId] = useAtom(shopIdAtom)
  const [newCardInfo, setNewCardInfo] = useAtom(newCardInfoAtom)
  const [, setNewCardToken] = useAtom(newCardTokenAtom)
  const [newCardError, setNewCardError] = useState({ message: null, code: null })

  useValidListItemCheck()

  useEffect(() => {
    addGmoPaymentTokenJs(false)

    const script = document.createElement('script')
    script.innerHTML = 'var gmoResponse = null;function gmoCallBack(res) {gmoResponse = res;}'
    document.body.appendChild(script)
  }, [])

  const addGmoPaymentTokenJs = (isReload) => {
    const d = document
    const link = d.createElement('script')
    link.src = tokenApiUrl
    if (isReload) {
      const now = new Date(),
        hour = now.getHours(),
        min = now.getMinutes(),
        sec = now.getSeconds(),
        temp = hour.toString() + min.toString() + sec.toString()
      link.src += '?v=' + temp
    }

    const h = d.getElementsByTagName('head')[0]
    h.appendChild(link)
  }

  const handleCardNumber = (number) => {
    setNewCardInfo(Object.assign({}, newCardInfo, { number: number }))
  }

  const handleCardName = (name) => {
    setNewCardInfo(Object.assign({}, newCardInfo, { name: name }))
  }

  const handleSecurityCode = (code) => {
    setNewCardInfo(Object.assign({}, newCardInfo, { securityCode: code }))
  }

  const handleExpireMonthYear = (month, year) => {
    const monthYear = {
      month: month != null ? month : newCardInfo.month,
      year: year != null ? year : newCardInfo.year,
    }
    setNewCardInfo(Object.assign({}, newCardInfo, monthYear))
  }

  const getCardErrorInfo = (code) => {
    switch (code) {
      case 100:
      case 101:
      case 102:
        // カード番号エラー
        return {
          message: 'カード番号が正しくありません。',
          code: 'number',
        }
      case 110:
      case 111:
      case 112:
      case 113:
        // 有効期限エラー
        return {
          message: '有効期限が正しくありません。',
          code: 'expire',
        }
      case 121:
      case 122:
        // セキュリティーコードエラー
        return {
          message: 'セキュリティーコードが正しくありません。',
          code: 'security',
        }
      case 131:
      case 132:
        // 名義人エラー
        return {
          message: '名義が正しくありません。',
          code: 'name',
        }
      default:
        // その他エラー
        return {
          message: 'エラーが発生しました。',
          code: 'etc',
        }
    }
  }

  const getTokenResult = (response) => {
    if (response.resultCode == '000') {
      setNewCardToken(response.tokenObject.token)
      navigate('/confirm')
    } else {
      setNewCardError(getCardErrorInfo(response.resultCode))
    }
  }

  const handleSubmit = () => {
    const inputError = { message: null, code: null }
    if (!newCardInfo.number) {
      inputError.message = 'カード番号を入力してください。'
      inputError.code = 'number'
    } else if (!newCardInfo.name) {
      inputError.message = 'カード名義を入力してください。'
      inputError.code = 'name'
    } else if (!newCardInfo.securityCode) {
      inputError.message = 'セキュリティコードを入力してください。'
      inputError.code = 'security'
    }

    if (inputError.message !== null && inputError.code !== null) {
      setNewCardError(inputError)
      return
    }

    if (typeof Multipayment === 'undefined') {
      // 再読み込み。
      // TODO ユーザにダイアログを出してあげる方が良いかも
      addGmoPaymentTokenJs(true)
      return
    }

    Multipayment.init(shopId)
    const params = {
      cardno: newCardInfo.number,
      holdername: newCardInfo.name,
      expire: newCardInfo.year + '' + newCardInfo.month,
      securitycode: newCardInfo.securityCode,
    }
    // GMOのapiはreactの内のメソッドにコールバックできないみたいなので、
    // globalのメソッドで一応対応。ダサいな。
    Multipayment.getToken(params, gmoCallBack)
    checkGMOResponse()
  }

  const checkGMOResponse = () => {
    if (gmoResponse == null) {
      setTimeout(() => checkGMOResponse(), 500)
    } else {
      getTokenResult(gmoResponse)
    }
  }

  const pageBack = (e) => {
    e.preventDefault()
    navigate(-1)
  }

  return (
    <div className={css(styles.credit_card_main)}>
      <div className={css(styles.credit_card_info)}>
        <form>
          <CreditCardInput
            handleCardNumber={(data) => handleCardNumber(data)}
            handleCardName={(data) => handleCardName(data)}
            handleSecurityCode={(data) => handleSecurityCode(data)}
            handleExpireMonthYear={(month, year) => handleExpireMonthYear(month, year)}
            newCardError={newCardError}
          />
          <div className={css(styles.credit_card_back)}>
            <a className={css(styles.confirm_back_link)} href="#" onClick={(e) => pageBack(e)}>
              {'< 前のページに戻る'}
            </a>
          </div>
          <div className={css(styles.credit_card_form_group)}>
            <button type="button" className={css(styles.credit_card_submit)} onClick={(e) => handleSubmit(e)}>
              確認画面へ
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default PaymentNewCard
