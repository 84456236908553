import { Box } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const CustomCheckbox = ({ label, isChecked, onChange }) => {
  return (
    <Box as="button" role="checkbox" aria-checked={isChecked} onClick={onChange} display="flex" alignItems="center">
      <Box w="20px" h="20px" border="1px solid #e0e0e0" bg="#f3f3f8" position="relative" borderRadius="3px" mx={3}>
        {isChecked && (
          <Box
            as="span"
            position="absolute"
            left="5px"
            w="8px"
            h="14px"
            borderBottom="3px solid #e94841"
            borderRight="3px solid #e94841"
            transform="rotate(45deg)"
          />
        )}
      </Box>
      {label}
    </Box>
  )
}

CustomCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}
