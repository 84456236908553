import { PropTypes } from 'prop-types'

import { IconButton } from '@chakra-ui/react'
import { ChevronUpIcon } from '@chakra-ui/icons'

export const UpButton = ({ onClick, ...props }) => {
  return (
    <IconButton
      variant="solid"
      onClick={onClick}
      colorScheme="primary"
      size="md"
      isRound={true}
      aria-label="Scroll to top"
      icon={<ChevronUpIcon fontSize="2xl" />}
      {...props}
    />
  )
}

UpButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}
