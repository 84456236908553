import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/react'

export const RoundButton = ({ children, bgColor, color, variant, ...props }) => {
  const styles = {
    solid: {
      bg: bgColor,
      color: color,
      _hover: {
        opacity: 0.6,
      },
    },
    outline: {
      bg: 'white',
      borderColor: bgColor,
      borderWidth: 1,
      color: color,
      _hover: {
        opacity: 0.6,
      },
    },
  }

  return (
    <Button borderRadius="9999px" p="0px" {...styles[variant]} {...props}>
      {children}
    </Button>
  )
}

RoundButton.propTypes = {
  children: PropTypes.node.isRequired,
  p: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
  fontSize: PropTypes.string,
  variant: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
}

RoundButton.defaultProps = {
  p: '0px',
  width: '100%',
  height: '44px',
  variant: 'solid',
  color: 'white',
  bgColor: 'black',
}
