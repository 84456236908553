import { PropTypes } from 'prop-types'

import { HStack, VStack, Box } from '@chakra-ui/react'

import { AppleButton, FacebookButton, TwitterButton } from './Button'
import { CenterLineText } from './Text'

export const SocialRegistration = ({ handleRegistration }) => {
  // NOTE: テスト用にTwitterボタンを有効化
  const isDebug = ['development', 'staging', 'test'].includes(process.env.RAILS_ENV)

  return (
    <VStack spacing={0}>
      <CenterLineText fontSize="sm" fontWeight="bold" my={2}>
        お持ちのアカウントで会員登録する
      </CenterLineText>
      <HStack spacing={0} my={2}>
        <Box mx={2}>
          <AppleButton onClick={() => handleRegistration('apple')} />
        </Box>
        <Box mx={2}>
          <FacebookButton onClick={() => handleRegistration('facebook')} />
        </Box>
        {isDebug && (
          <Box mx={2}>
            <TwitterButton onClick={() => handleRegistration('twitter')} />
          </Box>
        )}
      </HStack>
    </VStack>
  )
}

SocialRegistration.propTypes = {
  handleRegistration: PropTypes.func.isRequired,
}
