import { PropTypes } from 'prop-types'

import { Text, VStack } from '@chakra-ui/react'

import { PointExpirationButton } from './PointExpirationButton'
import { PointLabel } from './PointLabel'

export const PointBox = (props) => {
  const { pointCount } = props

  return (
    <VStack paddingBottom={'24px'}>
      <Text fontSize={'1em'} fontWeight={600}>
        保有ポイント数
      </Text>
      <PointLabel pointCount={pointCount} />
      <PointExpirationButton />
    </VStack>
  )
}

PointBox.propType = {
  pointCount: PropTypes.number.isRequired,
}
