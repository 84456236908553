import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

import { Error401 } from '@!/Errors/components/Error401'
import { Error404 } from '@!/Errors/components/Error404'
import { Error500 } from '@!/Errors/components/Error500'

const Errors = ({ status }) => {
  return <Box pb={12}>{status === 401 ? <Error401 /> : status === 404 ? <Error404 /> : <Error500 />}</Box>
}

Errors.propTypes = {
  status: PropTypes.number.isRequired,
}

export default Errors
