import queryString from 'query-string'

export const buildUrlString = (path, query, fragmentIdentifier = '') => {
  const protocol = window.location.protocol
  const host = window.location.host

  return queryString.stringifyUrl(
    {
      url: `${protocol}//${host}${path}`,
      query: query,
      fragmentIdentifier: fragmentIdentifier,
    },
    {
      // Railsは配列を'foo[]=1&foo[]=2&foo[]=3'の形で受け取るので'bracket'を指定
      arrayFormat: 'bracket',
    },
  )
}
