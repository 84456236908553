import { useMemo } from 'react'
import { PropTypes } from 'prop-types'

import { Modal, ModalContent, ModalCloseButton, ModalOverlay } from '@chakra-ui/react'

import { MenuHeader, LoggedInMenuBody, LoggedOutMenuBody } from '@!/Menu/components/'
import { getDevice } from '@!/Util/Device'

export const Menu = ({ isLoggedIn, isOpen, onClose }) => {
  const device = useMemo(() => getDevice(), [])

  const MenuBody = isLoggedIn ? LoggedInMenuBody : LoggedOutMenuBody

  return (
    // NOTE: スマホ向けはフルスクリーン、PC向けはモーダル表示
    <Modal isOpen={isOpen} onClose={onClose} size={device === 'pc' ? 'xl' : 'full'}>
      {device === 'pc' && <ModalOverlay />}
      <ModalContent>
        <ModalCloseButton border="none" />
        <MenuHeader />
        <MenuBody />
      </ModalContent>
    </Modal>
  )
}

Menu.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default Menu
