import { useMemo } from 'react'
import { useAtom } from 'jotai'
import { getDevice } from '@!/Util'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { Banner } from './Banner'
import loggedInPointbackSpBannerImage from '@images/logged_in_point_back_sp_banner.webp'
import loggedInPointbackPcBannerImage from '@images/logged_in_point_back_pc_banner.webp'
import notLoggedInPointbackSpBannerImage from '@images/not_logged_in_point_back_sp_banner.webp'
import notLoggedInPointbackPcBannerImage from '@images/not_logged_in_point_back_pc_banner.webp'
import slimNoImageBanner from '@images/slim_noimage.webp'

export const PointBackBanner = (props) => {
  const [userHash] = useAtom(userHashAtom)
  const device = useMemo(() => getDevice(), [])

  let banner
  if (device === 'pc') {
    banner = userHash ? loggedInPointbackPcBannerImage : notLoggedInPointbackPcBannerImage
  } else {
    banner = userHash ? loggedInPointbackSpBannerImage : notLoggedInPointbackSpBannerImage
  }

  return <Banner src={banner} fallbackSrc={slimNoImageBanner} href="/event" alt="初回限定90%OFF、全作品対象40%ポイント還元キャンペーン" {...props} />
}
