import { PropTypes } from 'prop-types'

import { HStack, Image, Link } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util'
import CoinPurchaseBannerPng from '@images/coin_purchase_banner.png'
import CoinPurchasePcBannerPng from '@images/coin_purchase_pc_banner.png'

export const CoinPurchaseBanner = ({ device }) => {
  const bannerImage = device === 'pc' ? CoinPurchasePcBannerPng : CoinPurchaseBannerPng

  return (
    <HStack spacing={0} px={4}>
      <Link href="/user/payment">
        <Image src={buildAssetUrlString(bannerImage)} alt="最大400コインお得！コインを購入する" borderRadius="4px" />
      </Link>
    </HStack>
  )
}

CoinPurchaseBanner.propType = {
  device: PropTypes.oneOf(['sp', 'tab', 'pc']).isRequired,
}
