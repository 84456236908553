import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

import { createCartItemsApi } from '@!/Api/Cart'
import { cartItemsKeys } from '@!/Api/queryKeys'

export const useCreateCartItems = (userHash) => {
  const [apiError, setApiError] = useState(null)

  const queryClient = useQueryClient()
  const createCartItemsMutation = useMutation({
    mutationFn: (params) => createCartItemsApi(params),
    onSuccess: () => {
      // API成功時はreact-queryのキャッシュをクリアする
      queryClient.invalidateQueries(cartItemsKeys.cartItemsLoggedIn(userHash))
    },
    onError: (error) => {
      if (error.data?.error) {
        setApiError(error.data.error)
      } else {
        setApiError('server error')
      }
    },
  })

  return [createCartItemsMutation, apiError]
}
