import { useMemo } from 'react'
import { PropTypes } from 'prop-types'

import { Image, AspectRatio } from '@chakra-ui/react'
import LazyLoad from 'react-lazyload'

import { buildAssetUrlString } from '@!/Util/buildAssetUrlString'

// ノーイメージ画像
import NoImagePng from '@images/noimage.png'

export const CoverImage = ({ src, variant, width, ...props }) => {
  const noImagePath = useMemo(() => buildAssetUrlString(NoImagePng), [])
  const imageSrc = src ? src : noImagePath
  const filter = variant === 'grayscale' ? 'grayscale(100%)' : 'none'

  return (
    <AspectRatio ratio={1 / 1.4} w={width} minW={width}>
      <LazyLoad offset={100} once>
        <Image src={imageSrc} filter={filter} {...props} fallbackSrc={noImagePath} w="100%" h="100%" />
      </LazyLoad>
    </AspectRatio>
  )
}

CoverImage.propType = {
  src: PropTypes.string, // エラーで画像が無いケースがある
  variant: PropTypes.string, // variant: default or grayscale
  width: PropTypes.string.isRequired,
}
