import { PropTypes } from 'prop-types'

import { HStack, Link, Spacer, Stack, Box } from '@chakra-ui/react'

import { Login, HamburgerMenu, Cart, Guide, Bookshelf, Coupon } from './menus'
import { HeaderBox, CoinOwned, PointOwned } from './'
import { BrandLogo } from '@!/components/BrandLogo'
import { ContentContainer } from '@!/components/Container'

export const NewHeader = ({ isLoggedIn, isInitial, coinCount, pointCount, cartCount, handleMenuOpen }) => {
  const openMenu = () => handleMenuOpen(true)
  const Logo = () => {
    return (
      <Link href="/">
        <BrandLogo htmlWidth="170" />
      </Link>
    )
  }

  // ページ遷移の度にヘッダーのログイン中/未ログインのアイコンが切り替わるのを防ぐため、
  // userHashが取得できるまではヘッダー枠のみ表示
  if (isInitial) {
    return <HeaderBox />
  }

  return (
    <HeaderBox>
      <ContentContainer h="100%">
        <HStack h="100%" spacing={0}>
          <HamburgerMenu handleOnClick={openMenu} />
          <Stack spacing={0}>
            <Logo />
            {isLoggedIn && (
              <HStack spacing={0} mt="1px">
                <Box mr={2}>
                  <CoinOwned coinCount={coinCount} />
                </Box>
                <PointOwned pointCount={pointCount} />
              </HStack>
            )}
          </Stack>
          <Spacer />
          {isLoggedIn ? (
            <Coupon />
          ) : (
            <>
              <Guide />
              <Box mx={1} mt="auto">
                <Login />
              </Box>
            </>
          )}
          <Cart count={cartCount} />
          {/* 未ログイン時にカートのアイテム数がはみ出るため、空のBoxを配置してアイテム数を表示するスペースを作成する */}
          {isLoggedIn ? <Bookshelf /> : <Box>&nbsp;</Box>}
        </HStack>
      </ContentContainer>
    </HeaderBox>
  )
}

NewHeader.propType = {
  isLoggedIn: PropTypes.bool.isRequired,
  coinCount: PropTypes.number.isRequired,
  pointCount: PropTypes.number.isRequired,
  cartCount: PropTypes.number.isRequired,
  handleMenuOpen: PropTypes.func.isRequired,
}
