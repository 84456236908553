import { useEffect, useRef } from 'react'

import { useToast } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { csrfTokenAtom, csrfParamsAtom, alertMessageAtom } from '@!/atoms/appSettingsAtom'
import { SocialRegistration } from '@!/User/Login/components'
import { buildUrlString } from '@!/Util'

export const SocialRegistrationForm = () => {
  const toast = useToast()
  const [csrfToken] = useAtom(csrfTokenAtom)
  const [csrfParams] = useAtom(csrfParamsAtom)
  const [alertMessage, setAlertMessage] = useAtom(alertMessageAtom)
  const formRef = useRef()

  const handleRegistration = (provider) => {
    console.log(provider)
    // NOTE: テスト用にTwitterボタンを有効化
    if (!['apple', 'facebook', 'twitter'].includes(provider)) {
      return
    }

    if (formRef.current) {
      // クエリーパラメータを指定するとコールバックの呼び出しの際に
      // request.env['omniauth.params'] で取得出来る
      formRef.current.action = buildUrlString(`/auth/${provider}`, { from: 'registration' })
      formRef.current.submit()
    }
  }

  useEffect(() => {
    if (alertMessage) {
      toast({
        title: 'Notification',
        description: alertMessage,
        status: 'error',
        duration: 5000,
        isClosable: true,
        onCloseComplete: () => {
          setAlertMessage(null)
        },
      })
    }
  }, [alertMessage])

  return (
    <>
      <form method="post" ref={formRef}>
        {csrfParams && csrfToken && <input type="hidden" name={csrfParams} value={csrfToken} />}
      </form>
      <SocialRegistration handleRegistration={handleRegistration} />
    </>
  )
}
