import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { HStack, Button } from '@chakra-ui/react'
import { RightArrowIcon, LeftArrowIcon } from '@!/components/Icon'

export const Paginate = ({ currentPage, totalCount, perPage, pageMargin, onPageChange }) => {
  const numberOfPages = Math.max(1, Math.ceil(totalCount / perPage))

  const shouldRender = (page) => {
    return page == currentPage || Math.abs(page - currentPage) <= pageMargin || page === numberOfPages || page === 1
  }

  const shouldRenderEllipsis = (page) => {
    return page == currentPage || Math.abs(page - currentPage) === pageMargin + 1
  }

  const handlePageClick = (page) => {
    let newPage = page
    if (page >= numberOfPages) {
      newPage = numberOfPages
    } else if (page <= 0) {
      newPage = 1
    }
    onPageChange(newPage)
  }

  const baseButtonOptions = {
    color: 'black',
    bg: 'white',
    mx: 0.5,
    fontSize: 'xs',
    maxH: '1.8rem',
    maxW: '1.0rem',
    minW: '1.0rem',
    paddingX: '0.9rem',
  }

  const NumButton = ({ page }) => {
    const options = {
      ...baseButtonOptions,
      variant: 'outline',
    }

    if (page == currentPage) {
      options.bg = 'label.primary'
      options.color = 'white'
    }

    return (
      <Button
        {...options}
        onClick={() => {
          handlePageClick(page)
        }}
      >
        {page}
      </Button>
    )
  }

  const EllipsisButton = () => {
    const options = {
      ...baseButtonOptions,
      variant: 'ghost',
    }

    return <Button {...options}>...</Button>
  }

  return (
    <HStack spacing={0}>
      {numberOfPages > 1 && currentPage !== 1 && (
        <Button {...baseButtonOptions} variant="ghost">
          <LeftArrowIcon
            onClick={(e) => {
              e.preventDefault()
              handlePageClick(currentPage - 1)
            }}
          />
        </Button>
      )}
      {Array(numberOfPages)
        .fill(0)
        .map((_, i) => {
          return shouldRender(i + 1) ? (
            <NumButton key={i} page={i + 1} />
          ) : shouldRenderEllipsis(i + 1) ? (
            <EllipsisButton key={i} />
          ) : (
            <Fragment key={i}></Fragment>
          )
        })}
      {numberOfPages > 1 && currentPage !== numberOfPages && (
        <Button {...baseButtonOptions} variant="ghost">
          <RightArrowIcon
            onClick={(e) => {
              e.preventDefault()
              handlePageClick(currentPage + 1)
            }}
          />
        </Button>
      )}
    </HStack>
  )
}

/*
    currentPage: 表示中のページ番号
    totalCount: 全件数
    perPage: 1ページあたりの件数（デフォルト10）
    pageMargin: 表示中のページからpageMargin離れているページから省略表示（デフォルト3）
    onPageChange: ページ番号をクリックした時に実行するメソッド
 */
Paginate.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  perPage: PropTypes.number,
  pageMargin: PropTypes.number,
  onPageChange: PropTypes.func,
}

Paginate.defaultProps = {
  perPage: 10,
  pageMargin: 1,
}
