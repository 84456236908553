import { PropTypes } from 'prop-types'

import { HStack, VStack, Box } from '@chakra-ui/react'

import { AppleButton, FacebookButton, TwitterButton } from './Button'
import { CenterLineText } from './Text'

export const SocialLogin = ({ handleLogin }) => {
  return (
    <VStack spacing={0} my={3}>
      <CenterLineText fontSize="sm" fontWeight="bold" my={2}>
        お持ちのアカウントでログイン
      </CenterLineText>
      <HStack spacing={0} my={2}>
        <Box mx={2}>
          <AppleButton onClick={() => handleLogin('apple')} />
        </Box>
        <Box mx={2}>
          <FacebookButton onClick={() => handleLogin('facebook')} />
        </Box>
        <Box mx={2}>
          <TwitterButton onClick={() => handleLogin('twitter')} />
        </Box>
      </HStack>
    </VStack>
  )
}

SocialLogin.propTypes = {
  handleLogin: PropTypes.func.isRequired,
}
