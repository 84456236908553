import { createIcon } from '@chakra-ui/icons'

export const MenuIcon = createIcon({
  displayName: 'MenuIcon',
  viewBox: '0 0 28 24',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.1 11.563H5.9c-.497 0-.9.42-.9.937 0 .518.403.938.9.938h16.2c.497 0 .9-.42.9-.938s-.403-.938-.9-.938Zm0 6.562H5.9c-.497 0-.9.42-.9.938s.403.937.9.937h16.2c.497 0 .9-.42.9-.938 0-.517-.403-.937-.9-.937ZM5.9 6.875h16.2c.497 0 .9-.42.9-.938C23 5.42 22.597 5 22.1 5H5.9c-.497 0-.9.42-.9.938s.403.937.9.937Z"
    />
  ),
})
