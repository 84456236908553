import React, { useEffect, useState, useRef } from 'react'
import { Box } from '@chakra-ui/react'

export const ScrollableContent = ({ children }) => {
  const [showGradient, setShowGradient] = useState(true)
  const scrollableRef = useRef(null)

  const checkScrollPosition = () => {
    const element = scrollableRef.current
    if (element) {
      const { scrollWidth, clientWidth, scrollLeft } = element
      if (scrollLeft >= scrollWidth - clientWidth) {
        setShowGradient(false)
      } else {
        setShowGradient(true)
      }
    }
  }

  useEffect(() => {
    const element = scrollableRef.current
    if (element) {
      element.addEventListener('scroll', checkScrollPosition)
    }
    return () => {
      if (element) {
        element.removeEventListener('scroll', checkScrollPosition)
      }
    }
  }, [])

  return (
    <Box position="relative">
      <Box
        ref={scrollableRef}
        overflowX="scroll"
        css={{
          '::-webkit-scrollbar': { display: 'none' },
        }}
        onScroll={checkScrollPosition}
      >
        {children}
      </Box>
      {showGradient && (
        <Box
          position="absolute"
          top="0"
          right="-1"
          bottom="0"
          width="80px"
          bgGradient="linear(to-r, rgba(255, 255, 255, 0), bg.primary)"
          pointerEvents="none"
        />
      )}
    </Box>
  )
}
