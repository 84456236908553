import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { Box } from '@chakra-ui/react'

import { PointAndTable } from '@!/User/PointHistories/components/'
import usePointList from '@!/User/PointHistories/hooks/usePointList'
import { userHashAtom, userPointsAtom } from '@!/atoms/userInfoAtom'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'

export const PointHistories = () => {
  const [userHash] = useAtom(userHashAtom)
  const [pointCount] = useAtom(userPointsAtom)
  const { isError, pointList } = usePointList(userHash)
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: '保有ポイント数' }])
  }, [])

  if (isError) {
    return <Box>Error</Box>
  }

  return <PointAndTable pointCount={parseInt(pointCount, 10)} pointList={pointList} />
}
