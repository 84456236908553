import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/react'
import queryString from 'query-string'

import { TwitterIcon } from '@!/components/Icon'

export const ShareXButton = ({ title }) => {
  const pseudoStyle = { bg: 'social.x', color: 'white' }

  return (
    <Button
      size="xs"
      as="a"
      href={queryString.stringifyUrl({ url: 'https://twitter.com/share', query: { url: window.location.href, text: title } })}
      target="_blank"
      rel="noopener noreferrer"
      leftIcon={<TwitterIcon width="1.2em" height="1.2em" mr={-1} />}
      bg="social.x"
      color="white"
      _hover={pseudoStyle}
      _active={pseudoStyle}
    >
      ツイート
    </Button>
  )
}

ShareXButton.propTypes = {
  title: PropTypes.string.isRequired,
}
