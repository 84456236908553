import { Link } from '@chakra-ui/react'

export const AuthorList = ({ authors }) => {
  return (
    <>
      {authors
        .split(',')
        .slice(0, 3)
        .map((author, index) => (
          <Link key={index} textDecoration="none" href={`/store/search/author/${author}`}>
            {author}
          </Link>
        ))
        .reduce((acc, curr) => [acc, ', ', curr])}
      {authors.split(',').length > 3 && '...'}
    </>
  )
}
