import PropTypes from 'prop-types'
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button, Image } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util'

import SecurityCodeHelpPng from '@images/security_code_help.png'

export const CreditCardSecurityCodePopupModal = ({ handlePopup, isOpen = true }) => (
  <Modal isCentered isOpen={isOpen} onClose={handlePopup}>
    <ModalOverlay />
    <ModalContent>
      <ModalBody>
        <Image src={buildAssetUrlString(SecurityCodeHelpPng)} />
      </ModalBody>
      <ModalFooter>
        <Button onClick={handlePopup}>OK</Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
)

CreditCardSecurityCodePopupModal.propTypes = {
  isOpen: PropTypes.bool,
  handlePopup: PropTypes.func.isRequired,
}
