import { CartIcon, ViewCartIcon } from '@!/components/Icon'
import { NormalIconButton } from '@!/components/Button'
import { HStack } from '@chakra-ui/react'

export const ViewCartIconButton = ({ iconOnly, ...props }) => {
  const handleButtonClick = (e) => {
    e.preventDefault()
    window.location.href = '/cart'
  }

  return iconOnly ? (
    <NormalIconButton icon={<CartIcon width="1.5em" height="1.5em" />} onClick={handleButtonClick} aria-label="カートを見る" {...props} />
  ) : (
    <HStack spacing={0} onClick={handleButtonClick}>
      <ViewCartIcon width="80px" height="23px" mr={1} />
      <NormalIconButton icon={<CartIcon width="1.5em" height="1.5em" />} aria-label="カートを見る" />
    </HStack>
  )
}
