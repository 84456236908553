import { useEffect, useState } from 'react'
import { css } from 'aphrodite'
import { styles } from '../styles/PaymentStyle'
import { CreditCardSecurityCodePopupModal } from '@!/User/Payment/components/CreditCardSecurityCodePopupModal'

export const CreditCardInput = ({ handleCardNumber, handleCardName, handleSecurityCode, handleExpireMonthYear, newCardError }) => {
  const [isSecurityPopup, setIsSecurityPopup] = useState(false)
  const nowDate = new Date()
  const defaultYear = nowDate.getFullYear()
  const defaultMonth = '01'

  useEffect(() => {
    handleExpireMonthYear(defaultMonth, defaultYear)
  }, [])

  const handleSecurityPopup = () => {
    setIsSecurityPopup(!isSecurityPopup)
  }

  const getMonthList = () => {
    return [
      { value: '01', name: '1月' },
      { value: '02', name: '2月' },
      { value: '03', name: '3月' },
      { value: '04', name: '4月' },
      { value: '05', name: '5月' },
      { value: '06', name: '6月' },
      { value: '07', name: '7月' },
      { value: '08', name: '8月' },
      { value: '09', name: '9月' },
      { value: '10', name: '10月' },
      { value: '11', name: '11月' },
      { value: '12', name: '12月' },
    ]
  }

  const handleCreditNumberInput = (event) => {
    const target = event.currentTarget
    const currentValue = target.value
    if (String(currentValue).length >= 16) {
      event.preventDefault()
    }
  }

  const handleSecurityCodeInput = (event) => {
    const target = event.currentTarget
    const currentValue = target.value
    if (String(currentValue).length >= 4) {
      event.preventDefault()
    }
  }

  const handleTransferCardNumber = (event) => {
    handleCardNumber(event.target.value)
  }

  const handleTransferCardName = (event) => {
    handleCardName(event.target.value)
  }

  const handleTransferSecurityCode = (event) => {
    handleSecurityCode(event.target.value)
  }

  const handleTransferExpireMonth = (event) => {
    handleExpireMonthYear(event.target.value, null)
  }

  const handleTransferExpireYear = (event) => {
    handleExpireMonthYear(null, event.target.value)
  }

  const month_options = getMonthList().map((month) => {
    return (
      <option value={month.value} key={month.value}>
        {month.name}
      </option>
    )
  })

  const year_options = []
  for (let ii = defaultYear; ii < defaultYear + 10; ii++) {
    year_options.push(
      <option value={ii} key={ii}>
        {ii}
      </option>,
    )
  }

  return (
    <div>
      {newCardError.message != null ? <div style={{ color: '#f00', margin: '12px 0' }}>{newCardError.message}</div> : null}
      <div className={css(styles.credit_card_form_group)} id="card-number-field">
        <label htmlFor="cardNumber" className={css(styles.credit_card_label)}>
          クレジットカード番号
        </label>
        <input
          type="tel"
          name="addCreditCardNumber"
          className={css(styles.credit_card_form)}
          onKeyPress={(e) => handleCreditNumberInput(e)}
          onChange={(e) => handleTransferCardNumber(e)}
          placeholder={'0123456789012345'}
          style={newCardError.code == 'number' ? { backgroundColor: '#ffb3b3' } : { backgroundColor: '#fff' }}
        />
        <p className={css(styles.credit_card_label_attention)}>＊ハイフン無し、半角数字</p>
      </div>
      <div className={css(styles.credit_card_form_group)}>
        <label htmlFor="owner" className={css(styles.credit_card_label)}>
          カード名義
        </label>
        <input
          type="text"
          className={css(styles.credit_card_form)}
          onChange={(e) => handleTransferCardName(e)}
          placeholder={'TARO MANGA'}
          style={newCardError.code == 'name' ? { backgroundColor: '#ffb3b3' } : { backgroundColor: '#fff' }}
        />
        <p className={css(styles.credit_card_label_attention)}>＊半角ローマ字</p>
      </div>
      <div className={css(styles.credit_card_form_group)}>
        <label className={css(styles.credit_card_label)}>有効期限(月/年)</label>
        <select
          className={css(styles.credit_card_expire_select)}
          defaultValue={defaultMonth}
          onChange={(e) => handleTransferExpireMonth(e)}
          style={newCardError.code == 'expire' ? { backgroundColor: '#ffb3b3' } : { backgroundColor: '#fff' }}
        >
          {month_options}
        </select>
        <span style={{ marginLeft: 6, marginRight: 6 }}>/</span>
        <select
          className={css(styles.credit_card_expire_select)}
          defaultValue={defaultYear}
          onChange={(e) => handleTransferExpireYear(e)}
          style={newCardError.code == 'expire' ? { backgroundColor: '#ffb3b3' } : { backgroundColor: '#fff' }}
        >
          {year_options}
        </select>
      </div>
      <div className={css(styles.credit_card_form_group)}>
        <div>
          <label className={css(styles.credit_card_security_code_label)}>セキュリティコード</label>
          <i className={css(styles.credit_card_security_code_question) + ' fa fa-question-circle'} onClick={handleSecurityPopup}></i>
          <CreditCardSecurityCodePopupModal isOpen={isSecurityPopup} handlePopup={handleSecurityPopup} />
        </div>
        <input
          type="tel"
          className={css(styles.credit_card_security_code_form)}
          onKeyPress={(e) => handleSecurityCodeInput(e)}
          onChange={(e) => handleTransferSecurityCode(e)}
          style={newCardError.code == 'security' ? { backgroundColor: '#ffb3b3' } : { backgroundColor: '#fff' }}
        />
        <p className={css(styles.credit_card_label_attention)}>＊半角数字</p>
      </div>
    </div>
  )
}

export default CreditCardInput
