import { IconButton } from '@chakra-ui/react'

import { FacebookIcon } from '@!/components/Icon'

export const FacebookButton = ({ onClick }) => {
  const styles = {
    color: 'white',
    fontSize: '22px',
    bg: '#4A6099;',
    _hover: '#4A6099',
    _active: '#4A6099',
    width: '57px',
    height: '57px',
  }

  return <IconButton isRound={true} aria-label="Sign in with Facebook" icon={<FacebookIcon />} {...styles} onClick={onClick} />
}
