import { createIcon } from '@chakra-ui/icons'

export const LeftArrowIcon = createIcon({
  displayName: 'LeftArrowIcon',
  viewBox: '0 0 18 24',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.75011 11.9925L3.75 11.9925V12C3.75 12.3065 3.87331 12.5663 4.10253 12.7841C4.10278 12.7843 4.10302 12.7845 4.10327 12.7848L9.46772 17.9535C9.66439 18.1546 9.92337 18.25 10.2016 18.25C10.782 18.25 11.25 17.804 11.25 17.2136C11.25 16.9247 11.13 16.6653 10.9345 16.4727L10.9323 16.4706L6.28112 12L10.9323 7.52939L10.9324 7.5295L10.9384 7.52332C11.1296 7.3265 11.25 7.06927 11.25 6.77966C11.25 6.19455 10.7806 5.75 10.2016 5.75C9.92337 5.75 9.66439 5.84537 9.46772 6.04646L4.10252 11.2159C3.86927 11.4375 3.75895 11.6964 3.75011 11.9925Z"
    />
  ),
})
