import { Routes, Route } from 'react-router-dom'

import { AppProvider } from '@!/providers'
import PasswordReset from '../containers/PasswordReset'
import { LoginPage, RegistrationPage, RegistrationCompletePage } from '@!/User/Login/containers'
import { useAppSettings } from '@!/Hooks/useAppSettings'

const LoginApp = (props) => {
  const jotaiStore = useAppSettings({ isSaveLastUrl: false, csrfToken: props.csrf_token, csrfParams: props.csrf_params, alertMessage: props.alert_message })

  return (
    <AppProvider jotaiStore={jotaiStore}>
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/registrations" element={<RegistrationPage />} />
        <Route path="/registration_complete" element={<RegistrationCompletePage />} />
        <Route path="/password_reset" element={<PasswordReset />} />
        <Route path="/*" element={<LoginPage />} />
      </Routes>
    </AppProvider>
  )
}

export default LoginApp
