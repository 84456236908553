import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/react'

export const SmallReadButton = ({ colorScheme = 'red', size = 'sm', fontSize = 'xs', fontWeight = 'normal', w = 'full', ...props }) => {
  return (
    <Button colorScheme={colorScheme} size={size} fontSize={fontSize} fontWeight={fontWeight} w={w} {...props}>
      読む
    </Button>
  )
}

SmallReadButton.propType = {
  colorScheme: PropTypes.string,
  size: PropTypes.string,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  w: PropTypes.string,
}
