import { PropTypes } from 'prop-types'
import { Link, Image } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util'

export const Banner = ({ src, fallbackSrc, href, alt, gtmClick, ...props }) => {
  return (
    <Link href={href} {...(gtmClick && { 'data-gtm-click': gtmClick })}>
      <Image src={buildAssetUrlString(src)} fallbackSrc={buildAssetUrlString(fallbackSrc)} alt={alt} width="100%" {...props} />
    </Link>
  )
}

Banner.propTypes = {
  src: PropTypes.string.isRequired,
  fallbackSrc: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  gtmClick: PropTypes.string,
}
