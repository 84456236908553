import { useEffect } from 'react'
import { Container, Stack, Box, Heading, Text, Center } from '@chakra-ui/react'

import { NormalButton } from '@!/components/Button'
import { useLogout } from '@!/Hooks'

export const Error401 = () => {
  const [execLogoutMutation] = useLogout()

  useEffect(() => {
    // 401エラー時は強制ログアウト
    execLogoutMutation.mutate()
    setTimeout(() => (window.location.href = '/user/login'), 1000)
  }, [])

  return (
    <Container boxSizing="border-box" width="100%" p={5} pt={8} bg="bg.primary" mt={2}>
      <Stack spacing={0}>
        <Heading fontSize="md" textAlign="center" as="h2">
          ログアウトしました。
        </Heading>
        <Box fontSize="sm" my={8} textAlign="center">
          <Text>ログインセッションが無効になりました。</Text>
          <Text>ログインを行ってから再度お試しください。</Text>
        </Box>
        <Box fontSize="sm">
          <Center>
            <NormalButton w="full" as="a" href="/user/login">
              ログイン
            </NormalButton>
          </Center>
        </Box>
      </Stack>
    </Container>
  )
}
