import PropTypes from 'prop-types'
import { HStack, VStack, Box, Image } from '@chakra-ui/react'
import { buildAssetUrlString } from '@!/Util/buildAssetUrlString'

import StoreCoinSvg from '@images/store_coin.svg'

export const CoinHistoriesListItem = ({ item }) => {
  return (
    <HStack px="16px" py="12px">
      <VStack w="50%" textAlign="left">
        <Box w="100%" fontSize="11px" fontWeight="300" lineHeight="16.5px">
          {item.date}
        </Box>
        <Box w="100%" fontSize="16px" fontWeight="600" lineHeight="22px">
          {item.title}
        </Box>
      </VStack>
      <VStack w="50%" textAlign="right">
        <Box w="100%" h="16.5px" fontSize="11px" fontWeight="300" lineHeight="16.5px">
          {item.expire_date && item.expire_date.length > 1 ? '有効期限 : ' + item.expire_date : ''}
        </Box>
        <HStack w="100%" justifyContent="flex-end" spacing={0}>
          <Box mr={1}>
            <Image w="16px" src={buildAssetUrlString(StoreCoinSvg)} />
          </Box>
          <Box fontSize="16px" fontWeight="600" lineHeight="22px">
            {item.coin_count.toLocaleString()}
          </Box>
        </HStack>
      </VStack>
    </HStack>
  )
}

CoinHistoriesListItem.propTypes = {
  item: PropTypes.shape({
    coin_count: PropTypes.number.isRequired,
    date: PropTypes.string.isRequired,
    expire_date: PropTypes.string,
    title: PropTypes.string.isRequired,
  }).isRequired,
}
