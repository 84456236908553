import { IconButton } from '@chakra-ui/react'

import { TwitterIcon } from '@!/components/Icon'

export const TwitterButton = ({ onClick }) => {
  const styles = {
    color: 'white',
    fontSize: '22px',
    bg: '#6BAAE8;',
    _hover: '#6BAAE8',
    _active: '#6BAAE8',
    width: '57px',
    height: '57px',
  }

  return <IconButton isRound={true} aria-label="Sign in with Twitter" icon={<TwitterIcon />} {...styles} onClick={onClick} />
}
