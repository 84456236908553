import { useEffect, useLayoutEffect } from 'react'
import { useAtom } from 'jotai'
import { useLocation } from 'react-router-dom'

import { NewFooter as Footer } from '@!/Footer/components'
import { NewHeader as Header } from '@!/Header/components/NewHeader'
import { getCartItemsNotLoggedIn, resetCartItemsNotLoggedIn, saveCartItemsLoggedIn } from '@!/Util/LocalStorageData'
import Menu from '@!/Menu/containers/Menu'
import { useCartItems, useCoinCounts, usePointCount, useAppCookies, useCreateCartItems, useUpdateCartItems } from '@!/Hooks'
import { userHashAtom, userCoinsAtom, userPointsAtom, userUsableCoinsAtom } from '@!/atoms/userInfoAtom'
import { cartItemsAtom, numberOfCartItemsAtom } from '@!/atoms/cartItemsAtom'
import { isSaveLastUrlAtom, menuOpenAtom } from '@!/atoms/appSettingsAtom'
import { ContentContainer } from '@!/components/Container'
import { ScrollToTop } from '@!/components/ScrollToTop'

const App = (props) => {
  useEffect(() => {
    // Safariでbfcacheが有効になっている時は強制リロードする
    // pageshowイベントでpersistedがtrueの場合はbfcacheが有効になっている
    window.addEventListener('pageshow', (event) => {
      if (event.persisted) window.location.reload()
    })
  }, [])

  const { userHashCookie, setLastUrlCookie, skipCartUpdateFlagCookie } = useAppCookies()
  // ページ遷移時はスクロールを上に戻す
  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  const [isMenuOpen, setIsMenuOpen] = useAtom(menuOpenAtom)
  const [isSaveLastUrl] = useAtom(isSaveLastUrlAtom)

  useEffect(() => {
    if (isSaveLastUrl) {
      setLastUrlCookie(window.location.href)
    }
  }, [isSaveLastUrl])

  const [userHash, setUserHash] = useAtom(userHashAtom)
  const [, setUserCoins] = useAtom(userCoinsAtom)
  const [userUsableCoins] = useAtom(userUsableCoinsAtom)
  const [userPoints, setUserPoints] = useAtom(userPointsAtom)
  useEffect(() => {
    setUserHash(userHashCookie)
  }, [userHashCookie])

  const [, setCartItems] = useAtom(cartItemsAtom)
  const [numberOfCartItems] = useAtom(numberOfCartItemsAtom)
  const { data: cartItems, isSuccess: cartItemsIsSuccess, isError: cartItemsIsError, refetch: refetchCartItems } = useCartItems(userHash)
  const { data: coins } = useCoinCounts(userHash)
  const { data: points } = usePointCount(userHash)
  const updateCartItemsMutation = useUpdateCartItems(userHash)

  useEffect(() => {
    setUserCoins(coins)
  }, [coins])

  useEffect(() => {
    setUserPoints(points)
  }, [points])

  useEffect(() => {
    if (!cartItemsIsSuccess) return
    saveCartItemsLoggedIn(cartItems)
    setCartItems(cartItems)
  }, [cartItemsIsSuccess])

  useEffect(() => {
    if (!cartItemsIsError) return
    saveCartItemsLoggedIn([])
    setCartItems([])
  }, [cartItemsIsError])

  useEffect(() => {
    // ログイン直後のみカートの更新を実行
    // 更新されるとskipCartUpdateFlagCookieがtrueになり、それ以降のページ遷移では更新を実行しない
    if (userHash && !skipCartUpdateFlagCookie) {
      updateCartItemsMutation.mutate()
    }
  }, [userHash, skipCartUpdateFlagCookie])

  /* ログイン後のカートマージ処理 */
  const [createCartItemsMutation] = useCreateCartItems(userHash)

  useEffect(() => {
    if (!createCartItemsMutation.isSuccess) return
    console.log('createCartItemsMutation')

    // カートマージ処理が走るタイミングはログイン後で、ログイン後は必ずリダイレクト処理がありページ遷移が発生するため実際には必要がない
    // SPA化した場合には有効
    refetchCartItems()
  }, [createCartItemsMutation.isSuccess])

  useEffect(() => {
    if (!userHash) return

    // ログイン前カート情報の取得
    const localCartItems = getCartItemsNotLoggedIn()
    if (localCartItems.length == 0) return

    const params = {
      books: localCartItems.map((item) => {
        return { distribution_id: item.distribution_id, version: item.version }
      }),
    }
    createCartItemsMutation.mutate(params)

    // 成否に関わらずログイン前のカート情報はリセット
    resetCartItemsNotLoggedIn()
  }, [userHash])
  /* ログイン後のカートマージ処理 ここまで */

  const handleMenuOpen = (open) => {
    setIsMenuOpen(open)
  }

  return (
    <>
      <Header
        isLoggedIn={!!userHash}
        isInitial={userHash === ''}
        coinCount={userUsableCoins}
        pointCount={userPoints}
        cartCount={numberOfCartItems}
        handleMenuOpen={(open) => handleMenuOpen(open)}
      />
      <ContentContainer bg="bg.primary" boxShadow="0px 2px 6px 0px rgba(0, 0, 0, 0.10)">
        {props.children}
      </ContentContainer>
      <Footer />
      <Menu isLoggedIn={!!userHash} isOpen={isMenuOpen} onClose={() => handleMenuOpen(false)} />
      <ScrollToTop position="fixed" right="10px" bottom="10px" />
    </>
  )
}

export default App
