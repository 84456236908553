import { CartIcon } from '@!/components/Icon'
import { NormalButton } from '@!/components/Button'

export const ViewCartButton = () => {
  return (
    <NormalButton as="a" href="/cart" width="100%" height="37px" rightIcon={<CartIcon width="1.5em" height="1.5em" ml={-1} />}>
      カートを見る
    </NormalButton>
  )
}
