import { useState } from 'react'
import { useMutation } from 'react-query'
import { purchaseApi } from '@!/Api/StoreBookPurchase'
import { buildUrlString } from '@!/Util'

export const usePurchase = (previousPage) => {
  const [redirectUrl, setRedirectUrl] = useState(null)
  const [apiError, setApiError] = useState(null)

  const purchaseMutation = useMutation({
    mutationFn: (purchaseParams) => purchaseApi(purchaseParams),
    onSuccess: (data) => {
      let bookIds = []
      // 購入完了ページでは上位10件までを表示
      const book_histories = data.book_purchase_histories.slice(0, 10)
      book_histories.forEach((book) => {
        bookIds.push(book.mddc_id)
      })

      // order_id CVページのLink-AGタグ[ucd]に使用されているため必要
      setRedirectUrl(buildUrlString('/store/purchase/complete', { 'book_ids[]': bookIds, order_id: data.order_id }))
    },
    onError: (error) => {
      let errorMessage = ''
      let redirectError = false

      if (error.data?.error) {
        switch (error.data.error) {
          case 'NOT_ENOUGH_POINT':
            errorMessage = 'ポイントが不足しています。'
            break
          case 'PURCHASED_BOOK':
            errorMessage = '既に購入済みです。'
            break
          case 'NOT_ENOUGH_COIN':
            errorMessage = 'コインが不足しています。'
            break
          case 'INVALID_COUPON_CODE':
          case 'COUPON_NOT_APPLIED':
          case 'UNAVAILABLE_COUPON':
            errorMessage = 'クーポンが無効のため決済に失敗しました。'
            break
          case 'USED_COUPON':
            errorMessage = 'このクーポンはすでに使用されています。決済に失敗しました。'
            break
          case 'EXPIRED_COUPON':
            errorMessage = 'このクーポンは期限切れです。決済に失敗しました。'
            break
          case 'NOT_REGISTERED':
            errorMessage = 'クレジットカードが登録されていません。'
            break
          case 'INVALID_CARD_INFO':
            errorMessage = 'クレジットカードでの決済に失敗しました。'
            break
          case 'FAILED_USE_POINTS':
          case 'FAILED_ADD_POINTS':
          case 'FAILED_USE_COINS':
            errorMessage = 'ポイントの還元に失敗しました。お問合せください。'
            redirectError = true
            break
          case 'OUTDATED_CONTENTS':
            if (previousPage === 'cartPage') {
              errorMessage = 'カート内の作品情報が更新されております。ご確認の上、再度購入の手続きをお願いします。'
            } else {
              errorMessage = '購入に失敗しました。再度購入を行ってください。'
            }
            redirectError = true
            break
          default:
            errorMessage = '購入に失敗しました。再度購入を行ってください。'
            redirectError = true
        }
      } else {
        errorMessage = '購入に失敗しました。再度購入を行ってください。'
        redirectError = true
      }

      if (redirectError) {
        setRedirectUrl(buildUrlString('/store/purchase/error', { error_message: errorMessage }))
      }

      if (error.status === 422) {
        setRedirectUrl('/unauthorized')
      }

      setApiError(errorMessage)
    },
  })

  return [purchaseMutation, redirectUrl, apiError]
}
