import { useEffect, useMemo, useState } from 'react'
import { useAtom } from 'jotai'
import { css } from 'aphrodite'
import { useSearchParams } from 'react-router-dom'

import styles from '@!/Announcements/styles/AnnouncementsStyle'
import { useAnnouncementDetail } from '@!/Hooks'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'

const AnnouncementDetail = () => {
  const [searchParams] = useSearchParams()
  const [announcementId, setAnnouncementId] = useState(null)
  const { data: detail } = useAnnouncementDetail(announcementId)
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)
  const startsAt = useMemo(() => {
    if (!detail) return
    return new Date(detail.starts_at)
  }, [detail])

  useEffect(() => {
    if (!searchParams.get('id')) return
    setAnnouncementId(searchParams.get('id'))
  }, [searchParams])

  useEffect(() => {
    if (!detail) return

    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'お知らせ', ref: '/announcements' }, { name: detail.title }])
  }, [detail])

  return (
    <div>
      {detail && startsAt ? (
        <div style={{ maxWidth: '1080px', margin: '0 auto' }}>
          <h1 className={css(styles.announcements_list_detail_title)}>{detail.title}</h1>
          <div className={css(styles.announcements_list_detail)}>
            <div className={css(styles.announcements_list_detail_date)}>{`${startsAt.getFullYear()}/${startsAt.getMonth() + 1}/${startsAt.getDate()}`}</div>
            <div dangerouslySetInnerHTML={{ __html: detail.message }} />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default AnnouncementDetail
