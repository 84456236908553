export function getDevice() {
  const ua = navigator.userAgent
  if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || (ua.indexOf('Android') > 0 && ua.indexOf('Mobile') > 0)) {
    return 'sp'
  } else if (ua.indexOf('iPad') > 0 || ua.indexOf('Android') > 0) {
    return 'tab'
  } else {
    return 'pc'
  }
}

export function getOS() {
  const ua = navigator.userAgent
  if (ua.indexOf('iPhone') > 0 || ua.indexOf('iPod') > 0 || ua.indexOf('iPad') > 0) {
    return 'ios'
  } else if (ua.indexOf('Android') > 0) {
    return 'android'
  } else {
    return 'pc'
  }
}

export function getContentWidth(device) {
  if (device == 'sp') {
    return window.innerWidth
  } else if (device == 'tab') {
    return window.innerWidth
  } else {
    const w = (window.innerWidth * 1080) / 1400
    return w > 1080 ? w : 1080
  }
}

export function getDeviceInfo() {
  const os = getOS()
  const device = getDevice()
  const width = getContentWidth(device)
  return { os: os, device: device, width: width }
}
