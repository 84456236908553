import { useQuery } from 'react-query'
import { coinCountsApi } from '@!/Api/User'
import { userCoinKeys } from '@!/Api/queryKeys'
import { useAppCookies } from '@!/Hooks'

export const useCoinCounts = (userHash) => {
  const { coinCountsCookie } = useAppCookies()
  return useQuery(userCoinKeys.coinCounts(userHash), async () => {
    if (userHash) {
      // cookieから取得できた場合はその値を返す
      if (coinCountsCookie) return coinCountsCookie

      // cookieから取得できなかった場合はAPIを実行する
      try {
        return await coinCountsApi()
      } catch (error) {
        console.log(error)
      }
    }

    return {}
  })
}
