import { useMemo } from 'react'
import { PropTypes } from 'prop-types'

import { Box, Text } from '@chakra-ui/react'

import Ranking1Svg from '@images/ranking_1.svg'
import Ranking2Svg from '@images/ranking_2.svg'
import Ranking3Svg from '@images/ranking_3.svg'
import { buildAssetUrlString } from '@!/Util/buildAssetUrlString'

export const RankingCrown = ({ ranking }) => {
  const getRankingImage = (ranking) => {
    switch (ranking) {
      case 1:
        return Ranking1Svg
      case 2:
        return Ranking2Svg
      case 3:
        return Ranking3Svg
      default:
        return null
    }
  }
  const bgImage = useMemo(() => getRankingImage(ranking), [ranking])

  const RankingNumber = ({ ranking, color }) => {
    return (
      <Text position="absolute" bottom={0} textAlign="center" w="36px" fontSize="sm" fontWeight="bold" color={color}>
        {ranking}
      </Text>
    )
  }

  return (
    <Box w="36px" h="27px" position="relative" my={1} bgImage={bgImage ? `url(${buildAssetUrlString(bgImage)})` : null}>
      <RankingNumber ranking={ranking} color={bgImage ? 'label.white' : 'label.black'} />
    </Box>
  )
}

RankingCrown.propTypes = {
  ranking: PropTypes.number.isRequired,
}
