import PropTypes from 'prop-types'
import { IconButton } from '@chakra-ui/react'

export const NormalIconButton = ({ icon, ...props }) => {
  return <IconButton variant="outline" icon={icon} {...props} />
}

NormalIconButton.propTypes = {
  icon: PropTypes.object.isRequired,
}

NormalIconButton.defaultProps = {
  p: '0px',
  width: '37px',
  height: '37px',
  minW: '28px',
}
