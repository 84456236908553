import axios from '@!/lib/axios'
import { getCsrfToken } from '../Util/PostRequest'

// {name, email, title, description, screen_detail, operation_detail}
export const postInquiryApi = async (params) => {
  try {
    return await axios.post('/api/v1/inquiry.json', params, {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    })
  } catch (error) {
    throw new Error(error.message)
  }
}
