import { PropTypes } from 'prop-types'

import { HStack, Spacer, Stack, Text, Box } from '@chakra-ui/react'

import { CoinLabel } from './CoinLabel'
import { PrimaryButton } from '@!/components/Button'

export const CoinBox = (props) => {
  const { coinCount } = props

  return (
    <Stack padding={'15px 0 24px'}>
      <HStack padding={'0 8px'}>
        <Text fontSize={'1em'} fontWeight={600}>
          残コイン数
        </Text>
        <CoinLabel coinCount={coinCount} />
        <Spacer />
        <Box w="107px">
          <PrimaryButton as="a" href="/user/payment">
            コイン購入
          </PrimaryButton>
        </Box>
      </HStack>
    </Stack>
  )
}

CoinBox.propType = {
  coinCount: PropTypes.number.isRequired,
}
