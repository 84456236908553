import { Button } from '@chakra-ui/react'
import queryString from 'query-string'

import { FacebookIcon } from '@!/components/Icon'

export const ShareFacebookButton = () => {
  const pseudoStyle = { bg: 'social.facebook', color: 'white' }

  return (
    <Button
      size="xs"
      as="a"
      href={queryString.stringifyUrl({ url: 'https://www.facebook.com/sharer/sharer.php', query: { u: window.location.href } })}
      target="_blank"
      rel="noopener noreferrer"
      leftIcon={<FacebookIcon width="1.2em" height="1.2em" mr={-1} />}
      bg="social.facebook"
      color="white"
      _hover={pseudoStyle}
      _active={pseudoStyle}
    >
      シェア
    </Button>
  )
}
