import PropTypes from 'prop-types'
import { formatStringWithNewlines } from '@!/Util'
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalFooter, Button } from '@chakra-ui/react'

export const ConfirmPopupModal = ({ text, handlePopup, isOpen = true }) => {
  return (
    <Modal isCentered isOpen={isOpen} onClose={handlePopup}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>{formatStringWithNewlines(text)}</ModalBody>
        <ModalFooter>
          <Button onClick={handlePopup}>OK</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

ConfirmPopupModal.propTypes = {
  isOpen: PropTypes.bool,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  handlePopup: PropTypes.func.isRequired,
}
