import { AppProvider } from '@!/providers'
import CartContainer from '@!/Store/Cart/containers/CartContainer'

const CartApp = (props) => {
  return (
    <AppProvider>
      <CartContainer {...props} />
    </AppProvider>
  )
}

export default CartApp
