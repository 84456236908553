import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { Box, Text, Heading } from '@chakra-ui/react'

export const Terms = () => {
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: '利用規約' }])
  }, [])

  return (
    <Box p={4}>
      <Heading as="h1" size="lg" mb={2}>
        利用規約
      </Heading>
      <Text mb={4}>
        本利用規約（以下「本規約」といいます。）は、株式会社Amazia（以下「当社」といいます。）が運営するウェブサイト「マンガBANGブックス」及び通信端末用アプリケーション「マンガBANG」のご利用にあたり、ユーザーの皆様に遵守していただかなければならない事項及び当社とユーザーの皆様との間の権利義務関係を規定したものです。本サービスをご利用になる方は、本規約を必ず全文お読み頂き、ご同意の上で本サービスをご利用下さいますようお願い致します。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第1条 適 用
      </Heading>
      <Text mb={4}>
        1.
        本規約は、本サービス（第2条に定義）の利用に関する当社とユーザー（第2条に定義）との間の権利義務関係を定めることを目的とし、ユーザーと当社の間の本サービスの利用に関わる一切の関係に適用されます。
        <br />
        2.
        当社が本サービス（第2条に定義）上で随時掲載する本サービスに関する個別のルールその他諸規定等（以下「個別規約」といいます。）は、本規約の一部を構成するものとし、本規約と個別規約が矛盾する場合には、個別規約が優先するものとします。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第2条 定 義
      </Heading>
      <Text mb={4}>
        本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
        <br />
        (1)
        「知的財産権」とは、著作権、特許権、実用新案権、商標権、意匠権その他の知的財産権（それらの権利を取得し、又はそれらの権利につき登録等を出願する権利を含む。）を意味します。
        <br />
        (2) 「ユーザー」とは、第3条に基づき本サービスを利用する個人又は法人を意味します。
        <br />
        (3)
        「本サービス」とは、当社が提供する「マンガBANGブックス」という名称のウェブサイト及び「マンガBANG」という名称の通信端末用アプリケーション（理由の如何を問わずサービスの名称又は内容が変更された場合は、当該変更後のサービスを含みます。）によりユーザーに対し提供されるサービスを意味します。
        <br />
        (4) 「本サイト」とは、当社本サービスのウェブサイト「マンガBANGブックス」を意味します。
        <br />
        (5) 「通信端末用アプリケーション」とは、AndroidOS搭載デバイス及びiOS搭載デバイス用のアプリケーションを意味します。
        <br />
        (6) 「本アプリ」とは、本サービスの通信端末用アプリケーション「マンガBANG」を意味します。
        <br />
        (7) 「利用契約」とは、第3条第2項に基づき当社とユーザーの間で成立する、本規約の諸規定に従った本サービスの利用に関する契約を意味します。
        <br />
        (8) 「本コンテンツ」とは、本サービスにより提供される文章、画像その他の情報のことをいいます。
        <br />
        (9)
        「コイン」とは、本サービス内において有償で提供される電子的仮想通貨のことをいい、コンテンツの購入及び閲覧に使用することが可能です。コインには、コインの初回購入時に付与されるボーナスコインを含みます。
        <br />
        (10)
        「メダル」とは、本アプリ内において、当社所定の条件のもと無償で提供される電子的仮想通貨のことをいい、コンテンツのレンタル及び閲覧に使用することが可能です。メダルには、フリーメダル及びSPメダルを含みます。
        <br />
        (11)
        「ポイント」とは、本サイト内において、当社所定の条件のもと無償で提供される電子的仮想通貨のことをいい、コンテンツの購入及び閲覧に使用することが可能です。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第3条 本サービスの利用
      </Heading>
      <Text mb={4}>
        1.
        本サービスの利用を希望する者（以下「利用希望者」といいます。）は、予め本規約に同意した上で本アプリをインストールし、又はウェブブラウザにて提供される本サイトを利用するものとします。
        <br />
        2.
        前項に定めた本アプリをインストールした時点で、又は本サイトの利用を開始した時点をもって、利用契約がユーザーと当社の間に成立し、ユーザーは本サービスを当社の定める方法で利用することができるようになります。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第4条 未成年者の利用
      </Heading>
      <Text mb={4}>
        1.
        ユーザーが未成年者である場合、ユーザーは、本サービスの利用（本規約への同意を含みます）について、あらかじめ法定代理人（親権者等）の同意を得て、本サービスを利用するものとします。
        <br />
        2.
        本規約に同意した時点で未成年者であったユーザーが、成年に達した後に本サービスを利用した場合、未成年者であった間の利用行為を追認したものとみなされます。
        <br />
        3. 当社は、ユーザーの年齢に応じて、本サービスの一部又は全部の利用を制限する場合があります。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第5条 ユーザー登録
      </Heading>
      <Text mb={4}>
        1.
        本サービスの利用に際し、当社は、ユーザー情報の登録を求めることがあります。ユーザー情報の登録により、当社は、ユーザーに対し、アカウント（ID及びパスワードを含みます。）を付与します。ユーザーは、本サービスの利用に際し自らの情報を登録する場合、真実、正確かつ完全な情報を提供しなければならず、変更があった場合でも常に最新の情報となるよう修正しなければなりません。
        <br />
        (1) 本サービスでユーザー登録し付与されたアカウントは、本サイトおよび本アプリで利用できるものとします。
        <br />
        (2) 本サイトおよび本アプリのそれぞれでユーザー登録し別々にアカウントを付与された後に連携させることはできません。
        <br />
        (3) 本サイトおよび本アプリでアカウントを連携する場合、下記の方法で登録・ログインをして利用するものとします。
        <br />
        ⅰ.本アプリを利用している方で、本アプリでユーザー登録をしていない場合
        <br />
        本アプリで先にユーザー登録をしてから、本サイトでログインすること。
        <br />
        ⅱ.本アプリを利用している方で、本アプリでユーザー登録をしている場合
        <br />
        本アプリで利用しているアカウントで、本サイトでログインすること。
        <br />
        ⅲ.本アプリを利用していない方
        <br />
        本サイトでユーザー登録したアカウントで、本アプリでログインすること。
        <br />
        2. ユーザーは、登録により付与されたアカウントを不正に利用されないようご自身の責任で厳重に管理するものとします。
        <br />
        3.
        当社は、ユーザーのアカウントで行なわれた一切の行為を、ユーザーご本人の行為とみなすことができます。当社は、ユーザーのアカウントが使用されたことによってユーザーが被る損害について、当社に故意又は重過失がある場合を除き、一切の責任を負いません。
        <br />
        4. 当社は、最終のアクセスから6ヶ月以上利用実態のないアカウントを、あらかじめユーザーに通知することなく削除することができます。
        <br />
        5.
        本サービスのアカウントは、ユーザーに一身専属的に帰属します。ユーザーの本サービスにおけるすべての権利義務は、第三者に譲渡、貸与、交換、売買、換金、質入又は相続させることはできません。また、方法を問わず第三者に利用させてはならないものとします。
        <br />
        6. 当社は、第1項に基づき登録を申請した者が、以下の各号のいずれかの事由に該当する場合は、利用を拒否することがあります。
        <br />
        (1)
        未成年者、成年被後見人、被保佐人又は被補助人のいずれかであり、本サービスの利用について法定代理人、後見人、保佐人又は補助人の同意等を得ていなかった場合
        <br />
        (2)
        反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味する。以下同じ。）である、又は資金提供その他を通じて反社会的勢力等の維持、運営若しくは経営に協力若しくは関与する等反社会的勢力等との何らかの交流若しくは関与を行っていると当社が判断した場合
        <br />
        (3) その他、当社が利用を適当でないと判断した場合
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第6条 本サイトの内容
      </Heading>
      <Text mb={4}>
        1.
        ユーザーは、当社所定の数量のコイン又はポイントの使用あるいは別途当社が指定する決済方法その他当社所定の方法により、本コンテンツの購入及び閲覧が可能になります。
        <br />
        2.
        ユーザーは、コイン又はポイントを、当社が本サイト内で指定する方法で取得できるものとします。ただし、当社はユーザーが取得するコイン又はポイントの数量、内容、時刻その他の条件を自由に変更できるものとします。
        <br />
        3. 当社は、自由に本サイトに新しい本コンテンツや機能を追加、削除（既に配信済みの本コンテンツの削除を含みます。）又は変更することができるものとします。
        <br />
        4. 本コンテンツの内容は、本コンテンツの原作又は当社以外で提供されている同様のコンテンツと内容が異なる場合があります。
        <br />
        5.
        本サイトを使用するために必要な携帯端末、通信機器、通信手段等は、ユーザーの費用と責任で備えるものとし、パケット通信料、データ利用料等の通信料、電気料金その他の電気通信回線を利用するために要する費用はユーザーの負担とします。
        <br />
        6. ユーザーは、本サイトの利用にあたっては、本規約及び当社が別途定める規定のほか、著作権法その他一切等の法令を遵守するものとします。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第7条 コインの購入
      </Heading>
      <Text mb={4}>
        1. ユーザーは、以下の各号の条件により、当社が本サイト内で別途指定する価格にて、本コンテンツの購入及び閲覧に利用できるコインを購入できるものとします。
        <br />
        (1) コインは当社が本サイト内で指定する条件に従って本サイトのみで利用できること
        <br />
        (2) 本アプリでユーザー登録し付与されたアカウントを用いて本サイトを利用する場合、本サイトで購入したコインは本サイト及び本アプリでも利用できること
        <br />
        (3) 上記第2号にかかわらず、本アプリで購入したコインの利用対象は本アプリのみであること
        <br />
        (4) 本サイトで購入したコインの有効期限は、ユーザーがコインを購入した日から起算して180日目までとすること
        <br />
        2.
        当社は、ユーザーに対してボーナスコインを付与することがあります。ボーナスコインの有効期間は7日間とします。当社は、ボーナスコインの付与やユーザーの利用の有無に関して一切の責任を負わないものとします。
        <br />
        3. コイン購入は、全て消費税を加算した金額で精算されるものとします。
        <br />
        4. コインは、次の各号に該当する場合には、失効するものとします。
        <br />
        (1) 本条第1項第4号及び第2項又は本サイト内で別途指定された有効期限を経過した場合
        <br />
        (2) ユーザーが本サービスを退会した場合
        <br />
        (3)
        本アプリでユーザー登録し付与されたアカウントを用いて本サイトを利用する場合で、ユーザーが本アプリをアンインストール等（第16条第1項に定義）した場合（但し、第16条第3項の場合を除きます。）又は当該アカウントを削除した場合。なお、この場合、本サイトで保有するコインや購入履歴（本棚）の情報は削除されます。
        <br />
        (4) 当社が本サービスの提供を終了した場合
        <br />
        5.
        当社は、ユーザーが本条第1項に基づいてコインを購入した場合において、当社の故意若しくは重過失により購入手続完了後のキャンセルや当該代金の払戻しが必要となった場合、又は法令上認められる場合を除き、購入のキャンセルや当該代金の払戻しには一切応じないものとし、ユーザーは、これを予め了承するものとします。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第8条 ポイント
      </Heading>
      <Text mb={4}>
        1.ポイントは、当社が本サイト内で別途指定する方法にてユーザーに付与され、ユーザーはこれを本サイトでの本コンテンツの購入の際に代金支払いの一部又は全部に用いることができます。
        <br />
        2.ポイントは、本コンテンツの購入の際に還元される場合（以下「ポイント還元」といいます）と、その他キャンペーン等により付与される場合があります。
        <br />
        3.ユーザーは、以下の各号を条件に、本コンテンツの購入の際にポイント還元を受けることができます。
        <br />
        (1)ポイントは、本サイト内で別途表示される購入代金より消費税を除いた金額を対象に、本サイト内で別途表示するポイント還元率で計算され、小数点以下切り捨てとなること
        <br />
        (2)一部サービス又はコンテンツはポイント還元の対象外となる場合があること
        <br />
        (3)購入代金のうち、ポイントによる支払い充当分にはポイント還元されないこと。なお、ポイントを利用して本コンテンツを購入した場合、購入代金からポイントによる支払充当分を差し引いた残額があるときは、当該残額がポイント還元の対象となります。
        <br />
        (4)ポイント還元率は、当社の都合により予告なく変更される場合があること
        <br />
        4.ユーザーは、以下の各号の条件により、ポイントを利用することができます。
        <br />
        (1)本サイト内でのみ利用できること
        <br />
        (2)ユーザー自身のアカウントに付与されたポイントを1ポイントから利用すること。なお、ポイントの代金換算率は、1ポイント＝1円です。
        <br />
        5.ポイントの有効期限は、ユーザーが本コンテンツの購入した日から起算して30日目までとします。
        <br />
        6.ユーザーは、ポイントの失効及び保有残数を当社が定める方法により確認することができます。
        <br />
        7.ポイントは、次の各号に該当する場合には、失効するものとします。
        <br />
        (1)本条第5項に記載する有効期限を経過した場合
        <br />
        (2)ユーザーが本サービスを退会した場合
        <br />
        (3)本アプリでユーザー登録し付与されたアカウントを用いて本サイトを利用する場合で、ユーザーが本アプリをアンインストール等（第16条第1項に定義）した場合（但し、第16条第3項の場合を除きます。）又は当該アカウントを削除した場合。なお、この場合、本サイトで保有するポイントや購入履歴（本棚）の情報は削除されます。
        <br />
        (4)当社が本サービスの提供を終了した場合
        <br />
        8.当社は、いかなる場合であっても、利用または失効したポイントを返還せず、また、ポイントを現金その他に交換しません。
        <br />
        9.当社は、本規約に定める「本サービスの停止等」の場合、その他の事情により、本条に定めるポイント還元及びポイント利用のサービス提供を一定の予告期間をもって変更、中止又は終了する場合があります。ただし、緊急時等やむを得ない場合は、予告や通知ができないことがあります。
        <br />
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第9条 禁止事項
      </Heading>
      <Text mb={4}>
        ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為又はそのおそれがある行為を行わないものとします。
        <br />
        (1) 法令、裁判所の判決、決定若しくは命令、又は法令上拘束力のある行政措置に違反する行為
        <br />
        (2)
        当社、又は本サービスの他の利用者その他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利又は利益を侵害する行為（かかる侵害を直接又は間接に惹起する行為を含む。）
        <br />
        (3) 犯罪行為に関連する行為又は公序良俗に反する行為
        <br />
        (4) コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報を送信する行為
        <br />
        (5)
        過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引又は助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を、投稿又は送信する行為
        <br />
        (6) 当社又は第三者になりすます行為又は意図的に虚偽の情報を流布させる行為
        <br />
        (7) 同一又は類似のメッセージを不特定多数のユーザーに送信する行為（当社の認めたものを除きます。）、その他当社がスパムと判断する行為
        <br />
        (8) 当社が定める方法以外の方法で、本コンテンツの利用権を、現金、財物その他の経済上の利益と交換する行為
        <br />
        (9)
        営業、宣伝、広告、勧誘、その他営利を目的とする行為（当社の認めたものを除きます。）、性行為やわいせつな行為を目的とする行為、面識のない異性との出会いや交際を目的とする行為、他のユーザーに対する嫌がらせや誹謗中傷を目的とする行為、その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為
        <br />
        (10) 反社会的勢力に対する利益供与その他の協力行為
        <br />
        (11) 宗教活動又は宗教団体への勧誘行為
        <br />
        (12) 他人の個人情報、登録情報、利用履歴情報などを、不正に収集、開示又は提供する行為
        <br />
        (13)
        本サービスのサーバーやネットワークシステムに支障を与える行為、BOT、チートツール、その他の技術的手段を利用してサービスを不正に操作する行為、本サービスの不具合を意図的に利用する行為、ルーチングやジェイルブレイク等改変を行った通信端末にて本サービスにアクセスする行為、同様の質問を必要以上に繰り返す等、当社に対し不当な問い合わせ又は要求をする行為、その他当社による本サービスの運営又は他のユーザーによる本サービスの利用を妨害し、これらに支障を与える行為
        <br />
        (14) 過度に複数の通信機器類に本アプリを導入し、又はその他の方法により過度に複数のアカウントの付与を受ける行為
        <br />
        (15) 登録情報及びコイン等その他一切のアプリデータを貸与、交換、譲渡、名義変更、売買、質入、担保供与その他の方法により第三者に利用させ、又は処分する行為
        <br />
        (16) 通信妨害行為、通信傍受行為、本サービスを運営するサーバーへの侵入行為又は情報奪取行為
        <br />
        (17) 不正ツール、不正アプリケーション、本アプリの海賊版、チートツールその他本サービスの不正利用を目的としたプログラムの開発、配布若しくは使用行為
        <br />
        (18)
        本アプリの消去、改変（データの改ざんを含む）、修正、翻案、二次的著作物の作成、逆コンパイル、逆アセンブル若しくはリバースエンジニアリング、又はこれらの行為を第三者に誘発、推奨させるような一切の行為
        <br />
        (19) 本アプリの一部又は全部（コピーを含む）の再頒布及び貸与、並びに本アプリの再販又は転売行為
        <br />
        (20) 当社が禁止行為であることをユーザーに通知し、又は本アプリ上で公表した行為。
        <br />
        (21) 本サービスの運営を妨害する行為
        <br />
        (22) 当社役職員への脅迫、恐喝、威圧的不当要求及び不適切な言動などが認められる場合
        <br />
        (23) その他、当社が不適切と判断する行為
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第10条 本サービスの停止等
      </Heading>
      <Text mb={4}>
        1.
        当社は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの利用の全部又は一部を停止又は中断することができるものとします。
        <br />
        (1) 本サービスに係るコンピューター・システムの点検又は保守作業を定期的又は緊急に行う場合
        <br />
        (2) コンピューター、通信回線等が事故により停止した場合
        <br />
        (3) 火災、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
        <br />
        (4) その他、当社が停止又は中断を必要と判断した場合
        <br />
        2. 当社は、本条に基づき当社が行った措置に基づきユーザーに生じた損害について一切の責任を負いません。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第11条 権利帰属
      </Heading>
      <Text mb={4}>
        1. 当社ウェブサイト及び本サービスに関する所有権及び知的財産権は、全て当社又は当社にライセンスを許諾している者に帰属します。
        <br />
        2. 当社は、当社が提供する本コンテンツについて、ユーザーに対し、本サービスの利用を唯一の目的とする譲渡不能、再許諾不可、非独占的な利用権を許諾します。
        <br />
        3.
        ユーザーは、利用料、利用期間等の利用条件が別途定められた本コンテンツを利用する場合、かかる利用条件に従うものとします。本サービスの画面上で「購入」、「販売」、「レンタル」などの表示がされている場合であっても、当社がユーザーに対し提供する本コンテンツに関する所有権又は著作権、商標権その他の知的財産権はユーザーに移転せず、ユーザーには、前項の利用権のみが許諾されます。
        <br />
        4.
        本サービス又は公式サイト上に表示される人名、企業名、商品名、サービス名その他の名称（以下、総称して「商標等」といいます。）には、当社又は第三者が商標権その他の使用権を有する名称が含まれます。当社は、商標等が当社又は第三者の登録商標であることを、本サービス又は公式サイト上に記載することがありますが、この記載がない商標等であっても、当社又は第三者が使用権を有することがあります。
        <br />
        5.
        本サービスは、当社が所有又は管理しない第三者のウェブサイトへのリンクを含むことがあります。当社は、いかなる第三者のウェブサイトのコンテンツ、プライバシーポリシー又は運用について管理しておらず、これらにつき責任を負うものではありません。また、当社は、いかなる第三者のサイトのコンテンツを検閲又は編集することはなく、また、検閲又は編集することはできません。本サービスを利用することにより、ユーザーは、ユーザーによる第三者のウェブサイトの利用から生じる一切の責任から、当社を明示的に免除するものとします。
        <br />
        6.
        本規約は、ユーザーその他の第三者に対し、商標等を譲渡し、又は使用許諾するものとは解釈されないものとします。ユーザーは、商標等について商標登録の出願をしてはならないものとします。
        <br />
        7. ユーザーは、本サービスに関する知的財産権について、その登録を出願し、移転を申請し、又は行使してはならないものとします。
        <br />
        8. ユーザーは、本コンテンツを、本サービスが予定している利用態様を超えて利用（複製、送信、転載、改変などの行為を含みます。）してはなりません。
        <br />
        9.
        当社ウェブサイト又は本サービスにおいて、ユーザーが投稿その他送信を行った文章、画像、動画その他のデータについては、当社において、無償で自由に利用（複製、複写、改変、第三者への再許諾その他のあらゆる利用を含みます。）することができるものとし、ユーザーは、かかる利用について、著作者人格権を行使しないものとします。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第12条 ユーザーのアカウント停止等
      </Heading>
      <Text mb={4}>
        1.
        当社は、ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知又は催告することなく、当該ユーザーについて本サービスの利用の停止、本コンテンツの利用権の停止又は取消、アカウントの登録の削除、本利用契約の全部又は一部の解除その他当社が必要かつ適切と判断する措置を講じることができます。
        <br />
        (1) 本規約のいずれかの条項に違反した場合
        <br />
        (2)
        支払停止若しくは支払不能となり、又は破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始若しくはこれらに類する手続の開始の申立てがあった場合
        <br />
        (3) 第3条第3項各号に該当する場合
        <br />
        (4) その他、当社がユーザーとしての利用の継続を適当でないと判断した場合
        <br />
        2.
        前項各号のいずれかの事由に該当した場合、ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
        <br />
        3. 当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について一切の責任を負いません
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第13条 サービスの変更
      </Heading>
      <Text mb={4}>
        当社は、当社が必要と認める場合、ユーザーへ事前に通知することなく本サービスの変更・停止をすることができます。本サービスの変更・停止によってユーザーに生じた損害について、当社は一切の責任を負いません。ただし、本サービスを大幅に変更する場合には、当社は、事前に一定期間を設けた上でユーザーに告知するものとします。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第14条 サービスの廃止
      </Heading>
      <Text mb={4}>
        当社は、当社が必要と認める場合、ユーザーに対し、２か月前に事前に予告の上、資金決済法に定める範囲の返金手続をとることにより、本サービスの廃止をすることができます。法令に定める場合を除き、本サービスの廃止によってユーザーに生じた損害について、当社は一切の責任を負いません。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第15条 免責
      </Heading>
      <Text mb={4}>
        1.
        ユーザーは、本サービスを利用することが、ユーザーに適用のある法令、業界団体の内部規則等に違反するか否かを自己の責任と費用に基づいて調査するものとし、当社は、ユーザーによる本サービスの利用が、ユーザーに適用のある法令、業界団体の内部規則等に適合することを何ら保証するものではありません。
        <br />
        2.
        本サービス又は当社ウェブサイトに関連してユーザーと他のユーザー又は第三者との間において生じた取引、連絡、紛争等については、ユーザーの責任において処理及び解決するものとし、当社はかかる事項について一切責任を負いません。
        <br />
        3.
        当社は、本サービス（本コンテンツ及び本サービスで提供される広告システムを含みます。）に事実上又は法律上の瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。当社は、ユーザーに対して、かかる瑕疵を除去して本サービスを提供する義務を負いません。
        <br />
        4.
        本サービスから他のウェブサイト又はアプリへのリンク又は他のウェブサイト又はアプリから本サービスへのリンクが提供されている場合でも、当社は、本サービス及び当社ウェブサイト以外から得られる情報に関して如何なる理由に基づいても一切の責任を負わないものとします。
        <br />
        5.
        ユーザーが当社ドメイン等の拒否設定を行っている場合、当社からの連絡その他のサポートを受けられない場合があります。当社はかかるサポートが受けられないことによりユーザーに損害が生じた場合であっても、一切責任を負わないものとします。
        <br />
        6. 前五項の規定は、当社に故意又は重過失がある場合には適用されないものとします。
        <br />
        7.
        当社は、当社が損害賠償責任を負う場合であっても、当社に故意又は重過失がある場合を除き、請求原因の如何を問わず、ユーザーに生じた損害のうち特別な事情から生じた損害（当社又はユーザーが損害発生につき予見し、又は予見し得た場合を含みます。）、間接損害、逸失利益、及び不可抗力から生じた損害について一切の責任を負いません。
        <br />
        8.
        当社は、当社が損害賠償責任を負う場合であっても、当社に故意又は重過失がある場合を除き、ユーザーに生じた損害の賠償は、請求原因の如何を問わず、損害の事由が生じた時点から遡って過去1ヵ月の期間にユーザーから現実に受領した総額を上限とし、利用料が存在しない場合には金1,000円を上限とします。
        <br />
        9.
        ユーザーの行為により、第三者（本サービスに表示される広告・宣伝を行っている広告主等を含みます。）から当社が損害賠償等の請求をされた場合には、ユーザーの費用（弁護士費用）と責任で、これを解決するものとします。
        <br />
        10.
        当社が、前項に基づき、当該第三者に対して、損害賠償金を支払った場合には、ユーザーは、当社に対して当該損害賠償金を含む一切の費用（弁護士費用及び逸失利益を含む）を支払うものとします。
        <br />
        11.
        ユーザーが本サービスの利用に関連して当社に損害を与えた場合、会員の費用と責任において当社に対して損害の賠償（訴訟費用及び弁護士費用を含む）をするものとします。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第16条 本サービスの削除/権利の消失
      </Heading>
      <Text mb={4}>
        1.
        ユーザーは、本サービスの通信端末用アプリケーションをアンインストール、破棄又は消去（以下「アンインストール等」といいます。）及びアカウント削除することで、あるいはウェブサイトのみ利用している場合は当社所定の方法により本サービスの利用を中止することができます。本サービスからの退会を行いたいユーザーは、本アプリをアンインストールすることで、又はウェブサイトのみ利用している場合は当社所定の方法により退会できるものとします。また、ユーザーがメールアドレス等の個人情報の削除を希望する場合、本アプリのアンインストール前に、本アプリ記載の問い合わせ画面において、当社に対し連絡を行うか、又は、本アプリのアンインストール後あるいはウェブサイトのみ利用している場合は、プライバシーポリシー記載の方法にて削除の手続を行う必要があります。
        <br />
        2.
        ユーザーが本アプリを利用している場合、アンインストール等した時点又はユーザーがアカウントの登録の削除を希望した時点の早い時点で、本サービスにおける全ての権利（コイン、購入コンテンツ及びレンタルコンテンツに係る権利を含みます。）を放棄するものとし、当該時点において利用契約は終了するものとします。なお、ユーザーがアカウントの登録の削除を希望した場合は、個人情報や本サービスにおける全ての権利（コイン、購入コンテンツ及びレンタルコンテンツに係る権利を含みます。）に係る情報は復元されないものとします。
        <br />
        3.
        前項にかかわらず、当社の指定する方法によってユーザーが本サービスの利用を継続する場合には、本サービスにおける全ての権利（コイン、購入コンテンツ及びレンタルコンテンツに係る権利を含みます。）は放棄されず、アンインストール等及びアカウント削除によっても利用契約は終了しないものとします。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第17条 有効期間
      </Heading>
      <Text mb={4}>
        利用契約は、本サービスの提供期間中、ユーザーについて第3条に基づく利用が開始した日から本サービスの提供が終了した日又はユーザーが本サービスの通信端末用アプリケーションをアンインストール等した日（但し、第16条第3項の場合を除きます。）あるいはウェブサイトのみ利用している場合は、当社所定の方法により本サービスの利用を停止した日のいずれか早い日まで、当社とユーザーとの間で有効に存続するものとします。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第18条 個人情報及びその扱い
      </Heading>
      <Text mb={4}>
        1.
        当社は、ユーザーが本サービスを利用するにあたり、個人情報（個人情報の保護に関する法律第2条第1項の個人情報をいいます。）その他ユーザーに関する一定の情報（以下「個人情報等」といいます。）を取得することがあります。
        <br />
        2. 当社は、ユーザーから取得する個人情報等を、当社所定のプライバシーポリシーに従い取扱うものとします。
        <br />
        3.
        ユーザーは、個人情報等の当社による取得に同意しない場合であっても、本コンテンツの閲覧等の、個人情報等を利用して提供されるサービス以外の本サービスの利用ができます。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第19条 本規約等の変更
      </Heading>
      <Text mb={4}>
        当社は、以下の各号に定める場合において、本規約（個別規約を含みます。以下本条において同じ。）を変更できるものとします。本規約を変更する場合、当社は、当該変更に関して、変更内容及び当社が定めた効力発生日を一定期間前までにユーザーに対して周知するものとし、当該変更は、効力発生日の到来をもって、効力が発生するものとします。
        <br />
        (1) 変更がユーザーの一般の利益に適合する場合
        <br />
        (2) 変更が本規約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性、及びその内容その他の変更に係る事情に照らして合理的な場合
        <br />
        (3) 変更に関し、ユーザーから同意を得た場合
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第20条 連絡/通知
      </Heading>
      <Text mb={4}>
        1.
        本規約の変更に関する通知その他当社からユーザーに対する連絡又は通知は、当社の定める方法で行うものとし、当社は、ユーザーが登録している宛先に当該連絡又は通知を行えば足りるものとします。
        <br />
        2. ユーザーが、本サービスに関する問い合わせその他当社に対する連絡又は通知を行う場合、当社の所定の方法で行うものとします。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第21条 権利義務の譲渡等
      </Heading>
      <Text mb={4}>
        1.
        ユーザーは、当社の書面による事前の承諾がある場合を除き、利用契約上の地位又は本規約に基づく権利若しくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をしないものとします。
        <br />
        2.
        当社は、本サービスにかかる事業を他社に譲渡した場合、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びにユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第22条 分離可能性
      </Heading>
      <Text mb={4}>
        本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により
        無効又は執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効又は執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及びユーザーは、当該無効若しくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効若しくは執行不能な条項又は部分の趣旨並びに法律的及び経済的に同等の効果を確保できるように努めるものとします。
      </Text>
      <Heading as="h3" size="md" mb={2}>
        第23条 準拠法及び管轄裁判所
      </Heading>
      <Text mb={4}>本規約の準拠法は、日本法とし、本規約に起因し又は関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</Text>
      <Heading as="h3" size="md" mb={2}>
        第24条 協議解決
      </Heading>
      <Text mb={4}>
        当社及びユーザーは、本規約に定めのない事項又は本規約の解釈に疑義が生じた場合には、互いに信義誠実の原則に従って協議の上速やかに解決を図るものとします。
      </Text>
      2023年10月23日改定
      <br />
      2022年10月17日改定
      <br />
      2020年8月6日改定
      <br />
      2019年5月15日改定
      <br />
      2017年6月7日改定
      <br />
      2017年4月13日改定
      <br />
      2016年7月1日改定
      <br />
      2014年9月1日制定
      <br />
    </Box>
  )
}
