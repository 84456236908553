import { AppProvider } from '@!/providers'
import BookPurchaseHistoryList from '@!/User/BookPurchaseHistoryList/containers/BookPurchaseHistoryList'
import { useAppSettings } from '@!/Hooks/useAppSettings'

const BookPurchaseHistoryListApp = () => {
  const jotaiStore = useAppSettings({ isSaveLastUrl: false })

  return (
    <AppProvider jotaiStore={jotaiStore}>
      <BookPurchaseHistoryList />
    </AppProvider>
  )
}

export default BookPurchaseHistoryListApp
