import { Box, Stack, HStack, StackDivider, Center, Text } from '@chakra-ui/react'
import ReadBookLinkButton from './ReadBookLinkButton'
import { CoverImage } from '@!/Store/Common/components'

const ReadBookLinkCart = ({ books }) => {
  return (
    <>
      {books.length > 1 ? (
        <Stack borderY="1px" borderColor="divider.light" divider={<StackDivider />} py="10px">
          {books.map((book, index) => (
            <HStack key={book.distribution_id}>
              <Box w="45px" borderRight="1px" borderColor="divider.light">
                <Center bg="#F3F3F8" w="26px" h="26px">
                  {index + 1}
                </Center>
              </Box>
              <HStack w="100%" justifyContent="space-between">
                <HStack spacing={0}>
                  <CoverImage src={book.image_url} alt={book.content_name} width="44px" />
                  <Text pl="10px" fontSize="13px" textAlign="left">
                    {book.content_name}
                  </Text>
                </HStack>
                <Box w="100px" minW="100px">
                  <ReadBookLinkButton book={book} />
                </Box>
              </HStack>
            </HStack>
          ))}
        </Stack>
      ) : (
        <>
          <Box mb="18px">
            <Center>
              <CoverImage src={books[0].image_url} alt={books[0].content_name} width="109px" />
            </Center>
            <Text mt="15px" fontSize="sm">
              {books[0].content_name}
            </Text>
          </Box>
          <ReadBookLinkButton book={books[0]} />
        </>
      )}
    </>
  )
}

export default ReadBookLinkCart
