import PropTypes from 'prop-types'
import { Box, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'

const PurchaseDetails = ({ books }) => {
  return (
    <Box margin="5px 0" textAlign="center">
      <Table width="100%" border="1px solid #e0e0e0">
        <Thead>
          <Tr>
            <Th
              color="#222222"
              fontSize="13px"
              p="10px"
              borderBottom="1px solid #e0e0e0"
              backgroundColor="#f3f3f8"
              borderRight="1px solid #e0e0e0"
              fontWeight="bold"
              textAlign="center"
            >
              作品名
            </Th>
            <Th color="#222222" fontSize="13px" p="10px" borderBottom="1px solid #e0e0e0" backgroundColor="#f3f3f8" fontWeight="bold" textAlign="center">
              価格（税込）
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {books.map((book, index) => (
            <Tr key={index}>
              <Td p="10px" borderBottom="1px solid #e0e0e0" textAlign="center" borderRight="1px solid #e0e0e0">
                {book.full_name}
              </Td>
              <Td p="10px" borderBottom="1px solid #e0e0e0" textAlign="center">
                {book.price}円
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  )
}

PurchaseDetails.propTypes = {
  books: PropTypes.array.isRequired,
}

export default PurchaseDetails
