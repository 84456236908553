import { atom } from 'jotai'
import { purchaseItemsArrayAtom } from '@!/atoms/purchaseItemsAtom'

export const searchKeywordAtom = atom('')
searchKeywordAtom.debugLabel = 'searchKeyword'

export const searchPurchaseItemsAtom = atom((get) => {
  const keyword = get(searchKeywordAtom)
  if (!keyword) return []

  const purchaseItemsArray = get(purchaseItemsArrayAtom)
  return purchaseItemsArray.filter((book) => book.full_name.indexOf(keyword) !== -1)
})

export const itemsArrayAtom = atom((get) => {
  const keyword = get(searchKeywordAtom)
  const items = keyword ? get(searchPurchaseItemsAtom) : get(purchaseItemsArrayAtom)

  return items
})

export const purchaseItemsSortedByBuyAtom = atom((get) => {
  const itemsArray = get(itemsArrayAtom)

  return Array.from(itemsArray).sort((a, b) => {
    if (a.updated_at > b.updated_at) return -1
    if (a.updated_at < b.updated_at) return 1
    return 0
  })
})

export const purchaseItemsSortedByTitleAtom = atom((get) => {
  const itemsArray = get(itemsArrayAtom)

  return Array.from(itemsArray).sort((a, b) => {
    if (a.full_name > b.full_name) return 1
    if (a.full_name < b.full_name) return -1
    return 0
  })
})

export const purchaseItemsBookTitlesAtom = atom((get) => {
  const itemsArray = get(itemsArrayAtom)
  const bookTitleMap = {}
  itemsArray.forEach((purchaseItem) => {
    if (!purchaseItem.book_title_id) return

    const bookTitle = bookTitleMap[purchaseItem.book_title_id]
    if (bookTitle) {
      if (purchaseItem.update_at > bookTitle.updatedAt) {
        bookTitle.imageUrl = purchaseItem.image_url
        bookTitle.updatedAt = purchaseItem.updated_at
      }
    } else {
      bookTitleMap[purchaseItem.book_title_id] = {
        bookTitleId: purchaseItem.book_title_id,
        fullName: purchaseItem.book_title_name,
        authorName: purchaseItem.author_name,
        updatedAt: purchaseItem.updated_at,
        imageUrl: purchaseItem.image_url,
      }
    }
  })
  return Object.keys(bookTitleMap).map((key) => bookTitleMap[key])
})

export const bookTitlesSortedByBuyAtom = atom((get) => {
  const itemsArray = get(purchaseItemsBookTitlesAtom)

  return Array.from(itemsArray).sort((a, b) => {
    if (a.updatedAt > b.updatedAt) return -1
    if (a.updatedAt < b.updatedAt) return 1
    return 0
  })
})

export const bookTitlesSortedByTitleAtom = atom((get) => {
  const itemsArray = get(purchaseItemsBookTitlesAtom)

  return Array.from(itemsArray).sort((a, b) => {
    if (a.fullName > b.fullName) return 1
    if (a.fullName < b.fullName) return -1
    return 0
  })
})
