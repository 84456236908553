import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/react'

const CustomRadio = ({ isChecked }) => {
  return (
    <Box aria-checked={isChecked} display="flex" alignItems="center" border="none">
      <Box w="20px" h="20px" border="1px solid #e0e0e0" bg="#ffffff" position="relative" borderRadius="50%">
        {isChecked && <Box as="span" position="absolute" left="4px" top="4px" w="10px" h="10px" bg="#e94841" borderRadius="50%" />}
      </Box>
    </Box>
  )
}

CustomRadio.propTypes = {
  isChecked: PropTypes.bool.isRequired,
}

export default CustomRadio
