import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { Box, Heading, Table, Tbody, Th, Tr, Td, Text, HStack, UnorderedList, ListItem, Link } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

export const SpecifiedCommercialTransaction = () => {
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: '特定商取引法に関する表記' }])
  }, [])

  return (
    <Box p={6}>
      <Box pb={5}>
        <Heading size="sm">■運営会社</Heading>
        <Box borderBottom="2px" borderColor="divider.light" py={1} />
        <Table variant="unstyled" w="100%" fontSize="sm">
          <Tbody>
            <Tr>
              <Th textAlign="center" w="40%">
                販売業者
              </Th>
              <Td w="60%">株式会社Amazia</Td>
            </Tr>
            <Tr>
              <Th textAlign="center" w="40%">
                販売責任者
              </Th>
              <Td w="60%">佐久間亮輔</Td>
            </Tr>
            <Tr>
              <Th textAlign="center" w="40%">
                運営業者名
              </Th>
              <Td w="60%">株式会社Amazia</Td>
            </Tr>
            <Tr>
              <Th textAlign="center" w="40%">
                所在地
              </Th>
              <Td w="60%" fontSize="xs">
                〒150-0031
                <br />
                東京都渋谷区桜丘町1番2号
                <br />
                渋谷サクラステージセントラルビル13F
              </Td>
            </Tr>
            <Tr>
              <Th textAlign="center" w="40%">
                運営事業概要
              </Th>
              <Td w="60%">スマートフォンアプリの企画・開発</Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Box pb={5}>
        <Heading size="sm">■お問い合わせ</Heading>
        <Box borderBottom="2px" borderColor="divider.light" py={1} />
        <Box pt={2} fontSize="sm">
          お問い合わせ対応時間
          <br />
          平日10:00～17:00（土日祝日は除く）
          <br />
          ※上記以外のお時間では対応までにお時間を頂くことがございます。何卒ご了承ください。
          <br />
          ※お電話でのお問い合わせをご要望の場合はメールにその旨ご記載をお願いいたします。返信メールにて遅滞なく電話番号を通知させていただきます。なお、お電話いただく場合の通信料はお客様負担となります。
        </Box>
        <HStack pt={3} fontSize="sm">
          <Box fontWeight="bold">メールアドレス</Box>
          <Box ml={2}>info@support-mangabang.jp</Box>
        </HStack>
      </Box>
      <Box pb={5}>
        <Heading size="sm">■販売価格</Heading>
        <Box borderBottom="2px" borderColor="divider.light" py={1} />
        <Box p={2} fontSize="sm">
          <Text>
            マンガBANGブックスでコインを購入する場合はコインの購入画面に表示します。コンテンツを購入する場合は、事前に購入したコインまたはコンテンツの購入時に付与されたポイントを消費するか本ページの「お支払い方法とお支払時期」に記載するお支払方法によりコンテンツを購入するために必要なコイン数、ポイント数または販売価格を、各コンテンツの詳細ページおよび決済手続きの画面に表示します。
          </Text>
          <br />
          <Text>
            なお、マンガBANGブックスで購入したコインの有効期限は180日です。また、マンガBANGブックスで付与されたポイントの有効期限は30日です。コインとポイントは即時に消費され、使用後の取消や返還はできません。
          </Text>
        </Box>
      </Box>
      <Box pb={5}>
        <Heading size="sm">■販売価格以外にお客様にご負担いただく料金</Heading>
        <Box borderBottom="2px" borderColor="divider.light" py={1} />
        <Box pt={2} fontSize="sm">
          <UnorderedList>
            <ListItem>通信事業者の定めたインターネット利用料又はデータ通信料</ListItem>
          </UnorderedList>
        </Box>
      </Box>
      <Box pb={5}>
        <Heading size="sm">■お支払い方法とお支払時期</Heading>
        <Box borderBottom="2px" borderColor="divider.light" py={1} />
        <Box pt={2} fontSize="sm">
          <Text>
            コインの購入およびコンテンツの購入について以下のいずれかのお支払い方法をご利用いただけます。お支払時期は選択いただいたお支払方法ごとに定められた振替日となります。
          </Text>
          <UnorderedList pt={2}>
            <ListItem>クレジットカード</ListItem>
            <ListItem>携帯電話会社の料金回収サービス</ListItem>
            <ListItem>PayPay</ListItem>
            <ListItem>その他、購入に係る決済を代行する会社が提供するお支払い方法</ListItem>
          </UnorderedList>
          <Text fontSize="xs" mt={2}>
            ※現在、コンテンツの購入はクレジットカードのみの対応となっています。
          </Text>
        </Box>
      </Box>
      <Box pb={5}>
        <Heading size="sm">■商品の引渡時期（役務の提供時期）</Heading>
        <Box borderBottom="2px" borderColor="divider.light" py={1} />
        <Box pt={2} fontSize="sm">
          購入に関するページに特別な定めを置いている場合を除き、購入取引完了後、直ちにご利用いただけます。
        </Box>
      </Box>
      <Box pb={5}>
        <Heading size="sm">■返品の特約</Heading>
        <Box borderBottom="2px" borderColor="divider.light" py={1} />
        <Box pt={2} fontSize="sm">
          <Text>デジタルコンテンツの特性上、返品が不可能であるため、コイン、ポイント及びコンテンツの返品・返金には一切応じられません。</Text>
          <br />
          <Text>購入されたコンテンツに欠陥がある場合、あらためて欠陥のないコンテンツを提供いたします。この場合、返品・返金には一切応じられません。</Text>
        </Box>
      </Box>
      <Box pb={5}>
        <Heading size="sm">■ソフトウェアの動作環境</Heading>
        <Box borderBottom="2px" borderColor="divider.light" py={1} />
        <Box pt={2} fontSize="sm">
          マンガBANGブックスでは
          <Link href="/requirements" isExternal>
            別途ページ
            <ExternalLinkIcon mx="2px" />
          </Link>
          に記載する環境を推奨しています。
        </Box>
      </Box>
    </Box>
  )
}
