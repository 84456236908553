import { PropTypes } from 'prop-types'

import { HStack, Box, Text } from '@chakra-ui/react'

export const PriceLabel = ({ price, properPrice }) => {
  const discount = properPrice - price

  return (
    <HStack spacing={0}>
      {discount > 0 && (
        <Text fontSize="sm" as="del" color="label.primary" mr={1}>
          <Box as="span" fontWeight="semibold">
            {properPrice.toLocaleString()}
          </Box>
          <Box as="span" fontSize="xs">
            円
          </Box>
        </Text>
      )}
      <Text fontSize="xs" color={discount > 0 || price === 0 ? 'label.accent' : 'label.primary'}>
        <Box as="span" fontSize="sm" fontWeight="semibold">
          {price.toLocaleString()}
        </Box>
        <Box as="span">円</Box>
        {price !== 0 && '（税込）'}
      </Text>
    </HStack>
  )
}

PriceLabel.propTypes = {
  price: PropTypes.number.isRequired,
  properPrice: PropTypes.number.isRequired,
}
