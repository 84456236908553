// 非対応ブラウザが表示するため、chakra-uiなどを使用しない。
export const Fallback = ({ resetErrorBoundary }) => {
  return (
    <div role="alert" style={{ textAlign: 'center', padding: '32px 0' }}>
      <p style={{ fontSize: '16px', fontWeight: '600', margin: '32px 0' }}>読み込みエラーが発生しました。</p>
      <p style={{ fontSize: '13px' }}>ご利用端末が推奨環境ではない可能性がございます。</p>
      <p style={{ fontSize: '13px' }}>
        <a href="/requirements" style={{ color: '#2A8DE8', fontWeight: '600', textDecoration: 'underline' }}>
          推奨環境
        </a>
        をご確認ください。
      </p>
      <button
        onClick={resetErrorBoundary}
        style={{ fontSize: '14px', fontWeight: '600', width: '199px', height: '37px', boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.1)', margin: '32px 0' }}
      >
        ページを再度読み込む
      </button>
    </div>
  )
}
