import React from 'react'
import PropTypes from 'prop-types'
import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react'

export const BookPurchaseHistoryListTable = ({ histories }) => {
  const cellStyles = {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: '13px',
    lineHeight: '20px',
  }
  return (
    <Table>
      <Thead>
        <Tr>
          <Th {...cellStyles}>購入日</Th>
          <Th {...cellStyles}>作品名</Th>
          <Th {...cellStyles}>価格(税込)</Th>
        </Tr>
      </Thead>
      <Tbody>
        {histories.map((history, index) => (
          <Tr key={`book-purchase-history-${index}`}>
            <Td {...cellStyles}>{history.date.split('/').slice(1).join('/')}</Td>
            <Td {...cellStyles}>{history.full_name}</Td>
            <Td {...cellStyles}>{history.coin_count.toLocaleString()}円</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  )
}

BookPurchaseHistoryListTable.propTypes = {
  histories: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      full_name: PropTypes.string.isRequired,
      coin_count: PropTypes.number.isRequired,
    }),
  ).isRequired,
}
