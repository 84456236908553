import { useEffect, useMemo } from 'react'

import { Container, Heading, Stack } from '@chakra-ui/react'

import { openApp } from '@!/Util/OpenApp'
import { getDevice, getOS } from '@!/Util/Device'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { LoginForm, SocialLoginForm } from './'
import { LeadToRegistration } from '@!/User/Login/components'
import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { useResetByLogin } from '@!/Hooks'

export const LoginPage = () => {
  const [userHash] = useAtom(userHashAtom)
  const isLoggedIn = useMemo(() => !!userHash, [userHash])
  const device = useMemo(() => getDevice(), [])
  const os = useMemo(() => getOS(), [])
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)
  const executeResetData = useResetByLogin()

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'ログイン' }])
  }, [])

  // NOTE: ログイン済みの場合は元画面 or ルートにリダイレクト
  useEffect(() => {
    if (isLoggedIn) {
      executeResetData()
    }
  }, [isLoggedIn])

  const handleOpenApp = (e) => {
    e.preventDefault()
    openApp(os)
  }

  return (
    <Container p={5} bg="bg.primary" mt={2} boxSizing="border-box">
      <Stack spacing={0}>
        <Heading fontSize="md" textAlign="center" as="h2">
          ログイン
        </Heading>
        <LoginForm />
        <SocialLoginForm />
        <LeadToRegistration device={device} handleOpenApp={handleOpenApp} />
      </Stack>
    </Container>
  )
}
