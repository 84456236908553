import React from 'react'
import PropTypes from 'prop-types'
import { Box, Heading, Divider } from '@chakra-ui/react'
import CouponCard from '@!/User/Coupons/components/CouponCard'

const Coupons = ({ coupons }) => {
  const { used_or_expired: usedCoupons, unused: unusedCoupons } = coupons

  return (
    <>
      <Box p={4}>
        <Heading as="h1" fontSize="md" py={4}>
          使用できるクーポン
        </Heading>
        <Divider />
        {unusedCoupons.map((coupon, index) => (
          <Box key={index}>
            <CouponCard coupon={coupon} />
            {index < unusedCoupons.length - 1 && <Divider mt={4} />}
          </Box>
        ))}
      </Box>

      <Divider borderBottomWidth="12px" borderColor="bg.secondary" />

      <Box p={4}>
        <Heading as="h1" fontSize="md" py={4}>
          使用済み・期限切れクーポン
        </Heading>
        <Divider />
        {usedCoupons.map((coupon, index) => (
          <Box key={index}>
            <CouponCard coupon={coupon} />
            {index < usedCoupons.length - 1 && <Divider />}
          </Box>
        ))}
      </Box>
    </>
  )
}

Coupons.propTypes = {
  coupons: PropTypes.shape({
    used_or_expired: PropTypes.array.isRequired,
    unused: PropTypes.array.isRequired,
  }).isRequired,
}

export default Coupons
