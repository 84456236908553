import { AppProvider } from '@!/providers'
import BookPurchaseError from '../containers/BookPurchaseError'

const BookPurchaseErrorApp = (props) => {
  return (
    <AppProvider>
      <BookPurchaseError errorMessage={props.errorMessage} {...props} />
    </AppProvider>
  )
}

export default BookPurchaseErrorApp
