import axios from '../lib/axios'
import { getCsrfToken } from '../Util/PostRequest'

export const getCartItemsApi = async () => {
  try {
    return await axios.get('/api/v1/user/carts')
  } catch (error) {
    throw new Error(error.message)
  }
}

// params: {distribution_id: "DISTRIBUTION_ID", version: "VERSION"}
export const createCartItemApi = async (params) => {
  return await axios.post('/api/v1/user/carts', params, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
  })
}

// params: [{distribution_id: "DISTRIBUTION_ID", version: "VERSION"}]
export const createCartItemsApi = async (params) => {
  return await axios.post('/api/v1/user/carts/bulk_insert', params, {
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
  })
}

export const updateCartItemsApi = async () => {
  return await axios.post(
    '/api/v1/user/carts/update_items',
    {},
    {
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': getCsrfToken(),
      },
    },
  )
}

export const removeCartItemsApi = async (distributionIds) => {
  return await axios.delete('/api/v1/user/carts/bulk_destroy', {
    data: { distribution_ids: distributionIds },
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': getCsrfToken(),
    },
  })
}
