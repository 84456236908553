import { PropTypes } from 'prop-types'

import { Stack } from '@chakra-ui/react'

import { SectionTitle } from '@!/Store/Common/components/'
import { ScrollableContent, BulkPurchaseTitleItem } from '@!/Store/Home/components'

/* 横スクロール型のまとめ買い作品リスト */
export const ScrollableBulkPurchaseTitleList = ({ titles, tabName }) => {
  const sectionTitle = 'まとめ買い作品'
  return (
    <Stack spacing={0} mx={4} my={3}>
      <SectionTitle title={sectionTitle} url={`/store/bulk_purchase_titles`} />
      <ScrollableContent>
        <Stack direction="row" spacing={0}>
          {titles.map((title, index) => (
            <BulkPurchaseTitleItem key={`list-${index}-${title.title_id}`} title={title} sectionTitle={`${tabName}_${sectionTitle}`} />
          ))}
        </Stack>
      </ScrollableContent>
    </Stack>
  )
}

ScrollableBulkPurchaseTitleList.propTypes = {
  titles: PropTypes.array.isRequired,
  tabName: PropTypes.string.isRequired,
}
