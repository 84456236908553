import { useEffect, useMemo } from 'react'
import { useAtom } from 'jotai'

import { paymentListAtom, listIndexAtom } from '@!/atoms/paymentAtom'

export const useValidListItemCheck = () => {
  const [listIndex] = useAtom(listIndexAtom)
  const [paymentList] = useAtom(paymentListAtom)
  const isInValidPaymentListItem = useMemo(() => {
    return !!(listIndex < 0 || listIndex >= paymentList.length)
  }, [paymentList, listIndex])

  useEffect(() => {
    if (isInValidPaymentListItem) {
      location.href = '/user/payment'
    }
  }, [listIndex, paymentList])

  return [isInValidPaymentListItem]
}
