import { useToast } from '@chakra-ui/react'

export const useNavigationHandler = () => {
  const toast = useToast()

  const redirectToLoginWithToast = () => {
    toast({
      title: 'Notification',
      description: '作品を購入するにはログインが必要です。',
      status: 'info',
      duration: 2000,
      isClosable: true,
      onCloseComplete: () => {
        window.location.href = '/user/login'
      },
    })
  }

  return { redirectToLoginWithToast }
}
