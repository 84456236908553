import { IconButton } from '@chakra-ui/react'

import { LoginIcon } from '@!/components/Icon'

export const Login = () => {
  return (
    <IconButton
      aria-label="ログイン"
      color="label.primary"
      icon={<LoginIcon width="38px" height="42px" />}
      variant="link"
      as="a"
      href="/user/login"
      border="none"
    />
  )
}
