import { PropTypes } from 'prop-types'

import { LinkBox, LinkOverlay, VStack } from '@chakra-ui/react'

import { CoverImage } from '@!/Store/Common/components/CoverImage'

export const Book = ({ title, distributionId, imageUrl, sectionTitle, w }) => {
  return (
    <LinkBox w={w}>
      <VStack>
        <CoverImage src={imageUrl} alt={title} objectFit="contain" width={w} />
        <LinkOverlay
          href={`/store/books/${distributionId}`}
          {...(sectionTitle && { 'data-gtm-click': sectionTitle })}
          noOfLines={1}
          fontSize="sm"
          fontWeight="bold"
          w={w}
        >
          {title}
        </LinkOverlay>
      </VStack>
    </LinkBox>
  )
}

Book.propType = {
  title: PropTypes.string.isRequired,
  distributionId: PropTypes.string.isRequired,
  imageUrl: PropTypes.string,
  sectionTitle: PropTypes.string,
  w: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}
