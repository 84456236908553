import { useMemo, useState, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useAtom } from 'jotai'

import { Stack, StackDivider } from '@chakra-ui/react'

import { buildUrlString } from '@!/Util/buildUrlString'
import { ListItem } from '@!/Store/FeatureList/components'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { useCheckPurchasedBooks } from '@!/Hooks'
import { CircularProgressModal } from '@!/components/Modal'

export const BookListItem = ({ book, isPurchased }) => {
  const linkUrl = buildUrlString(`/store/books/${book.mddc_id}`)
  return (
    <ListItem
      title={book.full_name}
      imageUrl={book.image_url}
      authorName={book.author_name}
      publisherName={book.publisher_name}
      price={book.price}
      properPrice={book.proper_price}
      sampleBookId={book.sample_book_id}
      distributionId={book.mddc_id}
      rating={book.rating}
      linkUrl={linkUrl}
      isPurchased={isPurchased}
    />
  )
}

export const BookList = ({ books }) => {
  const [userHash] = useAtom(userHashAtom)
  const distributionIds = useMemo(() => books.map((book) => book.mddc_id), [books])
  const { data: purchasedBookIds, isSuccess: isSuccess, isLoading: isLoading } = useCheckPurchasedBooks(userHash, distributionIds)
  const [listItems, setListItems] = useState(books)

  useEffect(() => {
    if (!isSuccess) return

    // mddc_idがpurchasedBookIdsに存在するかどうかをpurchasedに保存してlistItemsを更新
    setListItems(
      books.map((book) => {
        book.purchased = purchasedBookIds.some((purchasedBookId) => purchasedBookId == book.mddc_id)
        return book
      }),
    )
  }, [isSuccess])

  const canRender = useMemo(() => (!!userHash && isSuccess) || !userHash, [userHash, isSuccess])

  return (
    <Stack divider={<StackDivider />} spacing={0}>
      <CircularProgressModal isOpen={isLoading} />
      {canRender && listItems.map((book, index) => <BookListItem key={`book-list-item-${index}`} book={book} isPurchased={book.purchased} />)}
    </Stack>
  )
}

BookList.propType = {
  books: PropTypes.array.isRequired,
}
