import { Container } from '@chakra-ui/react'

/* コンテンツ領域のレイアウト調整用コンテナ */
export const ContentContainer = ({ children, ...props }) => {
  return (
    <Container maxW="container.md" p={0} {...props}>
      {children}
    </Container>
  )
}
