import { atom } from 'jotai'

export const userHashAtom = atom('')
userHashAtom.debugLabel = 'userHash'

const initUserCoins = { coin_count: null, bonus_coin_count: null, unusable_coin_count: null, unusable_bonus_coin_count: null }
export const userCoinsAtom = atom(initUserCoins)
userCoinsAtom.debugLabel = 'userCoins'

export const userPointsAtom = atom(0)
userPointsAtom.debugLabel = 'userPoints'

export const userUsableCoinsAtom = atom((get) => {
  const userCoins = get(userCoinsAtom)
  if (!userCoins?.coin_count && !userCoins?.bonus_coin_count) return 0
  return (Number(userCoins.coin_count) || 0) + (Number(userCoins.bonus_coin_count) || 0)
})

export const resetUserAtom = atom(null, (get, set) => {
  set(userHashAtom, '')
  set(userCoinsAtom, initUserCoins)
  set(userPointsAtom, 0)
})

export const userCoinsIsLoadingAtom = atom((get) => {
  const userCoins = get(userCoinsAtom)
  return !!(
    userCoins &&
    userCoins.coin_count === null &&
    userCoins.bonus_coin_count === null &&
    (userCoins.unusable_coin_count === null) & (userCoins.unusable_bonus_coin_count === null)
  )
})
