import { createIcon } from '@chakra-ui/icons'

export const LineIcon = createIcon({
  displayName: 'LineIcon',
  viewBox: '0 0 48 48',
  fill: 'none',
  path: (
    <>
      <path fill="#00C300" d="M12.5 42H35.5C39.09 42 42 39.09 42 35.5V12.5C42 8.91 39.09 6 35.5 6H12.5C8.91 6 6 8.91 6 12.5V35.5C6 39.09 8.91 42 12.5 42Z" />
      <path
        fill="white"
        d="M37.113 22.417C37.113 16.552 31.233 11.78 24.006 11.78C16.779 11.78 10.898 16.552 10.898 22.417C10.898 27.675 15.561 32.079 21.86 32.912C22.287 33.004 22.868 33.194 23.015 33.558C23.147 33.889 23.101 34.408 23.057 34.743C23.057 34.743 22.904 35.668 22.87 35.865C22.813 36.196 22.607 37.161 24.005 36.572C25.404 35.983 31.553 32.127 34.303 28.961H34.302C36.203 26.879 37.113 24.764 37.113 22.417ZM18.875 25.907H16.271C15.892 25.907 15.584 25.599 15.584 25.219V20.01C15.584 19.631 15.892 19.323 16.271 19.323C16.65 19.323 16.958 19.631 16.958 20.01V24.531H18.875C19.254 24.531 19.562 24.839 19.562 25.218C19.562 25.598 19.254 25.907 18.875 25.907ZM21.568 25.219C21.568 25.598 21.26 25.907 20.881 25.907C20.502 25.907 20.194 25.599 20.194 25.219V20.01C20.194 19.631 20.502 19.323 20.881 19.323C21.26 19.323 21.568 19.631 21.568 20.01V25.219ZM27.838 25.219C27.838 25.516 27.65 25.778 27.368 25.871C27.297 25.895 27.223 25.907 27.15 25.907C26.935 25.907 26.73 25.804 26.601 25.632L23.932 21.997V25.219C23.932 25.598 23.624 25.907 23.244 25.907C22.865 25.907 22.556 25.599 22.556 25.219V20.01C22.556 19.714 22.745 19.452 23.026 19.358C23.097 19.334 23.17 19.323 23.244 19.323C23.458 19.323 23.664 19.426 23.793 19.598L26.463 23.233V20.01C26.463 19.631 26.772 19.323 27.151 19.323C27.53 19.323 27.838 19.631 27.838 20.01V25.219ZM32.052 21.927C32.431 21.927 32.74 22.235 32.74 22.615C32.74 22.994 32.432 23.302 32.052 23.302H30.135V24.532H32.052C32.431 24.532 32.74 24.84 32.74 25.219C32.74 25.598 32.431 25.907 32.052 25.907H29.448C29.07 25.907 28.761 25.599 28.761 25.219V22.616V22.614V20.013C28.761 20.012 28.761 20.012 28.761 20.011C28.761 19.632 29.069 19.324 29.448 19.324H32.052C32.431 19.324 32.74 19.632 32.74 20.011C32.74 20.39 32.432 20.698 32.052 20.698H30.135V21.928L32.052 21.927Z"
      />
    </>
  ),
})
