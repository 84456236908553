export function numberWithDelimiter(num) {
  let str = new String(num)
  while (str != (str = str.replace(/^(-?\d+)(\d{3})/, '$1,$2')));
  return str
}

export function checkEmailAddress(str) {
  if (str.match(/.+@.+\..+/)) {
    return true
  } else {
    return false
  }
}

export function encodeURIPath(str, isReplacePeriod = true) {
  if (!str) return null

  if (isReplacePeriod) {
    // periodを半角スペースに置き換え
    str = str.replace(/\./g, ' ')
  }
  return encodeURIComponent(str)
}

export function checkPassword(str) {
  return str.match(/^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[!-~]{8,100}$/i)
}
