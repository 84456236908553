import { AppProvider } from '@!/providers'
import Errors from '@!/Errors/containers/Errors'
import { useAppSettings } from '@!/Hooks/useAppSettings'

const ErrorsApp = (props) => {
  const jotaiStore = useAppSettings({ isSaveLastUrl: false })

  return (
    <AppProvider jotaiStore={jotaiStore}>
      <Errors status={props.status} />
    </AppProvider>
  )
}

export default ErrorsApp
