import { useEffect } from 'react'
import { StyleSheet, css } from 'aphrodite'

import StopPiarcyJpg from '@images/stop_piracy.jpg'
import AbjMarkPng from '@images/abj_mark.png'
import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'

export const StopPiracy = () => {
  const styles = StyleSheet.create({
    main_container: {
      padding: '15px',
      maxWidth: '800px',
      margin: '0 auto',
    },
    top_image: {
      width: '100%',
      maxWidth: '800px',
      display: 'block',
      margin: '0 auto',
    },
    top_text_container: {
      fontSize: '18px',
      textAlign: 'center',
      marginBottom: '25px',
      lineHeight: '28px',
      fontWeight: 'normal',
    },
    top_text_emphasis: {
      color: '#e94841',
      fontWeight: 'bold',
    },
    middle_text_container: {
      background: '#f4f4f4',
      padding: '15px',
      marginBottom: '30px',
    },
    abj_mark_title: {
      fontWeight: 'normal',
      fontSize: '18px',
      borderBottom: '1px solid #000',
      textAlign: 'center',
      marginBottom: 0,
    },
    abj_mark_container: {
      textAlign: 'center',
      marginBottom: '30px',
    },
    abj_mark_image: {
      width: '50%',
      maxWidth: '300px',
      display: 'block',
      margin: '0 auto',
    },
    abj_mark_text: {
      fontSize: '16px',
    },
    inquiry_button: {
      display: 'block',
      padding: '10px',
      background: '#2e3a46',
      color: '#fff',
      borderRadius: '4px',
      marginTop: '20px',
      textDecoration: 'none',
    },
  })

  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: '海賊版に関する取り組み' }])
  }, [])

  return (
    <div>
      <img src={`${window.asset_host}${StopPiarcyJpg}`} className={css(styles.top_image)} alt="STOP!海賊版" />
      <div className={css(styles.main_container)}>
        <h1 className={css(styles.top_text_container)}>
          マンガBANGブックスは、出版社・著作権者から
          <br />
          <span className={css(styles.top_text_emphasis)}>正規に使用許諾を得ている</span>
          <br />
          電子書店・電子書籍配信サービスです。
        </h1>
        <p className={css(styles.middle_text_container)}>
          海賊版サイトでの閲覧・購読は違法行為を助長するだけであり、作者、出版社、書店にその収益は一切還元されません。読者の皆様の閲覧・購読の収益が正しく還元されることが、次の新しいコンテンツが生み出すことにつながります。正規版配信サービスで閲覧・購読をお願いします。
        </p>

        <h2 className={css(styles.abj_mark_title)}>
          <i className="fa fa-check" />
          正規配信サイトマークを取得
        </h2>
        <div className={css(styles.abj_mark_container)}>
          <img src={`${window.asset_host}${AbjMarkPng}`} className={css(styles.abj_mark_image)} alt="ABJマーク" />
          <span className={css(styles.abj_mark_text)}>
            ＡＢＪマークは、この電子書店・電子書籍配信サービスが、著作権者からコンテンツ使用許諾を得た正規版配信サービスであることを示す登録商標（登録番号
            第６０９１７１３号）です。ABJマークの詳細、ABJマークを掲示しているサービスの一覧はこちら
            <a href="https://aebs.or.jp/" target="_blank" rel="noopener noreferrer">
              https://aebs.or.jp/
            </a>
          </span>
        </div>
        <h2 className={css(styles.abj_mark_title)}>著作権者の皆様へ</h2>
        <div className={css(styles.abj_mark_container)} style={{ paddingTop: '20px' }}>
          <span className={css(styles.abj_mark_text)}>
            当社は全ての配信作品について出版社様、取次会社様等との間で許諾契約を締結し、配信させて頂いております。
            ご自身の作品の配信許諾契約についてご確認なさりたい場合、お手数ですが、下記にご連絡を頂けますようお願いいたします。
          </span>

          <a href="mailto:info@support-mangabang.jp?subject=配信許諾契約について" className={css(styles.inquiry_button)}>
            問い合わせ
          </a>
        </div>
      </div>
    </div>
  )
}
