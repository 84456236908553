import App from '@!/App/containers/App'
import { BaseProvider } from '@!/providers'

export const AppProvider = (props) => {
  return (
    <BaseProvider {...props}>
      <App {...props}>{props.children}</App>
    </BaseProvider>
  )
}
