import { useEffect } from 'react'
import { Box, Heading, VStack, Link } from '@chakra-ui/react'
import { useAtom } from 'jotai'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'
import { HelpAccordion } from '@!/StaticPage/components'

export const Help = () => {
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'ヘルプ' }])
  }, [])

  return (
    <Box>
      <Heading as="h1" pt={8} mb={3} fontSize="2xl" lineHeight={9} textAlign="center" fontWeight="600">
        ヘルプ
      </Heading>

      <VStack>
        <HelpAccordion
          category="初めての方へ"
          qAndAList={[
            {
              question: 'マンガBANGブックスとはどんなサービスですか？マンガBANG（アプリ）との違いは？',
              answer: (
                <>
                  マンガBANGブックスは、人気マンガがいつでもお得に読むことができるWEB電子書籍サイトです。
                  <br />
                  42万作品以上がいつでも高ポイント還元！全作品対象なので、読みたかった作品もお得にお楽しみいただけます。
                  <br />
                  また、国内最大級のマンガアプリ「マンガBANG！」の姉妹サービスなので安心してご利用いただけます。
                  <br />
                  <br />
                  ■ マンガBANG（アプリ）との違いは？
                  <br />
                  マンガBANG（アプリ）は累計DL3,000万を突破した無料マンガアプリです。
                  <br />
                  アプリ限定で毎月新作の無料漫画が追加されるのでお見逃しなく！
                  <br />
                  <br />
                  同じアカウントを使用することで、マンガBANGブックス（WEB）とマンガBANG（アプリ）の本棚を共有する事もできます。
                </>
              ),
            },
            {
              question: '安全性について',
              answer: (
                <>
                  東証に上場している株式会社Amaziaが運営するサービスなので安心してご利用いただけます。
                  <br />
                  また、マンガBANGブックスは、出版社・著作権者から正規に使用許諾を得ている電子書店・電子書籍配信サービスです。
                  <br />
                  <br />
                  詳しくは海賊版に関する取り組みをご確認ください。
                </>
              ),
            },
            {
              question: 'はじめてガイド',
              answer: (
                <>
                  <Link href="/guide">初めての方へ</Link>をご確認ください
                </>
              ),
            },
          ]}
        />
        <HelpAccordion
          category="会員登録・退会"
          qAndAList={[
            {
              question: '会員登録方法を教えて下さい',
              answer: '会員登録ページからメールアドレス又はSNSアカウントで登録することができます。',
            },
            {
              question: 'マンガBANG（アプリ）を利用していますが、マンガBANGブックスでも会員登録が必要ですか？',
              answer: (
                <>
                  マンガBANG（アプリ）で会員の方は、マンガBANG（アプリ）のアカウントをご利用いただけます。
                  <br />
                  マンガBANG（アプリ）で会員登録がお済みでない方は、マンガBANG（アプリ）で事前に会員登録をしてから、マンガBANGブックスでログインしてください。
                </>
              ),
            },
            {
              question: '会員登録のメールが届かない',
              answer: 'ドメイン指定受信の方は、@support-mangabang.jp からの受信設定をしてください。',
            },
            {
              question: '退会はどこからできますか?',
              answer: (
                <>
                  <Box as="span" fontWeight="bold">
                    アカウント情報ページ
                  </Box>
                  から退会することができます。
                  <br />
                  <br />
                  ログイン＞メニュー＞アカウント情報
                  <br />
                  <br />
                  下記の注意点を読んでから退会してください。
                  <br />
                  <br />
                  保有しているコイン、ポイントが使えなくなります。
                  <br />
                  本棚にある作品が閲覧できなくなります。
                  <br />
                  マンガBANG（アプリ）をご利用の方は、マンガBANG（アプリ）のコインや本棚も利用できなくなります。
                  <br />
                  いかなる場合でもデータは復活させる事ができません。予めご了承ください。
                </>
              ),
            },
          ]}
        />
        <HelpAccordion
          category="ログイン・パスワード"
          qAndAList={[
            {
              question: 'パスワードを忘れてしまった',
              answer: (
                <>
                  パスワードリセットページから登録されているメールアドレスを記入して送信ボタンを押して下さい。
                  <br />
                  記入していただいたメールアドレスに新しいパスワードを記載したメールをお送りします。
                  <br />
                  <br />
                  ログイン＞パスワードをお忘れの方はこちら
                </>
              ),
            },
            {
              question: 'SNSアカウントでログインができない',
              answer: 'お問い合わせフォームからご連絡をお願いします。',
            },
            {
              question: 'ログアウトしたい',
              answer: (
                <>
                  アカウント情報ページからログアウトしてください。
                  <br />
                  <br />
                  ログイン＞メニュー＞アカウント情報
                </>
              ),
            },
          ]}
        />
        <HelpAccordion
          category="ポイント・ポイント還元"
          qAndAList={[
            {
              question: 'ポイントについて',
              answer: '作品を購入した際に還元されるポイントです。1pt=1円（税込み）で作品を購入する際にご利用いただけます。',
            },
            {
              question: 'ポイントの使い方を教えて下さい',
              answer: (
                <>
                  マンガを購入する際に、1pt=1円（税込み）でご利用いただけます。
                  <br />
                  <br />
                  マンガBANG（アプリ）と連携すると、マンガBANG（アプリ）内の下記作品・コーナーでも1pt=1コインとしてご利用いただけます。
                  <br />
                  ・メダル・チケット作品
                  <br />
                  ・マンガBANGストア
                  <br />
                  ※マンガBANG（アプリ）でポイントをお使いいただくためには、事前にアプリで会員登録の上、マンガBANGブックス（WEB）でログインが必要です。
                </>
              ),
            },
            {
              question: '還元されるポイントの計算方法を教えて下さい',
              answer: (
                <>
                  購入する作品の税抜金額に還元率を掛けて計算します。
                  <br />
                  ポイントを利用して購入する場合は、税込価格からポイント利用分を差し引いて計算します。
                  <br />
                  還元されるポイントは購入手続きページに表示されます。
                  <br />
                  ※ポイント還元率は、当社の都合により予告なく変更される場合がありますのでご容赦ください。
                </>
              ),
            },
            {
              question: 'ポイント還元の対象作品について',
              answer: 'ポイント還元対象は、作品ページに「ポイント還元対象作品」と表示されています。',
            },
            {
              question: 'ポイントはいつ付与されますか？',
              answer: '決済が完了した時にポイントが付与されます。',
            },
            {
              question: 'ポイントには有効期限がありますか？',
              answer: '有効期限は購入した翌日から30日間です。',
            },
            {
              question: '作品を購入したのに、ポイントが付与されません',
              answer: (
                <>
                  お問い合わせフォームからご連絡をお願いします。
                  <br />
                  購入日時、購入した作品と巻数をお伝えください。
                </>
              ),
            },
            {
              question: '現在の保有ポイントはどこで確認できますか？',
              answer: 'ログイン後のページ最上部またはメニュー内で確認することができます。',
            },
          ]}
        />
        <HelpAccordion
          category="コイン"
          qAndAList={[
            {
              question: '利用できる決済方法を教えて下さい',
              answer: 'クレジットカード決済、キャリア決済、PayPayがご利用いただけます。',
            },
            {
              question: '現在の保有コインはどこで確認できますか？',
              answer: (
                <>
                  メニューからご確認いただけます。
                  <br />
                  <br />
                  ログイン＞メニュー
                </>
              ),
            },
            {
              question: 'コインには有効期限がありますか？',
              answer: 'マンガBANGブックス（WEB）で購入したコインの有効期限は180日間です。',
            },
            {
              question: '購入したのに、コインが付与されません',
              answer: (
                <>
                  お問い合わせフォームからご連絡をお願いします。
                  <br />
                  購入日時、購入したコイン数、決済手段をお伝えください。調査して対応させていただきます。
                </>
              ),
            },
            {
              question: 'コイン購入時に2度決済が行われてしまいました',
              answer: (
                <>
                  お問い合わせフォームからご連絡をお願いします。
                  <br />
                  購入日時、購入したコイン数、決済手段をお伝えください。調査して対応させていただきます。
                </>
              ),
            },
            {
              question: '間違って購入したコインは返金できますか？',
              answer: '返金は致しかねます。ご了承下さい。',
            },
          ]}
        />
        <HelpAccordion
          category="作品購入について"
          qAndAList={[
            {
              question: '1度に何冊まで購入できますか？',
              answer: '1度に200冊まで購入いただけます。',
            },
            {
              question: '利用できる決済方法を教えて下さい',
              answer: (
                <>
                  クレジットカード決済またはコイン決済をご利用いただけます。
                  <br />
                  <br />
                  ※コイン決済について
                  <br />
                  コインの購入にはクレジットカード決済、キャリア決済、PayPayがご利用いただけます。
                </>
              ),
            },
            {
              question: '作品を開く事が出来ません',
              answer: (
                <>
                  お問い合わせフォームからご連絡をお願いします。
                  <br />
                  開く事ができない作品名と巻数をお伝えください。
                </>
              ),
            },
            {
              question: 'セーフサーチとは何ですか？',
              answer: (
                <>
                  セーフサーチはTOPページや検索結果上の表示作品を調整することができる機能です。
                  <br />
                  ONの場合、TL/BL/オトナジャンルの作品はTOPページや検索結果に表示されなくなります。
                  <br />
                  <br />
                  初期設定はONになっているため、作品を表示したい場合はメニューからOFFに設定をお願いいたします。
                  <br />
                  ログイン＞メニュー
                </>
              ),
            },
          ]}
        />
        <HelpAccordion
          category="クーポン"
          qAndAList={[
            {
              question: 'クーポンとは何ですか？',
              answer: '購入手続き画面で利用すると、お支払金額が割引されます。',
            },
            {
              question: 'クーポンの確認方法を教えてください',
              answer: (
                <>
                  クーポン確認ページでご確認いただけます。
                  <br />
                  <br />
                  ログイン＞メニュー＞クーポン
                  <br />
                  ※利用期間を過ぎると使用できませんのでご注意下さい。
                </>
              ),
            },
            {
              question: 'クーポンの使い方を教えてください',
              answer: (
                <>
                  購入手続き画面で利用したいクーポンを選択してください。
                  <br />
                  合計金額（税込み）が割引適用後の価格に再計算されます。
                  <br />
                  ※ご購入時にクーポンをご利用にならなかった場合、割引などが適用されませんのでご注意ください。
                  <br />
                  <br />
                  ステップ1：購入したい作品を探す
                  <br />
                  クーポンを使って購入したい作品を探します。
                  <br />
                  複数ある場合はカートをご利用ください。
                  <br />
                  <br />
                  ステップ2：利用したいクーポンを選ぶ
                  <br />
                  購入手続き画面の「クーポン」欄で利用したいクーポンを選択すると、合計金額（税込み）が割引適用後の価格に再計算されます。
                </>
              ),
            },
            {
              question: 'クーポンの内容について',
              answer: (
                <>
                  ■クーポン内容
                  <br />
                  クーポンごとに定められた割引率があります。
                  <br />
                  <br />
                  ■利用条件
                  <br />
                  クーポンを利用する場合に必要な条件です。
                  <br />
                  「WEBでの初回購入限定」の場合、マンガBANGブックス（WEB）で今まで購入したことがないのが条件です。マンガBANG（アプリ）をご利用いただいているユーザー様でも、マンガBANGブックス（WEB）での購入履歴がない方はお使いいただけます。
                  <br />
                  <br />
                  ■値引上限
                  <br />
                  「1,000円」と上限金額が設定されているクーポンは、合計金額の1,000円までご利用いただけます。
                  <br />
                  ※カートの中身が上限額を越えている場合、上限額に収まる範囲で合計金額から割引が適用されます。
                  <br />
                  <br />
                  ■利用可能回数
                  <br />
                  クーポンが使用できる回数です。
                  <br />
                  <br />
                  ■対象作品
                  <br />
                  新刊、既刊にもお使いいただけます。
                  <br />
                  詳細はクーポン確認ページにてご確認ください。
                  <br />
                  ログイン＞メニュー＞クーポン
                </>
              ),
            },
            {
              question: 'クーポンの注意点',
              answer: (
                <>
                  ・1度の購入でご利用いただけるクーポンは1つまでとなります。
                  <br />
                  ・値引きキャンペーン中の作品は、値引き後の価格にクーポンの割引が適用されます。
                  <br />
                  ・クーポンの割引は合計金額（税込み）に対して適用されます。
                  <br />
                  ・還元キャンペーン中の作品は、クーポンの割引適用後の合計金額（税抜き）から還元が適用されます。
                </>
              ),
            },
          ]}
        />
      </VStack>
    </Box>
  )
}
