import PropTypes from 'prop-types'
import { Box, Input, Text, Flex } from '@chakra-ui/react'
import CustomRadio from '@!/Store/Common/components/CustomRadio'

const PointUsageForm = ({ points, pointValue, setPointValue, spendPoints, setSpendPoints, currentPrice, isDisabled }) => {
  const handlePointUsageChange = (value) => {
    // ポイントを利用するを選択済みで、もう一度選択された場合は入力値を初期化しない
    if (spendPoints && value !== 'spend') {
      setPointValue('')
    }
    setSpendPoints(value === 'spend')
  }

  const handlePointInputChange = (e) => {
    const inputValue = e.target.value
    let updatedValue = parseInt(inputValue, 10)

    if (isNaN(updatedValue) || updatedValue <= 0) {
      updatedValue = ''
    } else if (updatedValue > points || updatedValue > currentPrice) {
      updatedValue = Math.min(points, currentPrice)
    }

    setPointValue(updatedValue.toString())
  }

  return (
    <>
      <Box>
        保有ポイント：
        <Box as="span" color="label.accent" fontWeight="bold">
          {points}pt
        </Box>
      </Box>

      <Flex mt={4} onClick={() => !isDisabled && handlePointUsageChange('not_use')} opacity={isDisabled ? 0.5 : 1}>
        <CustomRadio isChecked={!spendPoints} />
        <Text mx={2}>ポイントを利用しない</Text>
      </Flex>

      <Flex mt={4} alignItems="center" onClick={() => !isDisabled && handlePointUsageChange('spend')} opacity={isDisabled ? 0.5 : 1}>
        <CustomRadio isChecked={spendPoints} />
        <Input type="text" value={pointValue} onChange={handlePointInputChange} width="89px" isDisabled={isDisabled} mx={2} />
        <Text>pt 利用する</Text>
      </Flex>
    </>
  )
}

PointUsageForm.propTypes = {
  points: PropTypes.number.isRequired,
  pointValue: PropTypes.string.isRequired,
  setPointValue: PropTypes.func.isRequired,
  spendPoints: PropTypes.bool.isRequired,
  setSpendPoints: PropTypes.func.isRequired,
  currentPrice: PropTypes.number.isRequired,
  isDisabled: PropTypes.bool.isRequired,
}

export default PointUsageForm
