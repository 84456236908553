import { Button, Image } from '@chakra-ui/react'

import { buildAssetUrlString } from '@!/Util'

import TimerSvg from '@images/timer.svg'

export const PointExpirationButton = () => {
  const icon = <Image htmlHeight={20} htmlWidth={20} src={buildAssetUrlString(TimerSvg)} />

  return (
    <Button borderRadius={20} fontSize={'1em'} leftIcon={icon} color={'#FFF'} bg={'#222'} _hover={{ bg: '#666' }} as="a" href="/user/point_histories">
      有効期限
    </Button>
  )
}
