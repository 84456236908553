import { AppProvider } from '@!/providers'
import StaticPage from '@!/StaticPage/containers/StaticPage'
import { useAppSettings } from '@!/Hooks/useAppSettings'

const StaticPageApp = (props) => {
  const jotaiStore = useAppSettings({ isSaveLastUrl: false })

  return (
    <AppProvider jotaiStore={jotaiStore}>
      <StaticPage pageType={props.page_type} params={props.params} />
    </AppProvider>
  )
}

export default StaticPageApp
