import PropTypes from 'prop-types'
import { AddCartIcon } from '@!/components/Icon'
import { NormalButton } from '@!/components/Button'
import { ConfirmPopupModal } from '@!/components/Modal'
import { useAddCart } from '@!/Hooks/useAddCart'

export const AddCartButton = ({ detail }) => {
  const { handleAddCartItem, showConfirmPopup, confirmMessage, closeConfirmPopup } = useAddCart(detail)

  return (
    <>
      <NormalButton onClick={handleAddCartItem} width="100%" height="37px" rightIcon={<AddCartIcon width="1.5em" height="1.5em" ml={-1} />}>
        カートに入れる
      </NormalButton>
      <ConfirmPopupModal text={confirmMessage} isOpen={showConfirmPopup} handlePopup={closeConfirmPopup} />
    </>
  )
}

AddCartButton.propTypes = {
  detail: PropTypes.object.isRequired,
}
