import { PropTypes } from 'prop-types'

import { Stack } from '@chakra-ui/react'

import { NewScrollListTitles as ScrollableList, SectionTitle } from './'

/* 各画面に表示される横スクロール型の特集リスト */
export const ScrollableFeatureList = ({ title, pathname, books, tabName }) => {
  return (
    <Stack spacing={0} mx={4} my={3}>
      <SectionTitle title={title} url={`/store/features/${pathname}`} tabName={tabName} />
      <ScrollableList titles={books} type="book" {...(tabName && { sectionTitle: `${tabName}_${title}` })} />
    </Stack>
  )
}

ScrollableFeatureList.propTypes = {
  title: PropTypes.string.isRequired,
  pathname: PropTypes.string.isRequired,
  books: PropTypes.array.isRequired,
  tabName: PropTypes.string,
}
