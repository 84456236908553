import { PropTypes } from 'prop-types'

import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@chakra-ui/react'
import { ChevronRightIcon } from '@chakra-ui/icons'
import { ContentContainer } from '@!/components/Container'

export const BreadcrumbList = ({ links }) => {
  const items = links.map((item, index) => {
    if (item.ref) {
      return (
        <BreadcrumbItem key={`breadcrumb-${index}`} display="inline" wordBreak="break-word">
          <BreadcrumbLink href={item.ref}>{item.name}</BreadcrumbLink>
        </BreadcrumbItem>
      )
    } else {
      return (
        <BreadcrumbItem key={`breadcrumb-${index}`} isCurrentPage display="inline" wordBreak="break-word">
          <BreadcrumbLink href="#" fontWeight="bold">
            {item.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
      )
    }
  })
  return (
    <Box bg="gray.200" p="4px 10px">
      <ContentContainer>
        <Breadcrumb spacing={0} fontSize="xs" separator={<ChevronRightIcon mx="2px" />} sx={{ ol: { display: 'inline-block' } }}>
          {items}
        </Breadcrumb>
      </ContentContainer>
    </Box>
  )
}

BreadcrumbList.propType = {
  links: PropTypes.array.isRequired,
}
