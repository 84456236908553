import { Link, Wrap, WrapItem } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'

export const FooterLinks = () => {
  return (
    <Wrap justify="center" my={2} spacing={0}>
      <WrapItem mx={1}>
        <Link color="white" fontWeight="normal" fontSize="xs" href="https://amazia.co.jp/about-us/" isExternal>
          運営会社 <ExternalLinkIcon mx="2px" />
        </Link>
      </WrapItem>
      <WrapItem mx={1}>
        <Link color="white" fontWeight="normal" fontSize="xs" href="/static_page/specified_commercial_transaction">
          特定商取引法に関する表記
        </Link>
      </WrapItem>
      <WrapItem mx={1}>
        <Link color="white" fontWeight="normal" fontSize="xs" href="/static_page/privacy_policy">
          プライバシーポリシー
        </Link>
      </WrapItem>
      <WrapItem mx={1}>
        <Link color="white" fontWeight="normal" fontSize="xs" href="/static_page/stop_piracy">
          海賊版に関する取り組み
        </Link>
      </WrapItem>
    </Wrap>
  )
}
