import { useEffect } from 'react'
import { useBoolean } from '@chakra-ui/react'
import { UpButton } from '@!/components/Button'

export const ScrollToTop = (props) => {
  const [showButton, setShowButton] = useBoolean()

  // スクロールイベントをListen
  useEffect(() => {
    window.addEventListener('scroll', watchScroll)
    return () => {
      window.removeEventListener('scroll', watchScroll)
    }
  }, [])

  // Scrollを検知しボタン表示のフラグを切り替え
  const watchScroll = () => {
    const basePosition = 200
    const scrollPosition = window.scrollY
    if (basePosition <= scrollPosition) {
      setShowButton.on()
    } else {
      setShowButton.off()
    }
  }

  const handleScrollToTop = (e) => {
    e.preventDefault()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  return <UpButton onClick={handleScrollToTop} display={showButton ? 'block' : 'none'} {...props} />
}
