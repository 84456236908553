import { Children } from 'react'
import { PropTypes } from 'prop-types'

import { Box, SimpleGrid } from '@chakra-ui/react'

export const BorderedGrid = ({ columns, borderColor = '#e0e0e0', children, showSideBorders = true }) => {
  const items = Children.map(children, (child, index) => {
    return (
      <Box borderTop={index < columns ? '1px' : ''} borderBottom={'1px'} borderEnd={index % columns < columns - 1 ? '1px' : ''} borderColor={borderColor}>
        {child}
      </Box>
    )
  })

  return (
    <SimpleGrid columns={columns} spacing={0} borderRight={showSideBorders ? '1px' : ''} borderLeft={showSideBorders ? '1px' : ''} borderColor={borderColor}>
      {items}
    </SimpleGrid>
  )
}

BorderedGrid.propType = {
  columns: PropTypes.number.isRequired,
  borderColor: PropTypes.string.isRequired,
}
