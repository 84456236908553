import { useState } from 'react'
import { useMutation } from 'react-query'
import { execPurchaseApi } from '@!/Api/Payment'

export const usePaymentExecPurchase = () => {
  const [apiError, setApiError] = useState({ error: null, errorInfo: null })

  const execPurchaseMutation = useMutation({
    mutationFn: ({ listIndex, apiTime }) => execPurchaseApi({ listIndex: listIndex, apiTime: apiTime }),
    onError: (error) => {
      if (error.data?.error) {
        const errorInfo = error.data?.ErrInfo ? error.data.ErrInfo : null
        setApiError({ error: error.data.error, errorInfo: errorInfo })
      } else {
        setApiError({ error: 'etc_error' })
      }
    },
  })

  return [execPurchaseMutation, apiError]
}
