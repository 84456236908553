import { useEffect, useState } from 'react'
import { useAtom } from 'jotai'
import { Box, Flex, Text, Link, Divider, Heading, useToast, Container } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { YesNoPopupModal, CircularProgressModal } from '@!/components/Modal'
import { useAccountInfo, useLogout } from '@!/Hooks'
import { userHashAtom } from '@!/atoms/userInfoAtom'
import { csrfTokenAtom, csrfParamsAtom, alertMessageAtom } from '@!/atoms/appSettingsAtom'
import { NormalButton, PrimaryButton } from '@!/components/Button'
import { breadcrumbAtom } from '@!/atoms/breadcrumbAtom'

const Account = () => {
  const toast = useToast()
  const [userHash] = useAtom(userHashAtom)
  const [csrfToken] = useAtom(csrfTokenAtom)
  const [csrfParams] = useAtom(csrfParamsAtom)
  const { data: accountInfo, isLoading: isAccountInfoLoading, isSuccess: isAccountInfoSuccess } = useAccountInfo(userHash)
  const [alertMessage, setAlertMessage] = useAtom(alertMessageAtom)
  const [confirmLogout, setConfirmLogout] = useState(false)
  const [execLogoutMutation, logoutApiError] = useLogout()
  const [, setBreadcrumb] = useAtom(breadcrumbAtom)

  useEffect(() => {
    setBreadcrumb([{ name: 'マンガBANGブックス', ref: '/' }, { name: 'アカウント情報' }])
  }, [])

  useEffect(() => {
    if (alertMessage) {
      toast({
        title: 'Notification',
        description: alertMessage,
        status: 'info',
        duration: 9000,
        isClosable: true,
        onCloseComplete: () => {
          setAlertMessage(null)
        },
      })
    }
  }, [alertMessage])

  const onLogout = () => {
    setConfirmLogout(true)
  }

  useEffect(() => {
    if (!logoutApiError) return

    toast({
      title: '失敗',
      description: logoutApiError,
      status: 'error',
      duration: 9000,
      isClosable: true,
    })
  }, [logoutApiError])

  const handleLogOut = (isLogout) => {
    if (isLogout) {
      execLogoutMutation.mutate()
    }
    setConfirmLogout(false)
  }

  const registerBlock = (type) => {
    if (!csrfToken && !csrfParams) return

    switch (type) {
      case 'facebook':
        return (
          <form action="/auth/facebook?from=account" method="post">
            <input type="hidden" name={csrfParams} value={csrfToken} />
            <NormalButton type="submit" width="88px" height="auto" boxShadow="none" p="8px 16px">
              連携する
            </NormalButton>
          </form>
        )
      case 'siwa':
        return (
          <form action="/auth/apple?from=account" method="post">
            <input type="hidden" name={csrfParams} value={csrfToken} />
            <NormalButton type="submit" width="88px" height="auto" boxShadow="none" p="8px 16px">
              連携する
            </NormalButton>
          </form>
        )
      case 'email':
        return (
          <NormalButton as={RouterLink} to="register_email" width="88px" height="auto" boxShadow="none" p="8px 16px">
            登録する
          </NormalButton>
        )
      case 'updatePassword':
        return (
          <NormalButton as={RouterLink} to="update_password" width="88px" height="auto" boxShadow="none" p="8px 16px">
            変更する
          </NormalButton>
        )
    }
  }

  return (
    <Container boxSizing="border-box">
      <Flex mt={1} direction="column" m="0 auto" p="16px 0" color="label.primary">
        {isAccountInfoSuccess ? (
          <>
            <Box pb="16px">
              <Heading as="h3" size="sm" p="16px">
                アカウント情報
              </Heading>
              <Divider />
              <Flex justifyContent="space-between" alignItems="center" p="12px 16px">
                <Text m={0} fontSize="13px">
                  メールアドレス
                </Text>
                <Box>
                  {accountInfo.email ? (
                    <Text fontSize="13px" color="label.secondary">
                      {accountInfo.email}
                    </Text>
                  ) : (
                    registerBlock('email')
                  )}
                </Box>
              </Flex>
              <Divider />
              <Flex justifyContent="space-between" alignItems="center" p="12px 16px">
                <Text m={0} fontSize="13px">
                  パスワード
                </Text>
                <Box>{accountInfo.email ? registerBlock('updatePassword') : registerBlock('email')}</Box>
              </Flex>
              <Divider />
            </Box>

            <Box pb="16px">
              <Heading as="h3" size="sm" p="16px">
                他サービスID連携
              </Heading>
              <Divider />
              {accountInfo.twitter && (
                <>
                  <Flex justifyContent="space-between" alignItems="center" p="12px 16px">
                    <Text m={0} fontSize="13px">
                      Twitter
                    </Text>
                    <Box fontSize="14px" fontWeight="bold" w="88px" textAlign="center" p="8px 16px">
                      連携済み
                    </Box>
                  </Flex>
                  <Divider />
                </>
              )}
              <Flex justifyContent="space-between" alignItems="center" p="12px 16px">
                <Text m={0} fontSize="13px">
                  Facebook
                </Text>
                <Box>{accountInfo.facebook ? '連携済み' : registerBlock('facebook')}</Box>
              </Flex>
              <Divider />
              <Flex justifyContent="space-between" alignItems="center" p="12px 16px">
                <Text m={0} fontSize="13px">
                  AppleID
                </Text>
                <Box>{accountInfo.siwa ? '連携済み' : registerBlock('siwa')}</Box>
              </Flex>
              <Divider />
            </Box>

            <Flex p="16px 0" justifyContent="center">
              <PrimaryButton variant="outline" onClick={onLogout} width="165px" height="37px">
                ログアウト
              </PrimaryButton>
            </Flex>

            <Link as={RouterLink} to="resign" textAlign="center" p="16px" fontSize="13px">
              退会について
            </Link>
          </>
        ) : null}

        <CircularProgressModal isOpen={isAccountInfoLoading} />
        <YesNoPopupModal
          title={'ログアウトします。よろしいですか？'}
          handleCancel={() => handleLogOut(false)}
          handleSubmit={() => handleLogOut(true)}
          cancelTitle={'キャンセル'}
          submitTitle={'はい'}
          isOpen={confirmLogout}
        />
      </Flex>
    </Container>
  )
}

export default Account
