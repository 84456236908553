import { Box, Image } from '@chakra-ui/react'

import MangbangLogoPng from '@images/mangabang_logo.png'

export const MenuHeader = () => {
  return (
    <Box p={'16px'}>
      <Image htmlWidth={170} htmlHeight={18} src={`${window.asset_host || ''}${MangbangLogoPng}`} alt="マンガBANGブックス" />
    </Box>
  )
}
