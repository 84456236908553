import { StyleSheet, css } from 'aphrodite'

export const Error500 = () => {
  const styles = StyleSheet.create({
    error_500_main: {
      width: '75%',
      textAlign: 'center',
      margin: '0 auto',
      paddingTop: '10%',
    },
    error_500_image: {
      fontSize: '42px',
      color: '#f00',
    },
    error_500_main_text: {
      margin: 0,
      fontSize: '16px',
    },
    error_500_sub_text: {
      margin: '6px 0',
      fontSize: '12px',
    },
  })

  return (
    <div className={css(styles.error_500_main)}>
      <div className={css(styles.error_500_image)}>
        <i className="fa fa-exclamation"></i>
      </div>
      <p className={css(styles.error_500_main_text)}>ぺージが表示できません。</p>
      <p className={css(styles.error_500_sub_text)}>サーバーで問題が発生しました。しばらくお待ちください。</p>
    </div>
  )
}
