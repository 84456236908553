const SEPARATOR = '…'

export const ellipsis = (str, frontLen, backLen) => {
  if (str.length <= frontLen + backLen) {
    return str
  }

  const frontStr = str.substr(0, frontLen)
  const backStr = str.substr(str.length - backLen, backLen)

  return `${frontStr}${SEPARATOR}${backStr}`
}
