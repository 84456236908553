import { Box, LinkBox, LinkOverlay, VStack, Text } from '@chakra-ui/react'
import { CoverImage } from '@!/Store/Common/components/CoverImage'
import PropTypes from 'prop-types'

export const BulkPurchaseTitleItem = ({ title, sectionTitle }) => {
  return (
    <Box mr={3}>
      <LinkBox w="100px">
        <VStack spacing={0}>
          <Box mb="0.5em">
            <CoverImage src={title.image_url} alt={title.name} objectFit="contain" width="100px" />
          </Box>
          <LinkOverlay
            href={`/store/books?book_title_id=${title.title_id}`}
            data-gtm-click={sectionTitle}
            noOfLines={1}
            fontSize="sm"
            fontWeight="bold"
            w="100px"
          >
            {title.name}
          </LinkOverlay>
          <Box w="100%" p={1} bgColor="bg.secondary" fontSize="xs" lineHeight="13px" textAlign="center" fontWeight="bold" borderRadius="3px">
            <Text as="span" color="label.accent">
              {title.total_point_count.toLocaleString()}pt
            </Text>
            <Text as="span">還元</Text>
          </Box>
        </VStack>
      </LinkBox>
    </Box>
  )
}

BulkPurchaseTitleItem.propTypes = {
  title: PropTypes.object.isRequired,
  sectionTitle: PropTypes.string.isRequired,
}
