import { Children } from 'react'

import { Box, SimpleGrid } from '@chakra-ui/react'

export const MenuFooter = (props) => {
  const { children } = props

  const colSize = 2

  const menus = Children.map(children, (child, index) => {
    return (
      <Box borderTop={index < colSize ? '1px' : ''} borderBottom={'1px'} borderEnd={index % colSize < colSize - 1 ? '1px' : ''} borderColor={'#E0E0E0'}>
        {child}
      </Box>
    )
  })

  return (
    <SimpleGrid columns={colSize} spacing={0}>
      {menus}
    </SimpleGrid>
  )
}
