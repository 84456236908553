import { PropTypes } from 'prop-types'

import { Box, HStack, Skeleton, Spacer, Text } from '@chakra-ui/react'

export const PointCount = ({ pointCount }) => {
  return (
    <Box border="solid 1px #DCDCE1" borderRadius={'4px'} p={'19px 16px'}>
      <HStack>
        <Text fontSize={'1em'} fontWeight={600}>
          保有ポイント
        </Text>
        <Spacer />
        <Box color={'#E94841'}>
          <Skeleton minW="120px" isLoaded={!!pointCount}>
            <Text as="span" fontSize={'2em'} fontWeight={700}>
              {pointCount && pointCount.toLocaleString()}
            </Text>
            <Text as="span" fontSize={'1em'} fontWeight={600} marginInlineStart={'0.5em'}>
              pt
            </Text>
          </Skeleton>
        </Box>
      </HStack>
    </Box>
  )
}

PointCount.propTypes = {
  pointCount: PropTypes.number,
}
